<div
  class="box-dropdown-bg"
  [ngClass]="{
    'with-out-spaces': controlWithoutSpaces,
    'dropdown-disabled': isDisabled
  }"
>
  <label
    *ngIf="label"
    class="mo-label"
    [ngClass]="{
      'only-label': onlyLable,
      'only-label--open': onlyLable && isOpen
    }"
    >{{ label }}
    <i
      *ngIf="onlyLable"
      [class]="'icon__label uil ' + size"
      [ngClass]="isOpen ? 'uil-angle-up' : 'uil-angle-down '"
    ></i>
  </label>
  <div
    [class]="'mo-dropdown ' + colorTemplate + ' ' + size"
    [ngClass]="{ 'mo-dropdown--open': isOpen }"
    *ngIf="!onlyLable"
  >
    <div class="mo-dropdown-content item-option">
      <ng-container *ngIf="selectedKey; then asignedValue; else defaultValue">
      </ng-container>
      <ng-template #asignedValue>
        <span>{{ selectedKey }}</span>
      </ng-template>
      <ng-template #defaultValue>
        <span class="selected-option">{{ defaultOptionName }}</span>
      </ng-template>
      <ng-container *ngIf="isOpen; then optionsItems"></ng-container>
    </div>
    <i
      *ngIf="orientation == ORIENTATION.BOTTOM"
      [class]="'icon uil ' + size"
      [ngClass]="isOpen ? 'uil-angle-up icon--open' : 'uil-angle-down '"
    ></i>
    <i
      *ngIf="orientation != ORIENTATION.BOTTOM"
      [class]="'icon uil ' + size"
      [ngClass]="isOpen ? 'uil-angle-down icon--open' : 'uil-angle-up '"
    ></i>
  </div>
  <ng-container *ngIf="onlyLable; then optionsItems"></ng-container>

  <ng-template #optionsItems>
    <div *ngIf="isOpen" [class]="'mo-options-box ' + size + ' ' + orientation">
      <div
        class="mo-option"
        [ngClass]="{
          'mo-option__selected': selectedKey == option?.key && canUnSelect
        }"
        *ngFor="let option of options"
      >
        <div class="mo-option__item" (click)="selectOption(option)">
          {{ option.key }}
        </div>
        <div class="mo-option__unselect" *ngIf="canUnSelect">
          <i
            class="uil uil-times-circle"
            *ngIf="selectedKey == option?.key"
            (click)="onResetSelect()"
          ></i>
        </div>
      </div>
    </div>
  </ng-template>
</div>
