import { MenusTranslate } from '../../../models/menu-translate';

const MENUS_TRANSLATE: MenusTranslate = {
  SIDE_MENU: {
    MY_ACCOUNT: 'My Account',
  },
  NAVBAR: {
    LOGOUT: 'Log Out',
    NEED_SUPPORT: 'Need support?',
  },
};
export default MENUS_TRANSLATE;
