import { RoutesMock } from '@app/core/models/routes-mock.model';
import { MERCHANT_GROUPS } from '../mocks/merchant-groups.mock';
import {
  MERCHANTS,
  MERCHANT_GROUP_AND_OFFERS,
  MERCHANT_PROFILE,
  MERCHANT_SUMMARY,
} from '../mocks/merchants.mock';
import { PURCHASES } from '../mocks/purchases.mock';
import { API_URLS } from './api.constants';

const ID_REGEX = '/[0-9a-z-]+';

export const MERCHANT_MANAGER_ROUTES_MOCK: RoutesMock[] = [
  // MERCHANTS
  {
    url: API_URLS.MERCHANTS.GET_MERCHANTS,
    data: MERCHANTS,
    method: 'GET',
  },
  // MERCHANT PROFILE
  {
    url: `${API_URLS.MERCHANTS.GET_MERCHANTS}${ID_REGEX}`,
    data: MERCHANT_PROFILE,
    method: 'GET',
  },
  {
    url: API_URLS.MERCHANTS.GET_MERCHANTS_SUMMARY.replace('/:id', ID_REGEX),
    data: MERCHANT_SUMMARY,
    method: 'GET',
  },
  {
    url: API_URLS.MERCHANTS.GET_MERCHANTS_GROUP_AND_OFFERS.replace(
      '/:id',
      ID_REGEX
    ),
    data: MERCHANT_GROUP_AND_OFFERS,
    method: 'GET',
  },
  // PURCHASES
  {
    url: API_URLS.PURCHASES.GET_PURCHASES,
    data: PURCHASES,
    method: 'GET',
  },
  // MERCHANT GROUPS
  {
    url: API_URLS.MERCHANT_GROUPS.GET_MERCHANT_GROUPS,
    data: MERCHANT_GROUPS,
    method: 'GET',
  },
];
