import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectState } from './project.state';

export const getProjectFeatureState =
  createFeatureSelector<ProjectState>('project');

export const selectData = createSelector(
  getProjectFeatureState,
  (state: ProjectState) => state?.data
);

export const selectProjectFamily = createSelector(
  getProjectFeatureState,
  (state: ProjectState) => state?.data?.family_name
);

export const selectIsLoadingProject = createSelector(
  getProjectFeatureState,
  (state: ProjectState) => state?.isLoadingProject
);
