export enum ChargePeriodicity {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  UNIQUE = 'unique',
}
export enum ChargeValueType {
  FIXED = 'fixed',
  PERCENTAGE = 'percentage',
}
export enum ChargeType {
  CAPITAL = 'capital',
  INTEREST = 'interest',
  COST = 'cost',
  COLLECTION_COST = 'collection_cost',
  DEFAULT_INTEREST = 'default_interest',
}
