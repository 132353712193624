import { Component, Input } from '@angular/core';

@Component({
  selector: 'layout-page-description',
  templateUrl: './page-description.component.html',
  styleUrls: ['./page-description.component.scss'],
})
export class PageDescriptionComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() total?: number;

  constructor() {}
}
