import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  DEFAULT_FAMILY,
  LANGUAGES,
  LANGUAGES_BY_PRODUCT_FAMILY,
} from '@app/core/i18n/constants/translate.constants';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private _translate: TranslateService) {}

  public setLanguage(language: LANGUAGES, familyName: string) {
    import(
      /* webpackInclude: /(es|en)\.mjs$/ */
      `/node_modules/@angular/common/locales/${language}.mjs`
    ).then((module) => registerLocaleData(module.default));

    const family: string = familyName ?? DEFAULT_FAMILY;
    this._translate.use(LANGUAGES_BY_PRODUCT_FAMILY[family][language]);
  }
}
