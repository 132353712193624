import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'layout-basic-slides',
  templateUrl: './basic-slides.component.html',
  styleUrls: ['./basic-slides.component.scss'],
})
export class BasicSlidesComponent implements OnInit {
  @Input() title?: string;
  @Input() subTitle?: string;
  @Input() logo?: string;
  @Input() background?: string;
  constructor() {
    if (!this.logo)
      this.logo = 'app/presentation/assets/img/icons/logo/mo-logo-white.svg';
    if (!this.background)
      this.background =
        'app/presentation/assets/img/backgrounds/login-background.png';
  }

  ngOnInit(): void {}
}
