import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { APP_CONSTANTS } from '../constants/app.constants';
import {
  DEFAULT_FAMILY,
  DEFAULT_LANGUAGE,
  LANGUAGE_PATH,
  LANGUAGE_SEPARATOR,
  LANGUAGE_SUFFIX,
  TRANSLATION_BY_PRODUCT_FAMILY,
} from '../i18n/constants/translate.constants';

@Injectable()
export class AppTranslateInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const reqLanguage = req.url.indexOf(LANGUAGE_PATH) > -1;
    if (reqLanguage) {
      const languageFolder = req.url.split(LANGUAGE_PATH).pop();
      const language: string = languageFolder
        .split(LANGUAGE_SEPARATOR)
        .pop()
        .split(LANGUAGE_SUFFIX)
        .shift();

      const family: string = languageFolder
        .split(LANGUAGE_SEPARATOR)
        .shift()
        .split(`${LANGUAGE_SEPARATOR}${language}`)
        .shift();

      return of(
        new HttpResponse({
          status: APP_CONSTANTS.HTTP_CODES.OK,
          body: this._getBody(family, language),
        })
      );
    }

    return next.handle(req);
  }

  private _getBody(family: string, language: string): Object {
    return !family || !language
      ? TRANSLATION_BY_PRODUCT_FAMILY[DEFAULT_FAMILY][DEFAULT_LANGUAGE]
      : TRANSLATION_BY_PRODUCT_FAMILY[family][language];
  }
}
