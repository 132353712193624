export const LOAN_MANAGER_FEATURE = {
  NAME: 'Credit Manager',
  PATH: 'credit-manager',
  PAGES: {
    OVERVIEW: {
      NAME: 'Overview',
      PATH: 'overview',
    },
    LOAN_REQUEST: {
      NAME: 'Credit Request',
      PATH: 'credit-request',
      STORE_NAME: 'loansRequest',
    },
    LOANS: {
      NAME: 'Credits',
      PATH: 'credits',
      STORE_NAME: 'loans',
      SUB_PAGES: {
        LOAN_PROFILE: {
          NAME: 'Credit profile',
          PATH: 'credit-profile',
        },
      },
    },
    PAYMENTS: {
      NAME: 'Payments',
      PATH: 'payments',
      STORE_NAME: 'payments',
    },
  },
};
