<div class="mo-table-container">
  <table [class]="'mo-table ' + designClass + ' ' + containerSize">
    <ng-container *ngIf="!!headers; else loadingTable">
      <thead class="mo-header">
        <tr [class]="'mo-row ' + designClass">
          <th
            *ngFor="let header of headers; first as isFirst"
            [ngClass]="{
              'sticky-col': firstColumnFixed && isFirst,
              'fixed-min-width': fixedMinWidth
            }"
            [class]="'table-title mo-col mo-col-' + header?.size"
          >
            <table-header
              [header]="header"
              [sortedColumn]="sortedColumn"
              (sort)="onSort($event)"
            ></table-header>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="!isLoading; else loadingBody" #row>
          <ng-container *ngIf="data?.length > 0; else empty">
            <tr
              *ngFor="let row of data"
              [class]="'mo-row ' + designClass"
              [@inOutAnimation]
            >
              <td
                *ngFor="let header of headers; first as isFirst"
                [ngClass]="{
                  'sticky-col': firstColumnFixed && isFirst,
                  'fixed-min-width': fixedMinWidth
                }"
                [class]="'table-content mo-col mo-col-' + header?.size"
              >
                <table-row
                  [header]="header"
                  [row]="row"
                  [urlRow]="urlRow"
                  [urlRowIdentifier]="urlRowIdentifier"
                ></table-row>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </ng-container>
  </table>
</div>

<ng-template #loadingTable>
  <thead class="mo-header">
    <tr [class]="'mo-row ' + designClass">
      <th
        *ngFor="let colum of loaderColums; first as isFirst"
        [ngClass]="{
          'sticky-col': firstColumnFixed && isFirst,
          'fixed-min-width': fixedMinWidth
        }"
        [class]="'table-title mo-col mo-col-4'"
      >
        <table-cell-loader></table-cell-loader>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of loaderRows" [class]="'mo-row ' + designClass">
      <td
        *ngFor="let colum of loaderColums"
        [class]="'table-content mo-col mo-col-4'"
      >
        <table-cell-loader></table-cell-loader>
      </td>
    </tr>
  </tbody>
</ng-template>

<ng-template #loadingBody>
  <tr *ngFor="let row of loaderRows" [class]="'mo-row ' + designClass">
    <td
      *ngFor="let header of headers"
      [class]="'table-content mo-col mo-col-' + header?.size"
    >
      <table-cell-loader></table-cell-loader>
    </td>
  </tr>
</ng-template>

<ng-template #empty>
  <table-empty></table-empty>
</ng-template>
