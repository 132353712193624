import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'layout-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {
  @Input() title: string;
  @Input() checked: boolean;
  @Input() open: boolean = false;
  @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  public onToggle() {
    this.open = !this.open;
    this.toggle.emit(this.open);
  }
}
