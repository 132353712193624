<div class="table-with-controls">
  <div class="table-with-controls__options" *ngIf="showOptions">
    <table-options (download)="onDownload()"></table-options>
  </div>
  <div class="table-with-controls__content">
    <mo-table
      [designClass]="designClass"
      [headers]="headers"
      [data]="response?.results"
      [firstColumnFixed]="firstColumnFixed"
      [containerSize]="containerSize"
      [urlRow]="urlRow"
      [urlRowIdentifier]="urlRowIdentifier"
      [isLoading]="isLoading"
      [loadingRows]="loadingRows"
      [loadingColumns]="loadingColumns"
      (sortFilters)="onSort($event)"
    ></mo-table>
  </div>
  <div class="table-with-controls__navigation">
    <table-paginator
      (change)="onChangePage($event)"
      [currentPage]="currentPage"
      [dataLength]="response?.filtered"
      [pageSize]="pageSize"
    ></table-paginator>
    <table-page-size
      [pageSize]="pageSize"
      (changePageSize)="onChangePageSize($event)"
    ></table-page-size>
  </div>
</div>
