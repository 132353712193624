<div class="order-card">
  <layout-card-container
    [designClass]="CARD_CONTAINER_DESIGN_CLASS.ALTERNATIVE"
  >
    <div class="order-card__content">
      <h2 class="title">{{ title }}</h2>
      <div class="controls">
        <label class="controls__label">{{ label }}</label>
        <div class="controls__counter">
          <i class="uil uil-minus" (click)="onDiminish()"></i>
          <div class="number">
            <span> {{ value }}</span>
          </div>
          <i class="uil uil-plus" (click)="onIncrease()"></i>
        </div>
      </div>
    </div>
  </layout-card-container>
  <div class="order-card__icon-trash" (click)="onDelete()" *ngIf="canDelete">
    <i class="uil uil-trash-alt"></i>
  </div>
</div>
