import { Component, OnInit } from '@angular/core';
import { Skeleton } from '@app/core/models/skeleton.model';

@Component({
  selector: 'table-cell-loader',
  templateUrl: './table-cell-loader.component.html',
  styleUrls: ['./table-cell-loader.component.scss'],
})
export class TableCellLoaderComponent implements OnInit {
  public skeleton: Skeleton;
  constructor() {}

  ngOnInit(): void {
    this.skeleton = { theme: { margin: 0 } };
  }
}
