<div class="score-item" *ngIf="!isLoading; else loader">
  <div class="collapsed">
    <h2 class="title">{{ name }}</h2>
    <div class="divider"></div>
    <div class="values">
      <span>{{ date | MODate }}</span>
      <span>{{ score }}</span>
      <span>{{ amount | customCurrency }}</span>
    </div>
  </div>
  <div *ngIf="hasDetail">
    <i class="uil uil-angle-down"></i>
    <ng-content></ng-content>
  </div>
</div>

<ng-template #loader>
  <div class="score-item">
    <div class="collapsed">
      <layout-skeleton
        *ngFor="let skeleton of loaderName"
        [skeleton]="skeleton"
      ></layout-skeleton>
      <div class="divider"></div>
      <div class="values">
        <layout-skeleton
          *ngFor="let skeleton of loaderValues"
          [skeleton]="skeleton"
        ></layout-skeleton>
      </div>
    </div>
  </div>
</ng-template>
