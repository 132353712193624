import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TABLE_SORT } from '../../enums/table.enum';
import { ColumnSort, TableHeader } from '../../interfaces/table.interface';

@Component({
  selector: 'table-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnChanges {
  @Input() header: TableHeader;
  @Input() columnSort?: ColumnSort;
  @Input() sortedColumn: string;
  @Output() sort: EventEmitter<ColumnSort> = new EventEmitter<ColumnSort>();

  public sortIcon: string;

  ngOnInit(): void {
    this._setDefaulValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes?.sortedColumn?.firstChange &&
      this.columnSort &&
      this.columnSort?.column != changes?.sortedColumn?.currentValue
    ) {
      this._setDefaulValues();
    }
  }

  private _setDefaulValues(): void {
    this.sortIcon = 'app/presentation/assets/img/icons/sort.svg';
    this.columnSort = {
      column: null,
      sortBy: TABLE_SORT.ASC,
      typeSort: 'string',
    };
  }

  /**
   * Establece el icono de ordenamiento
   * @param header ITableHeader
   * @returns void
   */
  public onHeaderClick(header: TableHeader): void {
    const column = header.dataKey;

    if (!header.isSortable) return;

    const isCurrentSort = this.columnSort?.column === column;

    if (isCurrentSort && this.columnSort?.sortBy === TABLE_SORT.DESC) {
      this.sortIcon = 'app/presentation/assets/img/icons/sort.svg';

      this.columnSort.column = null;
      this.columnSort.sortBy = TABLE_SORT.ASC;
      this._emitSort();
      return;
    }

    this.columnSort.sortBy = isCurrentSort ? TABLE_SORT.DESC : TABLE_SORT.ASC;
    this.columnSort.column = column;
    this.columnSort.typeSort = header.typeSort ?? 'string';
    this.sortIcon =
      this.columnSort.sortBy == TABLE_SORT.DESC
        ? 'app/presentation/assets/img/icons/sort-desc.svg'
        : 'app/presentation/assets/img/icons/sort-asc.svg';
    this._emitSort();
  }

  private _emitSort(): void {
    this.sort.emit(this.columnSort);
  }
}
