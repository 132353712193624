import { ProductsAndOffersPageTranslate } from '../../models/products_and_offers-translate';

const PRODUCTS_AND_OFFERS_TRANSLATE: ProductsAndOffersPageTranslate = {
  PRODUCTS_AND_OFFERS: {
    TITLE: 'Products and offers',
    DESCRIPTION:
      'Manage the products and offers of your credit portfolio, whilst overseeing the <br />performance of your credit operation.',
    PRODUCTS_COUNTER: 'Products',
    OFFERS_COUNTER: 'Offers',
    PRODUCTS: {
      TITLE: 'Product & offer map',
      DESCRIPTION:
        'View, edit or create new products and offers to <br />add to your credit portfolio.',
      BTN_CREATE_NEW_PRODUCT: 'Create new product',
      PRODUCT_CARD: {
        EMPTY:
          'At the moment you have no offers created, you can create an offer and you will see it in this place',
        OFFERS_COUNTER: 'Offers',
        PRODUCT_ID: 'Product ID',
        BTN_VIEW_MORE: 'View more',
        BTN_CREATE_NEW_OFFER: 'Create new offer',
        BTN_VIEW_PRODUCT_DETAILS: 'View product detail',
      },
    },
  },
  PRODUCTS_PROFILE: {
    TITLE: 'Product profile',
    DESCRIPTION:
      'Review this credit product basic information and the offers associated with this <br />product. Track how this product is performing within your credit operation with <br />some key metrics and visualizations.',
    INFO: {
      PRODUCT_ID: 'Product ID',
      OFFERS_COUNTER: 'Offers',
      PRODUCTS: 'Products',
      CREATED_AT: 'Date created',
      UPDATED_AT: 'Date modified',
    },
    OFFERS: {
      TITLE: 'Offers',
      DESCRIPTION:
        'View all credit offers associated with this product. <br />Quickly edit, archive, or create new offers to your credit product.',
      BTN_CREATE_NEW_OFFER: 'Create new offer',
      EMPTY: 'There aren’t any offers associated <br />to this product',
      TABLE: {
        OFFER_NAME: 'Offer name',
        OFFER_REFERENCE: 'Offer ID',
        CREATED_AT: 'Date created',
        UPDATED_AT: 'Date modified',
        STATUS: 'Status',
      },
    },
    DETAILS: {
      TITLE: 'General settings',
      MAXIMUM_NUMBER_OF_ACTIVE_LOANS: 'Maximum number of active credits',
      LOAN_AMOUNTS: 'Credits amounts',
      CLOSING_TOLERANCE: 'Closing tolerance',
      DECIMALS: 'Decimals',
      AMOUNT_REQUESTED_STEP: 'Amount requested step',
      NEAREST_MULTIPLE_OF: 'Nearest multiple of',
    },
  },
  OFFER_PROFILE: {
    TITLE: 'Offer profile',
    DESCRIPTION:
      'Review all the specific configurations and basic information for this offer. Edit <br />specific fields or archive this offer.',
    INFO: {
      OFFER_ID: 'Offert ID:',
      CREATED_AT: 'Date created:',
      UPDATED_AT: 'Date modified:',
    },
    GENERAL_SETTINGS: {
      TITLE: 'General settings',
      MAXIMUM_ACTIVE_CREDITS: 'Maximum number of the active credits:',
    },
    REPAYMENT_LOGIC: {
      TITLE: 'Repayment logics',
      TYPE: 'Repayment type:',
      PERIODICITY: 'Periodicity:',
      CALENDAR_LOGIC: 'Calendar logic:',
      FIXED_OR_RELATIVE: 'Fixed or relative:',
      FIXED_OR_RELATIVE_DAY_OF_WEEK: 'Day of the week:',
      NUMBER_OF_INSTALLMENTS: 'Number of installments:',
      TERM_OF_CREDIT: 'Term of credit',
      TYPE_OF_INSTALLMENT: 'Type of installment:',
      MISSED_REPAYMENT_LOGIC: 'Missed repayment logic:',
      GRACE_PERIOD: 'Grace perdiod:',
      GRACE_PERIOD_NUMBER_OF_DAYS: 'Number of days:',
    },
    CHARGES: {
      TITLE: 'Charges in order of collection priority',
      CAPITAL: { TITLE: 'Capital', DOWN_PAYMENT: 'Down payment:' },
      INTEREST: {
        TITLE: 'Interest',
        VALUE: 'Interest value:',
        BASE: 'Base:',
        TAX: 'Tax:',
        TAX_FEE: 'Tax fee:',
        PERIODICITY: 'Periodicity:',
        GRACE_PERIOD: 'Grace perdiod:',
        GRACE_PERIOD_NUMBER_OF_DAYS: 'Number of days:',
      },
      COSTS: {
        TITLE: 'Costs',
        PLATFORM: 'Platform',
        ADMINISTRATIVE: 'Administrative',
        FIXED_OR_PERCENT: 'Fixed or percent',
        FIXED_OR_PERCENT_PERCENTAGE: 'Percentage:',
        TAX: 'Tax:',
        TAX_FEE: 'Tax fee:',
      },
      COLLECTION_COSTS: {
        TITLE: 'Collection costs',
        TRIGGER: 'Trigger:',
        GRACE_PERIOD: 'Grace perdiod:',
        GRACE_PERIOD_TIME_UNIT: 'Time unit:',
        GRACE_PERIOD_PERCENTAGE: 'Percentage:',
        FIXED_OR_PERCENT: 'Fixed or percent',
        FIXED_OR_PERCENT_PERCENTAGE: 'Percentage:',
        FIXED_OR_PERCENT_BASE: 'Base:',
        TAX: 'Tax:',
        TAX_FEE: 'Tax fee:',
      },
      DEFAULT_INTEREST: {
        TITLE: 'Default interest',
        TRIGGER: 'Trigger:',
        INTEREST_VALUE: 'Interest value:',
        BASE: 'Base:',
        TAX: 'Tax:',
        TAX_FEE: 'Tax fee:',
        PERIODICITY: 'Periodicity:',
        FIXED_OR_RELATIVE: 'Fixed or relative:',
        GRACE_PERIOD: 'Grace perdiod:',
        GRACE_PERIOD_NUMBER_OF_DAYS: 'Number of days:',
      },
    },
  },
};

export default PRODUCTS_AND_OFFERS_TRANSLATE;
