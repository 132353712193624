import { Component, OnInit } from '@angular/core';
import {
  getSkeleton,
  Skeleton,
  SkeletonTheme,
} from '@app/core/models/skeleton.model';

@Component({
  selector: 'layout-summary-items-loader',
  templateUrl: './summary-items-loader.component.html',
  styleUrls: ['./summary-items-loader.component.scss'],
})
export class SummaryItemsLoaderComponent implements OnInit {
  public skeletonItems: Skeleton[];

  private skeletonTheme: SkeletonTheme;

  constructor() {}

  ngOnInit(): void {
    const margin: string = '0 10px 5px 0';
    this.skeletonTheme = {
      minWidth: 80,
      maxWidth: 250,
      margin,
    };
    this.skeletonItems = getSkeleton(2, 5, this.skeletonTheme);
  }
}
