import {
  Payment,
  PAYMENT_STATUS,
} from '@app/plugins/modules/loan-manager/core/models/payment.model';
import { TableResponse } from '@app/presentation/layout/mo-tables/interfaces/table.interface';

export const PAYMENTS: TableResponse<Payment> = {
  results: [
    {
      id: '1234567',
      reference: '812348-123123',
      loan_id: '123456',
      loan_reference: '12345ASD',
      customer_reference: '12318112312',
      status: PAYMENT_STATUS.ACCEPTED,
      amount: 110010,
      type: 'discount',
      paid_at: new Date('2019-10-01T15:59:15-05:00'),
    },
    {
      id: '1234567',
      reference: '812348-123123',
      loan_id: '123456',
      loan_reference: '12345ASD',
      customer_reference: '12318112312',
      status: PAYMENT_STATUS.REVERSED,
      amount: 110010,
      type: 'discount',
      paid_at: new Date('2019-10-01T15:59:15-05:00'),
    },
    {
      id: '1234567',
      reference: '812348-123123',
      loan_id: '123456',
      loan_reference: '12345ASD',
      customer_reference: '12318112312',
      status: PAYMENT_STATUS.REJECTED,
      amount: 110010,
      type: 'discount',
      paid_at: new Date('2019-10-01T15:59:15-05:00'),
    },
    {
      id: '1234567',
      reference: '812348-123123',
      loan_id: '123456',
      loan_reference: '12345ASD',
      customer_reference: '12318112312',
      status: PAYMENT_STATUS.ACCEPTED,
      amount: 110010,
      type: 'discount',
      paid_at: new Date('2019-10-01T15:59:15-05:00'),
    },
    {
      id: '1234567',
      reference: '812348-123123',
      loan_id: '123456',
      loan_reference: '12345ASD',
      customer_reference: '12318112312',
      status: PAYMENT_STATUS.REVERSED,
      amount: 110010,
      type: 'discount',
      paid_at: new Date('2019-10-01T15:59:15-05:00'),
    },
  ],
  filtered: 5,
  count: 5,
};
