<nav class="navbar">
  <div class="navbar-options">
    <a
      class="mo-button size-md template-primary"
      href="mailto:{{ email }}"
      target="_blank"
    >
      {{ "APP.MENUS.NAVBAR.NEED_SUPPORT" | translate }}
    </a>
    <navbar-user-card [label]="user?.name">
      <layout-user-card-menu (logout)="onLogout()"> </layout-user-card-menu>
    </navbar-user-card>
  </div>
</nav>
