import { random } from './utils.model';

export interface Skeleton {
  appearance?: '' | 'line' | 'circle';
  theme?: SkeletonTheme;
  rows?: string;
}

export interface SkeletonTheme {
  width?: string;
  minWidth?: number;
  maxWidth?: number;
  height?: string;
  margin?: string | number;
}

/**
 * Make skeleton for any template
 * @param rows number, Number of cols for row
 * @param count number, defaul 1, Number of skeleton rows
 * @param skeletonTheme SkeletonTheme, SkeletonTheme object
 * @returns Skeleton[]
 */
export function getSkeleton(
  rows: number,
  count: number,
  skeletonTheme: SkeletonTheme
): Skeleton[] {
  const skeleton = [];
  for (let i = 0; i < count; i++) {
    skeleton.push({
      theme: {
        margin: skeletonTheme?.margin ?? 0,
        width: _getSkeletonWidth(skeletonTheme),
        height: skeletonTheme?.height ?? '*',
      },
      rows: rows.toString(),
    });
  }
  return skeleton;
}

function _getSkeletonWidth(skeletonTheme: SkeletonTheme): string {
  return skeletonTheme?.width
    ? skeletonTheme?.width
    : `${random(skeletonTheme?.minWidth, skeletonTheme?.maxWidth)}px`;
}
