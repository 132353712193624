import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { FILTERS } from '@app/core/constants/filters.constant';
import { Filters } from '@app/core/models/filters.model';
import { Skeleton } from '@app/core/models/skeleton.model';
import {
  LIST_WITH_CONTROLS_DEFAULT_STYLES,
  SKELETON_LIST_WITH_CONTROLS_DEFAULT,
} from '@app/presentation/layout/mo-tables/constants/list-with-controls.constants';
import {
  PAGE_DEFAULT,
  PAGE_SIZE_DEFAULT,
} from '@app/presentation/layout/mo-tables/constants/page-size.constant';
import { TableResponse } from '@app/presentation/layout/mo-tables/interfaces/table.interface';

@Component({
  selector: 'mo-list-with-controls',
  templateUrl: './list-with-controls.component.html',
  styleUrls: ['./list-with-controls.component.scss'],
})
export class ListWithControlsComponent<T> implements OnChanges {
  @Input() isLoading: boolean;
  @Input() response: TableResponse<T>;
  @Input() customTemplate: TemplateRef<T>;
  @Input() customContentStyles: any = LIST_WITH_CONTROLS_DEFAULT_STYLES;
  @Input() skeletons: Skeleton[] = SKELETON_LIST_WITH_CONTROLS_DEFAULT;
  @Input() pageSize: number = PAGE_SIZE_DEFAULT;

  @Output() changeTableFilters: EventEmitter<Filters> =
    new EventEmitter<Filters>();
  @Output() changePage: EventEmitter<number> = new EventEmitter<number>();

  public currentPage: number = PAGE_DEFAULT;
  private _resetControls: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    this._resetControls = Boolean(
      changes['response'] &&
        changes['response']?.currentValue?.links?.previous === null
    );
    if (this._resetControls) this.currentPage = FILTERS.offset;
  }

  public onChangePage(currentPage: number) {
    this.currentPage = currentPage;
    this.changePage.emit(currentPage);
    this._calculatePageFilter();
  }

  private _calculatePageFilter() {
    const offset: number = (this.currentPage - 1) * this.pageSize;
    const pageFilter: Filters = { offset, limit: this.pageSize };
    this.changeTableFilters.emit(pageFilter);
  }
}
