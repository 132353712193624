import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'layout-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit {
  @Input() status: string;

  constructor() {}

  ngOnInit(): void {}
}
