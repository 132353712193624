import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MENU_CONFIG_DISABLED } from '@instance-config/menu.config';
import { FEATURES } from './core/constants/feature.constants';
import { LoggedGuard } from './core/guard/logged.guard';
import { UnloggedGuard } from './core/guard/unlogged.guard';
import { PLUGINS_ROUTES } from './plugins/constants/routes.constants';
import { BaseLoggedComponent } from './presentation/layout/components/base-logged/base-logged.component';

const CHILDREN_ROUTES: Routes = [
  {
    path: FEATURES.MY_ACCOUNT.PATH,
    loadChildren: () =>
      import('./presentation/features/account/account.module').then(
        (m) => m.AccountModule
      ),
    data: {
      breadcrumb: 'MY_ACCOUNT.ACCOUNT.TITLE',
      disabled: MENU_CONFIG_DISABLED.MY_ACCOUNT.MODULE ?? false
    },
  },
  ...PLUGINS_ROUTES,
].filter(route => !route?.data?.disabled);

const routes: Routes = [
  {
    path: FEATURES.AUTH.PATH,
    canActivate: [UnloggedGuard],
    canDeactivate: [UnloggedGuard],
    canLoad: [UnloggedGuard],
    loadChildren: () =>
      import('./presentation/features/auth/auth.module').then(
        (m) => m.AuthModule
      ),
  },
  {
    path: '',
    component: BaseLoggedComponent,
    canActivate: [LoggedGuard],
    canLoad: [LoggedGuard],
    canActivateChild: [LoggedGuard],
    children: [
      {
        path: '',
        redirectTo: CHILDREN_ROUTES[0]?.path,
        pathMatch: 'full',
      },
      ...CHILDREN_ROUTES
    ]
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
