import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanDeactivate,
  CanLoad,
  Router,
  UrlTree,
} from '@angular/router';
import { AuthFacade } from '@app/facade/auth/auth.facade';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UnloggedGuard
  implements CanActivate, CanDeactivate<unknown>, CanLoad
{
  constructor(private _authFacade: AuthFacade, private _router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this._validSession();
  }

  canLoad(): Observable<boolean | UrlTree> {
    return this._validSession();
  }

  canDeactivate(): Observable<boolean | UrlTree> {
    return this._authFacade.isAuthenticated$;
  }

  private _validSession(): Observable<UrlTree | boolean> {
    return this._authFacade.isAuthenticated$.pipe(
      map((isAuthenticated: boolean) => {
        if (!isAuthenticated) return true;
        return this._router.createUrlTree(['']);
      })
    );
  }
}
