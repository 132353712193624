import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import {
  ORIENTATION,
  SIZES,
} from '@app/presentation/layout/mo-forms/enums/fields.type';
import { SelectOption } from '@app/presentation/layout/mo-forms/interfaces/form.interface';
import {
  PAGE_SIZE_DEFAULT,
  PAGE_SIZE_OPTIONS,
} from '../../constants/page-size.constant';

@Component({
  selector: 'table-page-size',
  templateUrl: './page-size.component.html',
  styleUrls: ['./page-size.component.scss'],
})
export class PageSizeComponent implements OnChanges {
  @Input() pageSize: number = PAGE_SIZE_DEFAULT;
  @Output() changePageSize: EventEmitter<number>;

  public pageSizeOptions: SelectOption<number>[];
  public seletedOption: number;
  public ORIENTATION = ORIENTATION;
  public SIZES = SIZES;

  constructor() {
    this.pageSizeOptions = PAGE_SIZE_OPTIONS;
    this.changePageSize = new EventEmitter();
  }

  ngOnChanges(): void {
    this.seletedOption = this.pageSize;
  }

  onChangePageSize(value: number | string): void {
    this.changePageSize.emit(Number(value));
  }
}
