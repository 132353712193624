import { MerchantGroupsPageTranslate } from '../../models/merchant-groups-translate';

const MERCHANT_GROUPS_TRANSLATE: MerchantGroupsPageTranslate = {
  MERCHANT_GROUPS: {
    TITLE: 'Merchant Groups',
    DESCRIPTION:
      'Create new and review existing merchant groups. Filter by merchant name and group name.',
    FILTERS: {
      SEARCH_BAR: 'Group name',
    },
  },
};
export default MERCHANT_GROUPS_TRANSLATE;
