import { Component, EventEmitter, Input, Output } from '@angular/core';
import { APP_CONSTANTS } from '@app/core/constants/app.constants';
import { User } from '@app/core/models/user.model';
import {
  BUTTON_DESIGN_CLASS,
  BUTTON_TYPE,
  SIZES,
} from '../../mo-forms/enums/fields.type';

@Component({
  selector: 'layout-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  @Input() user: User;
  @Output() logout: EventEmitter<boolean>;

  public BUTTON_TYPE = BUTTON_TYPE;
  public BUTTON_DESIGN_CLASS = BUTTON_DESIGN_CLASS;
  public SIZES = SIZES;
  public isVisible: boolean;
  public email: string;

  constructor() {
    this.logout = new EventEmitter();
    this.email = APP_CONSTANTS.SUPPORT_EMAIL;
  }

  onLogout() {
    this.isVisible = false;
    this.logout.emit(true);
  }
}
