import { OverviewPageTranslate } from '../../models/overview-translate';

const OVERVIEW_TRANSLATE: OverviewPageTranslate = {
  OVERVIEW: {
    TITLE: 'Overview',
    DESCRIPTION:
      'Manage and oversee your customer and pre-customer base, whilst visualizing their credit and payment behavior.',
  },
};
export default OVERVIEW_TRANSLATE;
