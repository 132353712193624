import { ItemSubMenu, MenuItem, MENU_ITEM_TYPE } from '@app/core/models/menu.model';
import { MENU_CONFIG_DISABLED } from '@instance-config/menu.config';
import { MERCHANT_MANAGER_URLS } from './urls.constants';

const SUB_ITEMS_MENU: ItemSubMenu[] = [
  {
    type: MENU_ITEM_TYPE.SUB_MENU_ITEM,
    label: 'MERCHANT_MANAGER._MENU.OVERVIEW',
    path: MERCHANT_MANAGER_URLS.OVERVIEW,
    isSubMenu: true,
    disabled: MENU_CONFIG_DISABLED.MERCHANT_MANAGER.OVERVIEW ?? false
  },
  {
    type: MENU_ITEM_TYPE.SUB_MENU_ITEM,
    label: 'MERCHANT_MANAGER._MENU.MERCHANTS',
    path: MERCHANT_MANAGER_URLS.MERCHANTS,
    isSubMenu: true,
    disabled: MENU_CONFIG_DISABLED.MERCHANT_MANAGER.MERCHANTS ?? false
  },
  {
    type: MENU_ITEM_TYPE.SUB_MENU_ITEM,
    label: 'MERCHANT_MANAGER._MENU.PURCHASES',
    path: MERCHANT_MANAGER_URLS.PURCHASES,
    isSubMenu: true,
    disabled: MENU_CONFIG_DISABLED.MERCHANT_MANAGER.PURCHASES ?? false
  },
  {
    type: MENU_ITEM_TYPE.SUB_MENU_ITEM,
    label: 'MERCHANT_MANAGER._MENU.MERCHANT_GROUPS',
    path: MERCHANT_MANAGER_URLS.MERCHANT_GROUPS,
    isSubMenu: true,
    disabled: MENU_CONFIG_DISABLED.MERCHANT_MANAGER.MERCHANT_GROUPS ?? false
  },
].filter(item => !item.disabled);

export const MERCHANT_MANAGER_MENU: MenuItem[] = [
  {
    type: MENU_ITEM_TYPE.ITEM_ACCORDION,
    icon: 'app/presentation/assets/img/icons/menu/merchant-manager.svg',
    label: 'MERCHANT_MANAGER._NAME',
    path: SUB_ITEMS_MENU[0]?.path ?? '',
    disabled: MENU_CONFIG_DISABLED.MERCHANT_MANAGER.MODULE ?? false,
    item: {
      type: MENU_ITEM_TYPE.SUB_MENU,
      items: SUB_ITEMS_MENU
    },
  },
];
