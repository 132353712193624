import { MyAccountPageTranslate } from '@app/core/i18n/models/my-account-translate';

const MY_ACCOUNT: MyAccountPageTranslate = {
  ACCOUNT: {
    TITLE: 'Mi cuenta',
    DESCRIPTION:
      'Ver y editar los detalles y la información de su cuenta. Administre los perfiles de los usuarios en función de sus respectivos roles. Crear nuevos usuarios a través de correo electrónico.',
    YOUR_DETAILS: {
      TITLE: 'Tus detalles',
      ROL_TITLE: 'Rol de perfil',
      NAME: 'Nombre',
      EMAIL: 'Correo electrónico',
    },
    ACCOUNT_DETAILS: {
      TITLE: 'Detalles de la cuenta',
      NAME: 'Nombre',
      DESCRIPTION: 'Descripción',
      MO_MANAGE_FAMILY: 'Familia Mo Manage',
      ADD_IN_MO_PRODUCTS: 'MO Products añadidos',
      COUNTRY: 'País',
      CURRENCY: 'Moneda',
      TOP_LIMIT_TITLE: 'Límite superior',
      TOP_LIMIT_DESCRIPTION:
        'Establezca el número máximo absoluto de créditos activos que un cliente puede tener al mismo tiempo para cualquier producto u oferta.',
    },
    TABLE_CARD: {
      TITLE: 'Usuarios',
      TAB_USERS: 'Usuarios',
      TAB_INVITATIONS: 'Invitaciones enviadas',
      TABLE: {
        NAME: 'Nombre',
        STATUS: 'Estado',
        ROL: 'Rol',
      },
    },
  },
};
export default MY_ACCOUNT;
