import { Component, Input } from '@angular/core';

@Component({
  selector: 'layout-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() total?: number;

  constructor() {}
}
