import { Component, Input } from '@angular/core';

@Component({
  selector: 'layout-summary-item',
  templateUrl: './summary-item.component.html',
  styleUrls: ['./summary-item.component.scss'],
})
export class SummaryItemComponent {
  @Input() name: string;
  @Input() value: string | number;
  @Input() isBold?: boolean = false;
  @Input() isHighlighted?: boolean = false;
  @Input() isDashed?: boolean = false;
}
