import { Component, Input } from '@angular/core';

@Component({
  selector: 'layout-country-currency',
  templateUrl: './country-currency.component.html',
  styleUrls: ['./country-currency.component.scss'],
})
export class CountryCurrencyComponent {
  @Input() image: string;
  @Input() currency: string;
}
