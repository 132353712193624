<div class="basic-slides">
  <div class="basic-slides__slides">
    <div class="content">
      <img [src]="logo" class="content__logo" alt="Mo" *ngIf="logo" />
      <h1 class="content__title" *ngIf="title">{{ title }}</h1>
      <p class="content__description" *ngIf="subTitle">
        {{ subTitle }}
      </p>
    </div>
    <img [src]="background" class="background" *ngIf="background" />
  </div>
  <div class="basic-slides__content">
    <ng-content></ng-content>
  </div>
</div>
