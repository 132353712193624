import { APP_MENU } from '@app/core/constants/menus.constants';
import { DEFAULT_LANGUAGE } from '@app/core/i18n/constants/translate.constants';
import { Action, createReducer, on } from '@ngrx/store';
import { updateLanguage } from './app.actions';
import { AppState } from './app.state';

export const initialAppState: AppState = {
  menu: APP_MENU,
  language: DEFAULT_LANGUAGE,
};

const _appReducer = createReducer(
  initialAppState,
  on(updateLanguage, (state, { language }) => ({
    ...state,
    language,
  }))
);

export function AppReducers(state: AppState | undefined, action: Action) {
  return _appReducer(state, action);
}
