import { TableHeader } from '../interfaces/table.interface';

export interface iTableConst<T> {
  HEADERS: TableHeader[];
  DATA: T;
}

export const TABLE_COLUMNS_SIZE = {
  TOTAL_COLUMNS: 12,
  MIN_COLUMNS: 4,
};
