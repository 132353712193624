<section class="page-size">
  <label> {{ "APP.FILTERS.RECORDS_PER_PAGE" | translate }} </label>
  <mo-drop-down
    placeholder="Request status"
    [options]="pageSizeOptions"
    [orientation]="ORIENTATION.TOP"
    [defaultOptionName]="pageSize"
    [size]="SIZES.SM"
    [canUnSelect]="false"
    (changeValue)="onChangePageSize($event)"
  ></mo-drop-down>
</section>
