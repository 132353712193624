import { MerchantGroupsPageTranslate } from '../../models/merchant-groups-translate';

const MERCHANT_GROUPS_TRANSLATE: MerchantGroupsPageTranslate = {
  MERCHANT_GROUPS: {
    TITLE: 'Grupos de Comercios',
    DESCRIPTION:
      'Cree nuevos grupos de comerciantes y revise los existentes. Filtre por nombre de comerciante y nombre de grupo.',
    FILTERS: {
      SEARCH_BAR: 'Nombre del grupo',
    },
  },
};
export default MERCHANT_GROUPS_TRANSLATE;
