import { Injectable } from '@angular/core';
import { RefreshToken, Token } from '@app/core/models/auth.model';
import { COOKIES } from '@app/data/constants/cookies.constants';
import { CookiesService } from '@app/data/cookies/cookies.service';
import * as authActions from '@app/domain/store/auth/auth.actions';
import * as projectActions from '@app/domain/store/project/project.actions';
import * as userActions from '@store/user/user.actions';

import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private _store: Store, private _cookiesService: CookiesService) {}

  public getSessionToken(): Token {
    return this._cookiesService.getCookie(COOKIES.TOKEN) as Token;
  }

  public checkSession() {
    const tokenSession = this._cookiesService.getCookie(COOKIES.TOKEN);
    if (!tokenSession) this._store.dispatch(authActions.logout());
    else this._validateLoggedInfo();
  }

  public updateSessionToken(token: Token): void {
    this._cookiesService.setCookie(COOKIES.TOKEN, token, token?.expires);
    this._validateLoggedInfo();
  }

  public getRefreshToken(): RefreshToken {
    const sessionToken: Token = this.getSessionToken();
    let refreshToken: RefreshToken;
    if (sessionToken) {
      refreshToken = {
        refresh_token: sessionToken?.refresh_token,
      };
    }
    return refreshToken;
  }

  public logout() {
    this._cookiesService.deleteCookie(COOKIES.TOKEN);
  }

  private _validateLoggedInfo() {
    this._store.dispatch(
      authActions.updateIsAuthenticated({ isAuthenticated: true })
    );
    this._store.dispatch(projectActions.getProject());
    this._store.dispatch(userActions.getUserInfo());
  }
}
