import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ServerMock } from '@app/data/mocks/app-serve.mock';
import { AppFacade } from '@app/facade/app/app.facade';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { APP_CONSTANTS } from '../constants/app.constants';
import { MESSAGES } from '../constants/messages.constants';

@Injectable()
export class AppMockInterceptorService implements HttpInterceptor {
  private _localServer: ServerMock;

  constructor(private _appFacade: AppFacade) {
    this._localServer = new ServerMock();
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this._checkIsTest(req)) {
      return this._sendLocalServer(req);
    }
    return next.handle(req);
  }
  /**
   * Check if environment not is production and token is for test or email and password is given for test
   * @param req - Info request sended
   */
  private _checkIsTest(req: HttpRequest<any>): boolean {
    const hasTestToken = this.getToken() === APP_CONSTANTS.TEST_INFO.TOKEN;
    const hasTestInfo =
      req.body && req.body.user == APP_CONSTANTS.TEST_INFO.USER;
    return (
      !environment.production &&
      (environment.isTestServer || hasTestToken || hasTestInfo)
    );
  }

  public getToken(): string | null {
    return localStorage.getItem(APP_CONSTANTS.PROPS.STORAGE_TOKEN_MOCK);
  }

  public setToken(token: string): void {
    const current = new Date().getTime();
    localStorage.setItem(APP_CONSTANTS.PROPS.STORAGE_TOKEN_MOCK, token);
    localStorage.setItem(
      APP_CONSTANTS.PROPS.STORAGE_HOUR_TOKEN,
      current.toString()
    );
  }

  private _sendLocalServer(req: any): Observable<HttpEvent<any>> {
    return this._localServer.request(req).pipe(
      map((response: any) => {
        this._checkTokenResponse(response);
        return response;
      }),
      catchError((err: HttpErrorResponse) => this._catchError(req, err))
    );
  }
  private _checkTokenResponse(response: any) {
    if (response.token) {
      this.setToken(response.token);
    }
    if (response.body?.data?.access_token) {
      this.setToken(response.body?.data?.access_token);
    }
    if (response.headers && response.headers.get('token')) {
      this.setToken(response.headers.get('token'));
    }
  }

  private _catchError(req: HttpRequest<any>, error: any) {
    let messageError = MESSAGES.ERRORS.DEFAULT;

    if (error.status === APP_CONSTANTS.HTTP_CODES.NOT_FOUND) {
      this._showError(req, MESSAGES.ERRORS.NOT_FOUND_RESOURCE);
      return throwError(() => error);
    }

    if (error.error) {
      const err = error.error;
      messageError = this._message(err);
    }
    this._showError(req, messageError);
    return throwError(() => error);
  }

  private _message(err) {
    const firstError = Object.keys(err)[0];
    const typeError = typeof err[firstError];
    const isStringError = typeError === 'string';

    if (!isStringError && typeError === 'object') {
      return this._message(err[firstError][0]);
    }
    return isStringError ? err[firstError] : err[firstError][0];
  }

  private _showError(req: any, msg, error = null) {
    // showError attribute send from component that don't need to show snackbar error (only when send false)
    if (req.body && req.body.showError === false) {
      return;
    }
    msg = !msg || msg.length <= 2 ? MESSAGES.ERRORS.DEFAULT : msg;
    this._appFacade.showGlobalError(msg);
  }
}
