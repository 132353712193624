<div class="container-user-card" (click)="active = !active">
  <img src="/app/presentation/assets/img/icons/face-smile.svg" />
  <label>{{ label }}</label>
  <i class="uil" [ngClass]="active ? 'uil-angle-up' : 'uil-angle-down'"></i>
</div>
<div
  class="background-clickeable"
  (click)="active = false"
  *ngIf="active"
></div>
<div *ngIf="active" [@inOutAnimation]>
  <ng-content></ng-content>
</div>
