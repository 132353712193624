import { Component, Input, OnInit } from '@angular/core';
import {
  getSkeleton,
  Skeleton,
  SkeletonTheme,
} from '@app/core/models/skeleton.model';
import { random } from '@app/core/models/utils.model';

@Component({
  selector: 'layout-profile-info-card',
  templateUrl: './profile-info-card.component.html',
  styleUrls: ['./profile-info-card.component.scss'],
})
export class ProfileInfoCardComponent implements OnInit {
  @Input() isLoading: boolean;
  public skeletons: Skeleton[];

  private skeletonTheme: SkeletonTheme;

  constructor() {}

  ngOnInit(): void {
    const margin: string = '0 10px 5px 0';
    this.skeletonTheme = {
      minWidth: 80,
      maxWidth: 250,
      margin,
    };
    this.skeletons = [
      ...getSkeleton(random(1, 2), 1, this.skeletonTheme),
      ...getSkeleton(2, 1, this.skeletonTheme),
    ];
  }
}
