import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MoButtonComponent } from '@app/presentation/layout/mo-forms/components/mo-button/mo-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { UserCardMenuComponent } from './components/user-card-menu/user-card-menu.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { NavbarComponent } from './navbar.component';

const COMPONENTS = [NavbarComponent, UserCardComponent, UserCardMenuComponent];
@NgModule({
  declarations: COMPONENTS,
  imports: [CommonModule, TranslateModule, MoButtonComponent],
  exports: COMPONENTS,
})
export class NavbarModule {}
