export const API_URLS = {
  AUTH: {
    LOGIN: '/authentication/token',
    REFRESH_TOKEN: '/refresh_token',
  },
  USER: {
    GET_INFO: '/user',
  },
  PROJECT: {
    GET_PROJECT: '/projects',
  },
};
