import ENGLISH_MCA_CUSTOMERS from '@app/plugins/modules/customers/core/i18n/lang-mca/en';
import ENGLISH_MCA_LOAN_MANAGER from '@app/plugins/modules/loan-manager/core/i18n/lang-mca/en';
import ENGLISH_MCA_MERCHANT_MANAGER from '@app/plugins/modules/merchant-manager/core/i18n/lang-mca/en';
import ENGLISH_MCA_PRODUCT_CATALOGUE from '@app/plugins/modules/product-catalogue/core/i18n/lang-mca/en';

const ENGLISH_PLUGINS_MCA_TRANSLATE = {
  CUSTOMERS: { ...ENGLISH_MCA_CUSTOMERS },
  LOAN_MANAGER: { ...ENGLISH_MCA_LOAN_MANAGER },
  PRODUCT_CATALOGUE: { ...ENGLISH_MCA_PRODUCT_CATALOGUE },
  MERCHANT_MANAGER: { ...ENGLISH_MCA_MERCHANT_MANAGER },
};
export default ENGLISH_PLUGINS_MCA_TRANSLATE;
