import { DateTranslate } from '@app/core/i18n/models/date-translate';

const DATES_TRANSLTE: DateTranslate = {
  DATE_OF_WEEK: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
  MONTH_NAMES: [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic',
  ],
  RANGE_OPTIONS: {
    LAST_7_DAYS: 'Ultimos 7 días',
    LAST_14_DAYS: 'Últimos 14 días',
    LAST_30_DAYS: 'Últimos 30 días',
    LAST_3_MOUNTS: 'Últimos 3 meses',
    LAST_12_MOUNTS: 'últimos 12 meses',
    MONTH_TO_DATE: 'Mes hasta la fecha',
    QUARTER_TO_DATE: 'Trimestre a la fecha',
    ALL_TIME: 'Todo el tiempo',
    CUSTMOM: 'Personalizado',
  },
  BUTTONS: {
    CANCEL: 'Cancelar',
    SET_DATE: 'Definir la fecha',
  },
};

export default DATES_TRANSLTE;
