import SPANISH_DEFAULT_LOAN_MANAGER from '@app/plugins/modules/loan-manager/core/i18n/lang-default/es';
import LOAN_REQUEST_TRANSLATE from './loan-request.translate';
import LOANS_TRANSLATE from './loans.translate';

const SPANISH_BNPL_LOAN_MANAGER = {
  ...SPANISH_DEFAULT_LOAN_MANAGER,
  ...LOAN_REQUEST_TRANSLATE,
  ...LOANS_TRANSLATE,
};
export default SPANISH_BNPL_LOAN_MANAGER;
