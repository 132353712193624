import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  public setCookie(
    name: string,
    value: any,
    expireDate: Date | number,
    path: string = '',
    sameSite: 'lax' | 'strict' | 'none' = 'lax'
  ) {
    const expires: string = 'expires=' + new Date(expireDate).toUTCString();
    const valueString = JSON.stringify(value);
    document.cookie = `${name}=${valueString}; ${expires}; path=${
      path.length > 0 ? `${path}` : '/'
    }; SameSite=${sameSite}; Secure`;
  }

  public getCookie(name: string): any {
    let cookie = document.cookie
      .split(';')
      .find((row) => row.replace(' ', '').startsWith(`${name}=`))
      ?.split('=')[1];

    return cookie ? JSON.parse(cookie) : cookie;
  }

  public deleteCookie(name: string) {
    this.setCookie(name, '', -1);
  }
}
