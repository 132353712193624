export const PRODUCT_CATALOGUE_FEATURE = {
  NAME: 'Product Catalogue',
  PATH: 'product-catalogue',
  PAGES: {
    PRODUCTS_AND_OFFERS: {
      STORE_NAME: 'productCatalogue',
      NAME: 'Products & offers',
      PATH: 'products-and-offers',
      SUB_PAGES: {
        CREATE_NEW_OFFER: {
          NAME: 'Create new offer',
          PATH: 'create-new-offer',
          SUB_PAGES: {
            OFFER_SPECS: {
              NAME: 'Offer Specs',
              PATH: 'offer-specs',
            },
            OFFER_SUMMARY: {
              NAME: 'Offer Summary',
              PATH: 'offer-summary',
            },
            SUCCESS: {
              NAME: 'Success',
              PATH: 'success',
            },
          },
        },
        CREATE_NEW_PRODUCT: {
          NAME: 'Create new product',
          PATH: 'create-new-product',
          SUB_PAGES: {
            FORM: {
              NAME: 'General Settings',
              PATH: 'form',
            },
            SUMMARY: {
              NAME: 'Summary',
              PATH: 'summary',
            },
            SUCCESS: {
              NAME: 'Success',
              PATH: 'success',
            },
          },
        },
        PRODUCT_PROFILE: {
          NAME: 'Product profile',
          PATH: 'product-profile',
        },
        OFFER_PROFILE: {
          NAME: 'Offer profile',
          PATH: 'offer-profile',
        },
      },
    },
  },
};
