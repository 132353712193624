import SPANISH_OVERRIDE_DEFAULT_TRANSLATE from '@app/override/i18n/lang-default/es';
import APP_TRANSLATE from './app/index.translate';
import AUTH_TRANSLATE from './auth/login.translate';
import MY_ACCOUNT_TRANSLATE from './my-account';

export const BASE_SPANISH_TRASNLATE = {
  APP: { ...APP_TRANSLATE },
  AUTH: { ...AUTH_TRANSLATE },
  MY_ACCOUNT: { ...MY_ACCOUNT_TRANSLATE },
};

const SPANISH_DEFAULT = {
  ...BASE_SPANISH_TRASNLATE,
  ...SPANISH_OVERRIDE_DEFAULT_TRANSLATE,
};
export default SPANISH_DEFAULT;
