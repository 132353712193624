import ENGLISH_CREDIT_CARD_CUSTOMERS from '@app/plugins/modules/customers/core/i18n/lang-credit-card/en';
import ENGLISH_CREDIT_CARD_LOAN_MANAGER from '@app/plugins/modules/loan-manager/core/i18n/lang-credit-card/en';
import ENGLISH_CREDIT_CARD_MERCHANT_MANAGER from '@app/plugins/modules/merchant-manager/core/i18n/lang-credit-card/en';
import ENGLISH_CREDIT_CARD_PRODUCT_CATALOGUE from '@app/plugins/modules/product-catalogue/core/i18n/lang-credit-card/en';

const ENGLISH_PLUGINS_CREDIT_CARD_TRANSLATE = {
  CUSTOMERS: { ...ENGLISH_CREDIT_CARD_CUSTOMERS },
  LOAN_MANAGER: { ...ENGLISH_CREDIT_CARD_LOAN_MANAGER },
  PRODUCT_CATALOGUE: { ...ENGLISH_CREDIT_CARD_PRODUCT_CATALOGUE },
  MERCHANT_MANAGER: { ...ENGLISH_CREDIT_CARD_MERCHANT_MANAGER },
};
export default ENGLISH_PLUGINS_CREDIT_CARD_TRANSLATE;
