import { DateTranslate } from '@app/core/i18n/models/date-translate';

const DATES_TRANSLTE: DateTranslate = {
  DATE_OF_WEEK: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  MONTH_NAMES: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  RANGE_OPTIONS: {
    LAST_7_DAYS: 'Last 7 days',
    LAST_14_DAYS: 'Last 14 Days',
    LAST_30_DAYS: 'Last 30 Days',
    LAST_3_MOUNTS: 'Last 3 months',
    LAST_12_MOUNTS: 'Last 12 months',
    MONTH_TO_DATE: 'Month to date',
    QUARTER_TO_DATE: 'Quarter to date',
    ALL_TIME: 'All time',
    CUSTMOM: 'Custom',
  },
  BUTTONS: {
    CANCEL: 'Cancel',
    SET_DATE: 'Set Date',
  },
};

export default DATES_TRANSLTE;
