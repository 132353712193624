import SPANISH_CREDIT_CARD_CUSTOMERS from '@app/plugins/modules/customers/core/i18n/lang-credit-card/es';
import SPANISH_CREDIT_CARD_LOAN_MANAGER from '@app/plugins/modules/loan-manager/core/i18n/lang-credit-card/es';
import SPANISH_CREDIT_CARD_MERCHANT_MANAGER from '@app/plugins/modules/merchant-manager/core/i18n/lang-credit-card/es';
import SPANISH_CREDIT_CARD_PRODUCT_CATALOGUE from '@app/plugins/modules/product-catalogue/core/i18n/lang-credit-card/es';

const SPANISH_PLUGINS_CREDIT_CARD_TRANSLATE = {
  CUSTOMERS: { ...SPANISH_CREDIT_CARD_CUSTOMERS },
  LOAN_MANAGER: { ...SPANISH_CREDIT_CARD_LOAN_MANAGER },
  PRODUCT_CATALOGUE: { ...SPANISH_CREDIT_CARD_PRODUCT_CATALOGUE },
  MERCHANT_MANAGER: { ...SPANISH_CREDIT_CARD_MERCHANT_MANAGER },
};
export default SPANISH_PLUGINS_CREDIT_CARD_TRANSLATE;
