import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Router,
  UrlTree,
} from '@angular/router';
import { AuthFacade } from '@app/facade/auth/auth.facade';
import { map, Observable } from 'rxjs';
import { FEATURES } from '../constants/feature.constants';

@Injectable({
  providedIn: 'root',
})
export class LoggedGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(private _authFacade: AuthFacade, private _router: Router) {}

  canActivateChild(): Observable<UrlTree | boolean> {
    return this._validSession();
  }

  canActivate(): Observable<UrlTree | boolean> {
    return this._validSession();
  }

  canLoad(): Observable<UrlTree | boolean> {
    return this._validSession();
  }

  private _validSession(): Observable<UrlTree | boolean> {
    return this._authFacade.isAuthenticated$.pipe(
      map((isAuthenticated: boolean) => {
        if (isAuthenticated) return true;
        return this._router.createUrlTree([FEATURES.AUTH.PATH]);
      })
    );
  }
}
