export const API_URLS = {
  PRE_CUSTOMERS: {
    GET_PRE_CUSTOMERS: '/pre-customers',
    GET_PRE_CUSTOMERS_PRODUCTS_AND_SCORES: '/pre-customers/:id/scores',
  },
  CUSTOMERS: {
    GET_CUSTOMERS: '/customers',
    GET_CUSTOMERS_PRODUCTS_AND_SCORES: '/customers/:id/scores',
  },
  LOANS: {
    GET_LOANS: '/loans',
  },
  PAYMENTS: {
    GET_PAYMENTS: '/payments',
  },
};
