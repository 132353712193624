import ENGLISH_MICRO_CREDIT_CUSTOMERS from '@app/plugins/modules/customers/core/i18n/lang-micro-credit/en';
import ENGLISH_MICRO_CREDIT_LOAN_MANAGER from '@app/plugins/modules/loan-manager/core/i18n/lang-micro-credit/en';
import ENGLISH_MICRO_CREDIT_MERCHANT_MANAGER from '@app/plugins/modules/merchant-manager/core/i18n/lang-micro-credit/en';
import ENGLISH_MICRO_CREDIT_PRODUCT_CATALOGUE from '@app/plugins/modules/product-catalogue/core/i18n/lang-micro-credit/en';

const ENGLISH_PLUGINS_MICRO_CREDIT_TRANSLATE = {
  CUSTOMERS: { ...ENGLISH_MICRO_CREDIT_CUSTOMERS },
  LOAN_MANAGER: { ...ENGLISH_MICRO_CREDIT_LOAN_MANAGER },
  PRODUCT_CATALOGUE: { ...ENGLISH_MICRO_CREDIT_PRODUCT_CATALOGUE },
  MERCHANT_MANAGER: { ...ENGLISH_MICRO_CREDIT_MERCHANT_MANAGER },
};
export default ENGLISH_PLUGINS_MICRO_CREDIT_TRANSLATE;
