export enum TYPE_COLUMN {
  ID = 'id',
  LABEL = 'label',
  TITLE_CASE = 'title',
  DATE = 'date',
  DATE_TIME = 'datetime',
  STATUS = 'status',
  TOOLTIP = 'tooltip',
  CURRENCY = 'currency',
  DATA_POINT = 'datapoint',
  COMPOUND_LINK = 'compound-link',
  CUSTOM_TEMPLATE = 'custom-template',
}

export enum TABLE_OPTIONS_TYPE {
  SELECT = 'select',
  BUTTON = 'button',
  DROP_DOWN = 'dropdown',
}

export enum TABLE_DESIGN_CLASS {
  DEFAULT = 'template-default',
  ALTERNATIVE = 'template-alternative',
  WHITE = 'template-white',
}

export enum TABLE_SORT {
  ASC = 'ASC',
  DESC = 'DESC',
}
