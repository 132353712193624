import { Component, OnInit } from '@angular/core';
import { Menu } from '@app/core/models/menu.model';
import { User } from '@app/core/models/user.model';
import { AppFacade } from '@app/facade/app/app.facade';
import { AuthFacade } from '@app/facade/auth/auth.facade';
import { UserFacade } from '@app/facade/user/user.facade';
import { Observable } from 'rxjs';

@Component({
  selector: 'layout-base-logged',
  templateUrl: './base-logged.component.html',
  styleUrls: ['./base-logged.component.scss'],
})
export class BaseLoggedComponent implements OnInit {
  public menu$: Observable<Menu>;
  public user$: Observable<User>;

  constructor(
    private _appFacade: AppFacade,
    private _authFacade: AuthFacade,
    private _userFacade: UserFacade
  ) {}

  ngOnInit() {
    this._getGlobalData();
  }

  onLogout() {
    this._authFacade.logout();
  }

  private _getGlobalData() {
    this.menu$ = this._appFacade.menu$;
    this.user$ = this._userFacade.user$;
  }
}
