import { Injectable } from '@angular/core';
import { User } from '@app/core/models/user.model';
import { Store } from '@ngrx/store';
import * as userActions from '@store/user/user.actions';
import * as userSelectors from '@store/user/user.selectors';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserFacade {
  public user$: Observable<User>;

  constructor(private _store: Store) {
    this._setSelectors();
  }

  public getUserInfo() {
    this._store.dispatch(userActions.getUserInfo());
  }

  private _setSelectors() {
    this.user$ = this._store.select(userSelectors.selectData);
  }
}
