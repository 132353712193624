import {
  Offer,
  OFFER_STATUS,
} from '@app/plugins/modules/product-catalogue/core/models/offer.model';
import { TableResponse } from '@app/presentation/layout/mo-tables/interfaces/table.interface';
export const OFFERS: Offer[] = [
  {
    id: 'adbfad7c-16e3-4830-ac50-4f550002e78d',
    name: 'Offer-MCA',
    status: OFFER_STATUS.HIDDEN,
    reference: '0deebd2c-22d8-458d-825f-f336ee52d864',
    product_id: '0c157338-3058-4b44-a280-e644221b44cb',
    product_reference: 'd1a727f3-aca1-4b90-9f0c-ebe0c9c45c46',
    created_at: new Date('2022-07-12 21:56:59'),
    updated_at: new Date('2022-07-12 21:56:59'),
  },
  {
    id: '700ce1ae-c840-4de9-a61a-13050fdd8dff',
    name: 'Offer-MCA',
    status: OFFER_STATUS.DRAFT,
    reference: '69fcf7a4-1160-400e-a3b1-bae98b9bb925',
    product_id: '0c157338-3058-4b44-a280-e644221b44cb',
    product_reference: 'd1a727f3-aca1-4b90-9f0c-ebe0c9c45c46',
    created_at: new Date('2022-07-05 22:00:55'),
    updated_at: new Date('2022-07-05 22:00:55'),
  },
  {
    id: '384d755b-207f-45d4-b633-636b76360862',
    name: 'Offer-MCA',
    status: OFFER_STATUS.LIVE,
    reference: '6f222893-7219-4123-a8d0-118ecbaf092d',
    product_id: '0c157338-3058-4b44-a280-e644221b44cb',
    product_reference: 'd1a727f3-aca1-4b90-9f0c-ebe0c9c45c46',
    created_at: new Date('2022-07-08 15:43:26'),
    updated_at: new Date('2022-07-08 15:43:26'),
  },
  {
    id: '01ecbee0-8b31-48b6-b712-78c54783899f',
    name: 'Offer-MCA',
    status: OFFER_STATUS.HIDDEN,
    reference: '035060e6-5801-48d3-bb97-019504b873fd',
    product_id: '0c157338-3058-4b44-a280-e644221b44cb',
    product_reference: 'd1a727f3-aca1-4b90-9f0c-ebe0c9c45c46',
    created_at: new Date('2022-07-05 17:05:56'),
    updated_at: new Date('2022-07-05 17:05:56'),
  },
  {
    id: 'a1dbfad7c-16e3-4830-ac50-4f550002e78d',
    name: 'Offer-MCA',
    status: OFFER_STATUS.HIDDEN,
    reference: '0deebd2c-22d8-458d-825f-f336ee52d864',
    product_id: '0c157338-3058-4b44-a280-e644221b44cb',
    product_reference: 'd1a727f3-aca1-4b90-9f0c-ebe0c9c45c46',
    created_at: new Date('2022-07-12 21:56:59'),
    updated_at: new Date('2022-07-12 21:56:59'),
  },
  {
    id: '7020ce1ae-c840-4de9-a61a-13050fdd8dff',
    name: 'Offer-MCA',
    status: OFFER_STATUS.DRAFT,
    reference: '69fcf7a4-1160-400e-a3b1-bae98b9bb925',
    product_id: '0c157338-3058-4b44-a280-e644221b44cb',
    product_reference: 'd1a727f3-aca1-4b90-9f0c-ebe0c9c45c46',
    created_at: new Date('2022-07-05 22:00:55'),
    updated_at: new Date('2022-07-05 22:00:55'),
  },
  {
    id: '3384d755b-207f-45d4-b633-636b76360862',
    name: 'Offer-MCA',
    status: OFFER_STATUS.LIVE,
    reference: '6f222893-7219-4123-a8d0-118ecbaf092d',
    product_id: '0c157338-3058-4b44-a280-e644221b44cb',
    product_reference: 'd1a727f3-aca1-4b90-9f0c-ebe0c9c45c46',
    created_at: new Date('2022-07-08 15:43:26'),
    updated_at: new Date('2022-07-08 15:43:26'),
  },
  {
    id: '014ecbee0-8b31-48b6-b712-78c54783899f',
    name: 'Offer-MCA',
    status: OFFER_STATUS.HIDDEN,
    reference: '035060e6-5801-48d3-bb97-019504b873fd',
    product_id: '0c157338-3058-4b44-a280-e644221b44cb',
    product_reference: 'd1a727f3-aca1-4b90-9f0c-ebe0c9c45c46',
    created_at: new Date('2022-07-05 17:05:56'),
    updated_at: new Date('2022-07-05 17:05:56'),
  },
];

export const OFFERS_TABLE: TableResponse<Offer> = {
  results: [
    {
      id: 'adbfad7c-16e3-4830-ac50-4f550002e78d',
      name: 'Offer-MCA',
      status: OFFER_STATUS.HIDDEN,
      reference: '0deebd2c-22d8-458d-825f-f336ee52d864',
      product_id: '0c157338-3058-4b44-a280-e644221b44cb',
      product_reference: 'd1a727f3-aca1-4b90-9f0c-ebe0c9c45c46',
      created_at: new Date('2022-07-12 21:56:59'),
      updated_at: new Date('2022-07-12 21:56:59'),
    },
    {
      id: '700ce1ae-c840-4de9-a61a-13050fdd8dff',
      name: 'Offer-MCA',
      status: OFFER_STATUS.DRAFT,
      reference: '69fcf7a4-1160-400e-a3b1-bae98b9bb925',
      product_id: '0c157338-3058-4b44-a280-e644221b44cb',
      product_reference: 'd1a727f3-aca1-4b90-9f0c-ebe0c9c45c46',
      created_at: new Date('2022-07-05 22:00:55'),
      updated_at: new Date('2022-07-05 22:00:55'),
    },
    {
      id: '384d755b-207f-45d4-b633-636b76360862',
      name: 'Offer-MCA',
      status: OFFER_STATUS.LIVE,
      reference: '6f222893-7219-4123-a8d0-118ecbaf092d',
      product_id: '0c157338-3058-4b44-a280-e644221b44cb',
      product_reference: 'd1a727f3-aca1-4b90-9f0c-ebe0c9c45c46',
      created_at: new Date('2022-07-08 15:43:26'),
      updated_at: new Date('2022-07-08 15:43:26'),
    },
    {
      id: '01ecbee0-8b31-48b6-b712-78c54783899f',
      name: 'Offer-MCA',
      status: OFFER_STATUS.HIDDEN,
      reference: '035060e6-5801-48d3-bb97-019504b873fd',
      product_id: '0c157338-3058-4b44-a280-e644221b44cb',
      product_reference: 'd1a727f3-aca1-4b90-9f0c-ebe0c9c45c46',
      created_at: new Date('2022-07-05 17:05:56'),
      updated_at: new Date('2022-07-05 17:05:56'),
    },
  ],
  filtered: 4,
  count: 8,
};
