import { Component, EventEmitter, Output } from '@angular/core';
import {
  BUTTON_DESIGN_CLASS,
  COLOR_TEMPLATE,
  ICON_POSITION,
  SIZES,
} from '@app/presentation/layout/mo-forms/enums/fields.type';

@Component({
  selector: 'table-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss'],
})
export class OptionsComponent {
  @Output() download: EventEmitter<void> = new EventEmitter<void>();

  public ICON_POSITION = ICON_POSITION;
  public BUTTON_DESIGN_CLASS = BUTTON_DESIGN_CLASS;
  public SIZES = SIZES;
  public COLOR_TEMPLATE = COLOR_TEMPLATE;

  public onDownload(): void {
    this.download.emit();
  }
}
