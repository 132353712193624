import { Project } from '@app/core/models/project.model';
import { createAction, props } from '@ngrx/store';

export const getProject = createAction('[My Account] Get Project');
export const successGetProject = createAction(
  '[Project Effects] Success Get Project',
  props<{ project: Project }>()
);
export const failedGetProject = createAction(
  '[Project Effects] Failed Get Project'
);
