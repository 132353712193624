import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from './app.state';

export const getAppFeatureState = createFeatureSelector<AppState>('app');

export const selectMenu = createSelector(
  getAppFeatureState,
  (state: AppState) => state?.menu
);

export const selectLanguage = createSelector(
  getAppFeatureState,
  (state: AppState) => state?.language
);
