import { Filters } from '@app/core/models/filters.model';

export interface Payment {
  id: string;
  reference: string;
  loan_id: string;
  loan_reference: string;
  customer_reference: string;
  status: PAYMENT_STATUS;
  amount: string | number;
  type: string;
  paid_at: Date;
}

export interface PaymentFilters extends Filters {
  loan_id?: string;
  customer_id?: string;
  search?: string;
  amount__lte?: string;
  amount__gte?: string;
  status?: string;
  paid_at?: string;
}

export enum PAYMENT_STATUS {
  ACCEPTED = 'accepted',
  REVERSED = 'reversed',
  REJECTED = 'rejected',
}
