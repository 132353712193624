import SPANISH_MCA_CUSTOMERS from '@app/plugins/modules/customers/core/i18n/lang-mca/es';
import SPANISH_MCA_LOAN_MANAGER from '@app/plugins/modules/loan-manager/core/i18n/lang-mca/es';
import SPANISH_MCA_MERCHANT_MANAGER from '@app/plugins/modules/merchant-manager/core/i18n/lang-mca/es';
import SPANISH_MCA_PRODUCT_CATALOGUE from '@app/plugins/modules/product-catalogue/core/i18n/lang-mca/es';

const SPANISH_PLUGINS_MCA_TRANSLATE = {
  CUSTOMERS: { ...SPANISH_MCA_CUSTOMERS },
  LOAN_MANAGER: { ...SPANISH_MCA_LOAN_MANAGER },
  PRODUCT_CATALOGUE: { ...SPANISH_MCA_PRODUCT_CATALOGUE },
  MERCHANT_MANAGER: { ...SPANISH_MCA_MERCHANT_MANAGER },
};
export default SPANISH_PLUGINS_MCA_TRANSLATE;
