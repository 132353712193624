import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionComponent } from './components/accordion/accordion.component';
import { AppComponent } from './components/app/app.component';
import { BaseContentComponent } from './components/base-content/base-content.component';
import { BaseLoggedComponent } from './components/base-logged/base-logged.component';
import { BasicSlidesComponent } from './components/basic-slides/basic-slides.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CardBaseComponent } from './components/card-base/card-base.component';
import { CardContainerComponent } from './components/card-container/card-container.component';
import { CardWithDetailsComponent } from './components/card-with-details/card-with-details.component';
import { CountryCurrencyComponent } from './components/country-currency/country-currency.component';
import { CountryComponent } from './components/country/country.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { FilterContentComponent } from './components/filter-content/filter-content.component';
import { IconModule } from './components/icon/icon.module';
import { MenuSidebarModule } from './components/menu-sidebar/menu-sidebar.module';
import { NavbarModule } from './components/navbar/navbar.module';
import { OrderCardComponent } from './components/order-card/order-card.component';
import { PageDescriptionComponent } from './components/page-description/page-description.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { ProfileInfoCardComponent } from './components/profile-info-card/profile-info-card.component';
import { ScoreItemComponent } from './components/score-item/score-item.component';
import { SettingItemComponent } from './components/setting-item/setting-item.component';
import { SkeletonModule } from './components/skeleton/skeleton.module';
import { StatusComponent } from './components/status/status.component';
import { StepComponent } from './components/step/step.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { SummaryItemComponent } from './components/summary-item/summary-item.component';
import { SummaryItemsCategoryComponent } from './components/summary-items-category/summary-items-category.component';
import { SummaryItemsLoaderComponent } from './components/summary-items-loader/summary-items-loader.component';
import { SummarySubItemComponent } from './components/summary-sub-item/summary-sub-item.component';
import { SummaryComponent } from './components/summary/summary.component';
import { TabsModule } from './components/tabs/tabs.module';
import { MoFormsModule } from './mo-forms/mo-forms.module';
import { MoTablesModule } from './mo-tables/mo-tables.module';
import { CustomCurrencyPipe } from './pipes/currency.pipe';
import { MODatePipe } from './pipes/date.pipe';
import { MONumberPipe } from './pipes/number.pipe';
import { SnakeToCapitalPipe } from './pipes/snake-to-capital.pipe';

const SHARED_COMPONENTS = [
  PageDescriptionComponent,
  PageHeaderComponent,
  DialogComponent,
  CardContainerComponent,
  BreadcrumbComponent,
  FilterContentComponent,
  BasicSlidesComponent,
  SettingItemComponent,
  AccordionComponent,
  OrderCardComponent,
  SummaryComponent,
  SummaryItemComponent,
  CountryCurrencyComponent,
  SummarySubItemComponent,
  StepComponent,
  StepperComponent,
  SummaryItemsCategoryComponent,
  SummaryItemsLoaderComponent,
  ProfileInfoCardComponent,
  StatusComponent,
  CountryComponent,
  ScoreItemComponent,
  CardWithDetailsComponent,
  CardBaseComponent,
];

const PIPES = [
  MODatePipe,
  CustomCurrencyPipe,
  MONumberPipe,
  SnakeToCapitalPipe,
];

@NgModule({
  declarations: [
    AppComponent,
    BaseLoggedComponent,
    BaseContentComponent,
    ...SHARED_COMPONENTS,
    ...PIPES,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatSnackBarModule,
    MatDialogModule,
    MenuSidebarModule,
    NavbarModule,
    IconModule,
    SkeletonModule,
    TranslateModule,
    MoFormsModule,
  ],
  exports: [
    ...SHARED_COMPONENTS,
    ...PIPES,
    TabsModule,
    MoTablesModule,
    SkeletonModule,
    MoFormsModule,
    TranslateModule,
    IconModule,
  ],
  providers: [...PIPES],
})
export class LayoutModule {}
