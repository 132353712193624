import { PRODUCT_CATALOGUE_FEATURE } from './feature.constants';

export const PRODUCT_CATALOGUE_URLS = {
  ROOT_PATH: `/${PRODUCT_CATALOGUE_FEATURE.PATH}`,
  PRODUCTS_AND_OFFERS: {
    PRODUCT: {
      CREATE_NEW_PRODUCT: `/${PRODUCT_CATALOGUE_FEATURE.PATH}/${PRODUCT_CATALOGUE_FEATURE.PAGES.PRODUCTS_AND_OFFERS.PATH}/${PRODUCT_CATALOGUE_FEATURE.PAGES.PRODUCTS_AND_OFFERS.SUB_PAGES.CREATE_NEW_PRODUCT.PATH}`,
      CREATE_NEW_PRODUCT_FORM: `/${PRODUCT_CATALOGUE_FEATURE.PATH}/${PRODUCT_CATALOGUE_FEATURE.PAGES.PRODUCTS_AND_OFFERS.PATH}/${PRODUCT_CATALOGUE_FEATURE.PAGES.PRODUCTS_AND_OFFERS.SUB_PAGES.CREATE_NEW_PRODUCT.PATH}/${PRODUCT_CATALOGUE_FEATURE.PAGES.PRODUCTS_AND_OFFERS.SUB_PAGES.CREATE_NEW_PRODUCT.SUB_PAGES.FORM.PATH}`,
      CREATE_NEW_PRODUCT_SUMMARY: `/${PRODUCT_CATALOGUE_FEATURE.PATH}/${PRODUCT_CATALOGUE_FEATURE.PAGES.PRODUCTS_AND_OFFERS.PATH}/${PRODUCT_CATALOGUE_FEATURE.PAGES.PRODUCTS_AND_OFFERS.SUB_PAGES.CREATE_NEW_PRODUCT.PATH}/${PRODUCT_CATALOGUE_FEATURE.PAGES.PRODUCTS_AND_OFFERS.SUB_PAGES.CREATE_NEW_PRODUCT.SUB_PAGES.SUMMARY.PATH}`,
      PRODUCT_PROFILE: `/${PRODUCT_CATALOGUE_FEATURE.PATH}/${PRODUCT_CATALOGUE_FEATURE.PAGES.PRODUCTS_AND_OFFERS.PATH}/${PRODUCT_CATALOGUE_FEATURE.PAGES.PRODUCTS_AND_OFFERS.SUB_PAGES.PRODUCT_PROFILE.PATH}`,
    },
    OFFER: {
      CREATE_NEW_OFFER: `/${PRODUCT_CATALOGUE_FEATURE.PATH}/${PRODUCT_CATALOGUE_FEATURE.PAGES.PRODUCTS_AND_OFFERS.PATH}/${PRODUCT_CATALOGUE_FEATURE.PAGES.PRODUCTS_AND_OFFERS.SUB_PAGES.CREATE_NEW_OFFER.PATH}`,
      CREATE_NEW_OFFER_SPECS: `/${PRODUCT_CATALOGUE_FEATURE.PATH}/${PRODUCT_CATALOGUE_FEATURE.PAGES.PRODUCTS_AND_OFFERS.PATH}/${PRODUCT_CATALOGUE_FEATURE.PAGES.PRODUCTS_AND_OFFERS.SUB_PAGES.CREATE_NEW_OFFER.PATH}/${PRODUCT_CATALOGUE_FEATURE.PAGES.PRODUCTS_AND_OFFERS.SUB_PAGES.CREATE_NEW_OFFER.SUB_PAGES.OFFER_SPECS.PATH}`,
      CREATE_NEW_OFFER_SUMMARY: `/${PRODUCT_CATALOGUE_FEATURE.PATH}/${PRODUCT_CATALOGUE_FEATURE.PAGES.PRODUCTS_AND_OFFERS.PATH}/${PRODUCT_CATALOGUE_FEATURE.PAGES.PRODUCTS_AND_OFFERS.SUB_PAGES.CREATE_NEW_OFFER.PATH}/${PRODUCT_CATALOGUE_FEATURE.PAGES.PRODUCTS_AND_OFFERS.SUB_PAGES.CREATE_NEW_OFFER.SUB_PAGES.OFFER_SUMMARY.PATH}`,
      CREATE_NEW_OFFER_SUCCESS: `/${PRODUCT_CATALOGUE_FEATURE.PATH}/${PRODUCT_CATALOGUE_FEATURE.PAGES.PRODUCTS_AND_OFFERS.PATH}/${PRODUCT_CATALOGUE_FEATURE.PAGES.PRODUCTS_AND_OFFERS.SUB_PAGES.CREATE_NEW_OFFER.PATH}/${PRODUCT_CATALOGUE_FEATURE.PAGES.PRODUCTS_AND_OFFERS.SUB_PAGES.CREATE_NEW_OFFER.SUB_PAGES.SUCCESS.PATH}`,
      OFFER_PROFILE: `/${PRODUCT_CATALOGUE_FEATURE.PATH}/${PRODUCT_CATALOGUE_FEATURE.PAGES.PRODUCTS_AND_OFFERS.PATH}/${PRODUCT_CATALOGUE_FEATURE.PAGES.PRODUCTS_AND_OFFERS.SUB_PAGES.OFFER_PROFILE.PATH}`,
    },
  },
};
