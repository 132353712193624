import { OverviewPageTranslate } from '../../models/overview-translate';

const OVERVIEW_TRANSLATE: OverviewPageTranslate = {
  OVERVIEW: {
    TITLE: 'Visión general',
    DESCRIPTION:
      'Administre y supervise su base de clientes y pre-clientes, mientras visualiza su comportamiento crediticio y de pago.',
  },
};
export default OVERVIEW_TRANSLATE;
