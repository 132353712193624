import { Component } from '@angular/core';
import { inOutAnimation } from '@app/core/ animations/ animations';

@Component({
  selector: 'table-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
  animations: inOutAnimation,
})
export class EmptyComponent {}
