import { LoanRequestPageTranslate } from '../../models/loan-request-translate';

const LOAN_REQUEST_TRANSLATE: LoanRequestPageTranslate = {
  LOAN_REQUEST: {
    TITLE: 'Solicitudes de crédito',
    DESCRIPTION:
      'Revisa todas tus solicitudes de crédito. Filtre y descargue la base de datos de solicitudes para navegar y analizar rápidamente sus solicitudes de crédito.',
    FILTERS: {
      TITLE: 'Herramientas de búsqueda',
      DESCRIPTION:
        'Filtre su base de datos de solicitudes utilizando filtros predeterminados y avanzados o utilice la búsqueda de texto libre para campos específicos.',
      SEARCH_BAR: 'ID de cliente, ID de solicitud o nombre del cliente',
      AMOUNT_TITLE: 'Monto de crédito',
      AMOUNT_LESS: 'Desde',
      AMOUNT_GRATHER: 'hasta',
      STATUS: 'Estado de la solicitud',
      TAKEN_AT: 'Tomado en',
    },
    TABLE: {
      REFERENCE: 'ID de solicitud',
      CUSTOMER_REFERENCE: 'ID del cliente',
      MERCHANT_REFERENCE: 'ID del comercio',
      DISPLAY_NAME: 'Nombre del cliente',
      STATUS: 'Estado de la solicitud',
      AMOUNT: 'Monto',
      TAKEN_AT: 'Tomado en',
      PRODUCT_NAME: 'Producto',
      OFFER_NAME: 'Oferta',
    },
  },
};
export default LOAN_REQUEST_TRANSLATE;
