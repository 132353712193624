<div
  class="container-sidebar"
  [ngClass]="{ 'base-sidebar-collapsed': isCollapsed }"
>
  <aside class="sidebar" [class.closed]="isCollapsed">
    <div class="float-button" (click)="onToggle()">
      <img
        [src]="
          'app/presentation/assets/img/icons/menu/' +
          (isCollapsed ? 'arrow-right.svg' : 'arrow-left.svg')
        "
      />
    </div>
    <div class="container-product-logo">
      <img [@productLogoAnimation] [src]="menu.logo" *ngIf="isCollapsed" />
      <img
        [@productLogoAnimation]
        [src]="menu.logoCollapsed"
        *ngIf="!isCollapsed"
      />
    </div>
    <div class="sidebar__items">
      <ng-container *ngFor="let option of menu.items">
        <menu-primary-item
          *ngIf="option.type === MENU_ITEM_TYPE.ITEM"
          [active]="isOptionSelected(option.path)"
          [icon]="option.icon"
          [label]="option.label | translate"
          [product]="menu.product"
          (click)="goTo(option.path)"
        ></menu-primary-item>

        <menu-title
          *ngIf="option.type === MENU_ITEM_TYPE.TITLE"
          [label]="(isCollapsed ? option.shortLabel : option.label) | translate"
        ></menu-title>

        <menu-accordion-item
          *ngIf="option.type === MENU_ITEM_TYPE.ITEM_ACCORDION"
          [active]="isSubMenuOptionSelected(option)"
          [icon]="option.icon"
          [label]="option.label | translate"
          [collapsed]="!isCollapsed"
          [product]="menu.product"
          (toggleAccordion)="onToggleSubMenu($event, option.path)"
        >
          <sub-menu-list *ngIf="option.item as content">
            <ng-container *ngIf="content.type === MENU_ITEM_TYPE.SUB_MENU">
              <ng-container *ngFor="let subMenuOption of content.items">
                <sub-menu-item
                  *ngIf="subMenuOption.type === MENU_ITEM_TYPE.SUB_MENU_ITEM"
                  [label]="subMenuOption.label | translate"
                  [active]="isOptionSelected(subMenuOption.path)"
                  [isSubMenu]="subMenuOption?.isSubMenu"
                  [product]="menu.product"
                  (click)="goTo(subMenuOption.path)"
                ></sub-menu-item>

                <sub-menu-title
                  *ngIf="subMenuOption.type === MENU_ITEM_TYPE.SUB_MENU_TITLE"
                  [label]="
                    (isCollapsed
                      ? subMenuOption?.shortLabel
                      : subMenuOption?.label
                    ) | translate
                  "
                ></sub-menu-title>

                <sub-menu-item
                  *ngIf="
                    subMenuOption.type === MENU_ITEM_TYPE.SUB_MENU_ITEM_ICON
                  "
                  [isSubMenu]="subMenuOption?.isSubMenu"
                  [active]="isOptionSelected(subMenuOption.path)"
                  (click)="goTo(subMenuOption.path)"
                >
                  <ng-container slot="item">
                    <i class="uil" [ngClass]="subMenuOption.icon"></i>
                    {{ subMenuOption.label | translate }}
                  </ng-container>
                </sub-menu-item>
              </ng-container>
            </ng-container>
          </sub-menu-list>
        </menu-accordion-item>
      </ng-container>
      <div class="sidebar__footer">
        <img
          [@productLogoAnimation]
          src="app/presentation/assets/img/icons/logo/mo-logo.svg"
          alt="Powered by Mo"
          class="icon"
          *ngIf="isCollapsed"
        />
        <img
          [@productLogoAnimation]
          src="app/presentation/assets/img/icons/logo/power-by-mo.svg"
          alt="Powered by Mo"
          class="icon"
          *ngIf="!isCollapsed"
        />
      </div>
    </div>
  </aside>
  <article class="content-page">
    <ng-content></ng-content>
  </article>
</div>
