export enum CARD_CONTAINER_DESIGN_CLASS {
  DEFAULT = 'template-default',
  ALTERNATIVE = 'template-alternative',
  ALTERNATIVE_NO_PADDING = 'template-alternative__no-padding',
}

export enum SETTINGS_ITEM_DESIGN_CLASS {
  DEFAULT = 'template-default',
  PRIMARY = 'template-primary',
  ALTERNATIVE = 'template-alternative',
  ALTERNATIVE_DARK = 'template-alternative-dark',
  DISABLED = 'template-disabled',
}

export enum SUMMARY_DESIGN_CLASS {
  DEFAULT = 'template-default',
  PRIMARY = 'template-primary',
}
