import { CUSTOMER_FEATURE, LOAN_MANAGER_FEATURE } from './feature.constants';

export const CUSTOMERS_URLS = {
  OVERVIEW: `/${CUSTOMER_FEATURE.PATH}/${CUSTOMER_FEATURE.PAGES.OVERVIEW.PATH}`,
  PRE_CUSTOMERS: `/${CUSTOMER_FEATURE.PATH}/${CUSTOMER_FEATURE.PAGES.PRE_CUSTOMERS.PATH}`,
  PRE_CUSTOMERS_PROFILE: `/${CUSTOMER_FEATURE.PATH}/${CUSTOMER_FEATURE.PAGES.PRE_CUSTOMERS.PATH}/${CUSTOMER_FEATURE.PAGES.PRE_CUSTOMERS.SUB_PAGES.PRE_CUSTOMERS_PROFILE.PATH}`,
  CUSTOMERS: `/${CUSTOMER_FEATURE.PATH}/${CUSTOMER_FEATURE.PAGES.CUSTOMERS.PATH}`,
  CUSTOMERS_PROFILE: `/${CUSTOMER_FEATURE.PATH}/${CUSTOMER_FEATURE.PAGES.CUSTOMERS.PATH}/${CUSTOMER_FEATURE.PAGES.CUSTOMERS.SUB_PAGES.CUSTOMERS_PROFILE.PATH}`,
};

export const LOAN_MANAGER_URLS = {
  LOANS: `/${LOAN_MANAGER_FEATURE.PATH}/${LOAN_MANAGER_FEATURE.PAGES.LOANS.PATH}`,
  LOAN_PROFILE: `/${LOAN_MANAGER_FEATURE.PATH}/${LOAN_MANAGER_FEATURE.PAGES.LOANS.PATH}/${LOAN_MANAGER_FEATURE.PAGES.LOANS.SUB_PAGES.LOAN_PROFILE.PATH}`,
  PAYMENTS: `/${LOAN_MANAGER_FEATURE.PATH}/${LOAN_MANAGER_FEATURE.PAGES.PAYMENTS.PATH}`,
};
