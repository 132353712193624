import { Injectable } from '@angular/core';
import { ProjectApi } from '@app/data/api/project/project.api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import * as appActions from '../app/app.actions';
import * as appSelectors from '../app/app.selectors';
import * as projectActions from './project.actions';

@Injectable()
export class ProjectEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _projectApi: ProjectApi
  ) {}

  getProject$ = createEffect(() =>
    this._actions$.pipe(
      ofType(projectActions.getProject),
      switchMap(() =>
        this._projectApi.getProject().pipe(
          map((project) => projectActions.successGetProject({ project })),
          catchError((_) => of(projectActions.failedGetProject()))
        )
      )
    )
  );

  successGetProject$ = createEffect(() =>
    this._actions$.pipe(
      ofType(projectActions.successGetProject),
      withLatestFrom(this._store.select(appSelectors.selectLanguage)),
      map(([_, language]) => appActions.updateLanguage({ language }))
    )
  );
}
