<div class="paginator">
  <article class="pager">
    <button class="pager__item" (click)="onPrev()">
      <i class="uil uil-angle-left-b"></i>
    </button>
    <button
      class="pager__item"
      *ngFor="let page of pages"
      [ngClass]="{ selected: page.active }"
      (click)="onSelectPage(page.number)"
    >
      {{ page.text }}
    </button>
    <button class="pager__item" (click)="onNext()">
      <i class="uil uil-angle-right-b"></i>
    </button>
  </article>
</div>
