import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { AppFacade } from '@app/facade/app/app.facade';
import { AuthFacade } from '@app/facade/auth/auth.facade';
import { environment } from 'src/environments/environment';
import { APP_CONSTANTS } from '../constants/app.constants';
import { COUNTRY_CONSTANTS } from '../constants/country.constants';
import { CONTENT_TYPE, HTTP_HEADERS } from '../constants/http.constants';
import { MESSAGES } from '../constants/messages.constants';

const API_URL = environment.api_url;

@Injectable()
export class AppInterceptorService implements HttpInterceptor {
  private _isShownModal: boolean;

  constructor(private _appFacade: AppFacade, private _authFacade: AuthFacade) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this._sendRequest(req, next);
  }

  private _sendRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isRequestAmazon = req.url.indexOf('s3.amazon') > -1;
    const isRequestGCP = req.url.indexOf('project.cloudfunctions') > -1;

    if (isRequestAmazon || isRequestGCP) {
      return this._continueRequest(req, next);
    }

    const headers: any = this._mapHeaders(req.headers);
    let params: HttpParams = new HttpParams();

    req.params.keys().forEach((key) => {
      const param = req.params.get(key);
      if (param && param !== 'null' && param !== 'undefined')
        params = params.append(key, param);
    });

    return this._authFacade.getSessionToken().pipe(
      mergeMap((token) => {
        if (token && token.access_token) {
          headers.Authorization = `Bearer ${token.access_token}`;
        }
        const reqUpdated = req.clone({
          url: API_URL + req.url,
          setHeaders: headers,
          params,
        });
        return this._continueRequest(reqUpdated, next);
      })
    );
  }

  private _continueRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(req)
      .pipe(catchError((err: any) => this._catchError(req, err)));
  }

  private _catchError(req: HttpRequest<any>, error: any) {
    const isUnathorized =
      error.status === APP_CONSTANTS.HTTP_CODES.UNAUTHORIZED ||
      error.status === APP_CONSTANTS.HTTP_CODES.FORBIDDEN;
    const isLogged = req.headers.get(HTTP_HEADERS.AUTHORIZATION)?.length > 0;

    if (isUnathorized && isLogged) {
      this._authFacade.logout();
      // this._showDialogSessionExpired();// TODO IMPLEMENT WHEN UX DEFINES LOGOUT FLOW
      return throwError(() => error);
    }

    if (error.status === APP_CONSTANTS.HTTP_CODES.NOT_FOUND) {
      if (!req.body || (req.body && !req.body.preventNotFoundError)) {
        this._showError(req, MESSAGES.ERRORS.NOT_FOUND_RESOURCE);
      }
      return throwError(() => error);
    }
    return throwError(() => error);
  }

  private _mapHeaders(reqHeaders) {
    let headers: any = {
      [HTTP_HEADERS.CONTENT_TYPE]: CONTENT_TYPE.JSON,
      [HTTP_HEADERS.ACCEPT_LANGUAGE]: COUNTRY_CONSTANTS.US.language,
    };
    reqHeaders.keys().forEach((key) => {
      const headerValue = reqHeaders.get(key);
      if (headerValue && headerValue !== 'null' && headerValue !== 'undefined')
        headers[key] = headerValue;
    });
    return headers;
  }

  private _showDialogSessionExpired() {
    if (this._isShownModal) return;

    const callback = () => {
      this._authFacade.logout();
      this._isShownModal = false;
    };

    this._isShownModal = true;
    this._appFacade.showGlobalMessage(
      MESSAGES.ERRORS.SESSION_FINISHED,
      callback,
      'Session expired'
    );
  }

  private _showError(req: any, msg = null, error = null) {
    if (!req.body || !req.body.showError) {
      return;
    }
    if (msg && msg.indexOf && msg.indexOf('This field is required') >= 0) {
      msg = MESSAGES.ERRORS.FIELD_REQUIRED;
    }
    if (!msg) {
      msg = MESSAGES.ERRORS.DEFAULT;
    }
    this._appFacade.showGlobalError(msg);
  }
}
