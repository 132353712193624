import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FILTERS } from '@app/core/constants/filters.constant';
import { Filters } from '@app/core/models/filters.model';
import { TYPE_COLUMN } from '@app/presentation/layout/mo-tables/enums/table.enum';
import { CONTAINER_SIZE } from '../../constants/container.constants';
import {
  PAGE_DEFAULT,
  PAGE_SIZE_DEFAULT,
} from '../../constants/page-size.constant';
import { TABLE_DESIGN_CLASS } from '../../enums/table.enum';
import { TableHeader, TableResponse } from '../../interfaces/table.interface';

@Component({
  selector: 'mo-table-with-controls',
  templateUrl: './table-with-controls.component.html',
  styleUrls: ['./table-with-controls.component.scss'],
})
export class TableWithControlsComponent<T> implements OnChanges {
  @Input() showOptions: boolean = false;
  @Input() designClass: TABLE_DESIGN_CLASS = TABLE_DESIGN_CLASS.DEFAULT;
  @Input() response: TableResponse<T>;
  @Input() headers: TableHeader[];
  @Input() firstColumnFixed: boolean = false;
  @Input() containerSize: string = CONTAINER_SIZE.DEFAULT;
  @Input() pageSize: number = PAGE_SIZE_DEFAULT;
  @Input() fixedMinWidth: boolean = true;
  @Input() urlRow: string;
  @Input() urlRowIdentifier: string = TYPE_COLUMN.ID;
  @Input() isLoading: boolean;
  @Input() loadingRows: number = 5;
  @Input() loadingColumns: number = 7;
  @Output() changePage: EventEmitter<number> = new EventEmitter<number>();
  @Output() changePageSize: EventEmitter<number> = new EventEmitter<number>();
  @Output() changeTableFilters: EventEmitter<Filters> =
    new EventEmitter<Filters>();
  @Output() download: EventEmitter<void> = new EventEmitter<void>();

  public currentPage: number = PAGE_DEFAULT;
  private _resetControls: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    this._resetControls = Boolean(
      changes['response'] &&
        changes['response']?.currentValue?.links?.previous === null
    );
    if (this._resetControls) this.currentPage = FILTERS.offset;
  }

  public onChangePage(currentPage: number) {
    this.currentPage = currentPage;
    this.changePage.emit(currentPage);
    this._calculatePageFilter();
  }

  public onChangePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.currentPage = PAGE_DEFAULT;
    this.changePageSize.emit(pageSize);
    this._calculatePageFilter();
  }

  public onSort(sortFilter: Filters) {
    this.changeTableFilters.emit(sortFilter);
  }

  public onDownload(): void {
    this.download.emit();
  }

  private _calculatePageFilter() {
    const offset: number = (this.currentPage - 1) * this.pageSize;
    const pageFilter: Filters = { offset, limit: this.pageSize };
    this.changeTableFilters.emit(pageFilter);
  }
}
