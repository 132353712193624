import { FeatureDetailsTranslate } from '@app/core/i18n/models/feature-details-translate';
import MERCHANT_GROUPS_TRANSLATE from './merchant-groups.translate';
import MERCHANT_TRANSLATE from './merchants.translate';
import OVERVIEW_TRANSLATE from './overview.translate';
import PURCHASES_TRANSLATE from './purchases.translate';

const ENGLISH_DEFAULT_MERCHANT_MANAGER: FeatureDetailsTranslate = {
  _NAME: 'Merchant Manager',
  _MENU: {
    OVERVIEW: OVERVIEW_TRANSLATE.OVERVIEW.TITLE,
    MERCHANTS: MERCHANT_TRANSLATE.MERCHANTS.TITLE,
    PURCHASES: PURCHASES_TRANSLATE.PURCHASES.TITLE,
    MERCHANT_GROUPS: MERCHANT_GROUPS_TRANSLATE.MERCHANT_GROUPS.TITLE,
  },
  ...OVERVIEW_TRANSLATE,
  ...MERCHANT_TRANSLATE,
  ...PURCHASES_TRANSLATE,
  ...MERCHANT_GROUPS_TRANSLATE,
};
export default ENGLISH_DEFAULT_MERCHANT_MANAGER;
