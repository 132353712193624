import { MENU_CONFIG_DISABLED } from '@instance-config/menu.config';
import { PRODUCT_CATALOGUE_FEATURE } from './feature.constants';

export const PRODUCT_CATALOGUE_ROUTES = [
  {
    path: PRODUCT_CATALOGUE_FEATURE.PATH,
    loadChildren: () =>
      import(
        '@app/plugins/modules/product-catalogue/presentation/product-catalog.module'
      ).then((m) => m.ProductCatalogueModule),
    data: {
      breadcrumb: 'PRODUCT_CATALOGUE._NAME',
      disabled: MENU_CONFIG_DISABLED.PRODUCT_CATALOGUE.MODULE ?? false
    },
  },
];
