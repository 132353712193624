import { FiltersToolsTranslate } from '@app/core/i18n/models/filters-translate';

const FILTERS_TRANSLATE: FiltersToolsTranslate = {
  RESULTS: 'Resultados',
  BTN_SEARCH: 'Buscar',
  BTN_CLEAN_FILTERS: 'Borrar filtros',
  ADVANCED_FILTERS: 'Filtros avanzados',
  RECORDS_PER_PAGE: 'Registros por página',
};
export default FILTERS_TRANSLATE;
