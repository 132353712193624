<div class="form-control-radio">
  <input
    type="radio"
    [value]="value"
    [(ngModel)]="valueModel"
    [attr.id]="'radio' + id"
    [name]="name"
    [disabled]="isDisabled"
    (change)="onChange()"
  />
  <label [for]="'radio' + id">{{ label }}</label>
  <ng-content></ng-content>
</div>
