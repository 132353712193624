import { Component, EventEmitter, Output } from '@angular/core';
import {
  BUTTON_DESIGN_CLASS,
  BUTTON_TYPE,
  SIZES,
} from '@app/presentation/layout/mo-forms/enums/fields.type';

@Component({
  selector: 'layout-user-card-menu',
  templateUrl: './user-card-menu.component.html',
  styleUrls: ['./user-card-menu.component.scss'],
})
export class UserCardMenuComponent {
  @Output() logout: EventEmitter<boolean>;
  public BUTTON_TYPE = BUTTON_TYPE;
  public BUTTON_DESIGN_CLASS = BUTTON_DESIGN_CLASS;
  public SIZES = SIZES;

  constructor() {
    this.logout = new EventEmitter<boolean>(false);
  }

  onLogout() {
    this.logout.emit(true);
  }
}
