import { RoutesMock } from '@app/core/models/routes-mock.model';
import {
  LOAN,
  LOANS,
  LOAN_CHARGES,
  LOAN_REQUEST,
  LOAN_SCORES,
  LOAN_TOTALS,
} from '../mocks/loans.mock';
import { PAYMENTS } from '../mocks/payments.mock';
import { API_URLS } from './api.constants';

const ID_REGEX = '/[0-9a-z-]+';

export const LOANS_ROUTES_MOCK: RoutesMock[] = [
  // ********************
  // LOAN-MANAGER
  // ********************
  // LOANS
  {
    url: API_URLS.GET_LOANS,
    data: LOANS,
    method: 'GET',
  },
  {
    url: API_URLS.GET_LOANS_REQUEST,
    data: LOAN_REQUEST,
    method: 'GET',
  },
  {
    url: `${API_URLS.GET_LOANS}${ID_REGEX}`,
    data: LOAN,
    method: 'GET',
  },
  {
    url: API_URLS.GET_LOAN_CHARGES.replace('/:id', ID_REGEX),
    data: LOAN_CHARGES,
    method: 'GET',
  },
  {
    url: API_URLS.GET_LOAN_TOTALS.replace('/:id', ID_REGEX),
    data: LOAN_TOTALS,
    method: 'GET',
  },
  {
    url: API_URLS.GET_LOAN_SCORES.replace('/:id', ID_REGEX),
    data: LOAN_SCORES,
    method: 'GET',
  },
  // PAYMENTS
  {
    url: API_URLS.GET_PAYMENTS,
    data: PAYMENTS,
    method: 'GET',
  },
];
