import { CUSTOMER_CATEGORY } from '@app/core/models/score.model';
import { CUSTOMER_STATUS } from '@app/plugins/modules/customers/core/constants/status.constants';
import {
  Customer,
  CustomerProfile,
} from '@app/plugins/modules/customers/core/models/customers.model';
import { ProductAndScore } from '@app/plugins/modules/customers/core/models/product-and-score.model';
import { TableResponse } from '@app/presentation/layout/mo-tables/interfaces/table.interface';

export const ALL_CUSTOMERS: TableResponse<Customer> = {
  filtered: 50,
  count: 10,
  results: [
    {
      id: 'bcbea3c1-939e-427b-a235-7d9cccd1a5f7',
      reference: '123456789',
      display_name: 'Andres Orozco',
      active_loans: 0,
      email: 'diego.orozco@wearemo.com',
      phone_number: '32032301233',
      identification_number: '1234567890',
      created_at: new Date('2019-10-01T15:59:15-05:00'),
      updated_at: new Date('2019-10-01T15:59:15-05:00'),
    },
    {
      id: 'bcbea3c1-939e-427b-a235-7d9cccd1a5f7',
      reference: '123456789',
      display_name: 'Faber Herrera',
      active_loans: null,
      email: 'faber.herrera@wearemo.com',
      phone_number: '32032301233',
      identification_number: '1234567890',
      created_at: new Date('2019-10-01T15:59:15-05:00'),
      updated_at: new Date('2019-10-01T15:59:15-05:00'),
    },
    {
      id: 'bcbea3c1-939e-427b-a235-7d9cccd1a5f7',
      reference: '123456789',
      display_name: 'Henry Bravo',
      active_loans: 3,
      email: 'henry.bravo@wearemo.com',
      phone_number: '32032301233',
      identification_number: '1234567890',
      created_at: new Date('2019-10-01T15:59:15-05:00'),
      updated_at: new Date('2019-10-01T15:59:15-05:00'),
    },
    {
      id: 'bcbea3c1-939e-427b-a235-7d9cccd1a5f7',
      reference: '123456789',
      display_name: 'Sebastian Diaz',
      active_loans: 3,
      email: 'sebastian.dias@wearemo.com',
      phone_number: '32032301233',
      identification_number: '1234567890',
      created_at: new Date('2019-10-01T15:59:15-05:00'),
      updated_at: new Date('2019-10-01T15:59:15-05:00'),
    },
    {
      id: 'bcbea3c1-939e-427b-a235-7d9cccd1a5f7',
      reference: '123456789',
      display_name: 'Jose Garcia',
      active_loans: 3,
      email: 'jose.garcia@wearemo.com',
      phone_number: '32032301233',
      identification_number: '1234567890',
      created_at: new Date('2019-10-01T15:59:15-05:00'),
      updated_at: new Date('2019-10-01T15:59:15-05:00'),
    },
    {
      id: 'bcbea3c1-939e-427b-a235-7d9cccd1a5f7',
      reference: '123456789',
      display_name: 'Juli',
      active_loans: 3,
      email: 'movers@wearemo.com',
      phone_number: '32032301233',
      identification_number: '1234567890',
      created_at: new Date('2019-10-01T15:59:15-05:00'),
      updated_at: new Date('2019-10-01T15:59:15-05:00'),
    },
    {
      id: 'bcbea3c1-939e-427b-a235-7d9cccd1a5f7',
      reference: '123456789',
      display_name: 'Diego',
      active_loans: 3,
      email: 'movers@wearemo.com',
      phone_number: '32032301233',
      identification_number: '1234567890',
      created_at: new Date('2019-10-01T15:59:15-05:00'),
      updated_at: new Date('2019-10-01T15:59:15-05:00'),
    },
  ],
};

export const CUSTOMER_PROFILE: CustomerProfile = {
  identification_type: 'CC',
  id: '75682ce9-c244-4c88',
  reference: '123456789',
  status: CUSTOMER_STATUS.ACTIVE,
  display_name: 'Santigo Mejia',
  updated_at: new Date('2022-01-24T15:59:15-05:00'),
  identification_number: '1234567890',
  email: 'santiago.mejia@gmail.com',
  created_at: new Date('2022-01-24T15:59:15-05:00'),
  phone_number: '3132210487',
  website: 'wearemo.com',
  others: {
    customer_tier: 'Premium',
    loyalty_program: 'Points',
    company_name: 'Mo Technologies',
    country: 'Colombia',
    address_of_principal_partner: 'Cra 1 # 2-33',
  },
};

export const CUSTOMERS_PRODUCTS_AND_SCORES: ProductAndScore[] = [
  {
    id: 'e9d7b2a7-c010-4c51-bf21-b6911bd4ec45',
    // loan_id: '7291c422-646d-45d0-ba88-cd4aaa0e05d9',
    // pre_loan_id: 'f36a6fca-f2fb-4fac-93dd-6b63824116e7',
    pre_approved_amount: 20000.0,
    pre_approved_at: new Date('2022-06-29 00:00:00'),
    score: 0.9,
    product_id: '6cfe450c-add3-41b3-b2ba-4e704dafcc30',
    product_name: 'Ejemplo_MCA',
    product_reference: '783e45b5-3ba3-4702-8129-34bc0a3b67c3',
    customer_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
    customer_category: CUSTOMER_CATEGORY.PRE_CUSTOMER,
    display_name: 'Bridget Bradtke',
    created_at: new Date('2022-06-29 20:33:56'),
    others: {
      estimated_monthly_sales: '50',
      maximum_discount_percentage: '500000',
    },
  },
  {
    id: 'e9d7b2a7-c010-4c51-bf21-b6911bd4ec45',
    // loan_id: '7291c422-646d-45d0-ba88-cd4aaa0e05d9',
    // pre_loan_id: 'f36a6fca-f2fb-4fac-93dd-6b63824116e7',
    pre_approved_amount: 20000.0,
    pre_approved_at: new Date('2022-06-29 00:00:00'),
    score: 0.9,
    product_id: '6cfe450c-add3-41b3-b2ba-4e704dafcc30',
    product_name: 'Ejemplo_MCA',
    product_reference: '783e45b5-3ba3-4702-8129-34bc0a3b67c3',
    customer_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
    customer_category: CUSTOMER_CATEGORY.CUSTOMER,
    display_name: 'Bridget Bradtke',
    created_at: new Date('2022-06-29 20:33:56'),
    others: {
      estimated_monthly_sales: '50',
      maximum_discount_percentage: '500000',
    },
  },
];
