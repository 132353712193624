import { COUNTRY_CONSTANTS } from '@app/core/constants/country.constants';
import { PRODUCT_FAMILY } from '@app/core/constants/product.constants';
import { Project, ROUND_WAY } from '@app/core/models/project.model';
export const PROJECT: Project = {
  id: 'IDASLDKJLASDJ',
  name: 'Demo MOManage BNPL',
  description:
    'This is a demo account. Any data contained is produced by MO experts',
  country: COUNTRY_CONSTANTS.CO.countryCode,
  decimal_places: 10,
  decimal_round_way: ROUND_WAY.ROUND_UP,
  includes_moscore: true,
  family_name: PRODUCT_FAMILY.BNPL,
  calendar_name: 'ORDINARY',
  maximum_number_active_loans: 100,
  file_s3_path: 'PP-Demo.docx',
  created_at: new Date('2019-10-01T15:59:15-05:00'),
  updated_at: new Date('2019-10-01T15:59:15-05:00'),
  currency_value: 'Peso Colombiano',
  currency_code: COUNTRY_CONSTANTS.CO.currencyCode,
  timezone_value: 'America/Denver',
};
