import {
  getSkeleton,
  Skeleton,
  SkeletonTheme,
} from '@app/core/models/skeleton.model';

const margin: string = '0 5px 5px 0';
const height: string = '100px';
const width: string = '100%';
const skeletonTheme: SkeletonTheme = { width, margin, height };

export const LIST_WITH_CONTROLS_DEFAULT_STYLES = {
  display: 'grid',
  gap: '2rem',
  ['grid-template-columns']: `repeat(auto-fill, 150px)`,
};

export const SKELETON_LIST_WITH_CONTROLS_DEFAULT: Skeleton[] = [
  ...getSkeleton(1, 7, skeletonTheme),
];
