import { MENU_CONFIG_DISABLED } from '@instance-config/menu.config';
import { CUSTOMER_FEATURE } from './feature.constants';

export const CUSTOMERS_ROUTES = [
  {
    path: CUSTOMER_FEATURE.PATH,
    loadChildren: () =>
      import('../../presentation/customers.module').then(
        (m) => m.CustomersModule
      ),
    data: {
      breadcrumb: 'CUSTOMERS._NAME',
      disabled: MENU_CONFIG_DISABLED.CUSTOMER.MODULE ?? false
    },
  },
];
