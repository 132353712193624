import { Action, createReducer, on } from '@ngrx/store';
import * as authActions from './auth.actions';
import { AuthState } from './auth.state';

export const initialAuthState: AuthState = {
  isAuthenticated: false,
};

const _authReducer = createReducer(
  initialAuthState,
  on(authActions.login, (state) => {
    return { ...state, isLoadingLogin: true };
  }),
  on(authActions.successLogin, (state) => {
    return { ...state, isAuthenticated: true, isLoadingLogin: false };
  }),
  on(authActions.failedLogin, (state) => {
    return { ...state, isAuthenticated: false, isLoadingLogin: false };
  }),
  on(authActions.updateIsAuthenticated, (state, { isAuthenticated }) => {
    return { ...state, isAuthenticated };
  }),
  on(authActions.logout, (state) => {
    return { ...state, isAuthenticated: false };
  })
);

export function AuthReducers(state: AuthState | undefined, action: Action) {
  return _authReducer(state, action);
}
