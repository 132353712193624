<div class="form-control-checkbox">
  <input
    data-testid="checkbox"
    type="checkbox"
    [attr.id]="'chk' + id"
    [checked]="isSelected"
    [disabled]="isDisabled"
    (change)="onChange($event)"
  />
  <label [for]="'chk' + id">{{ label }}</label>
  <ng-content></ng-content>
</div>
