import { Component, Input, OnInit } from '@angular/core';
import { CARD_CONTAINER_DESIGN_CLASS } from '../../enums/layout.enum';

@Component({
  selector: 'layout-card-container',
  templateUrl: './card-container.component.html',
  styleUrls: ['./card-container.component.scss'],
})
export class CardContainerComponent implements OnInit {
  @Input() designClass: CARD_CONTAINER_DESIGN_CLASS =
    CARD_CONTAINER_DESIGN_CLASS.DEFAULT;
  constructor() {}

  ngOnInit(): void {}
}
