import { FeatureDetailsTranslate } from '@app/core/i18n/models/feature-details-translate';
import PRODUCTS_AND_OFFERS_TRANSLATE from './products_and_offers.translate';

const ENGLISH_DEFAULT_PRODUCT_CATALOGUE: FeatureDetailsTranslate = {
  _NAME: 'Product Catalogue',
  _MENU: {
    PRODUCTS_AND_OFFERS:
      PRODUCTS_AND_OFFERS_TRANSLATE.PRODUCTS_AND_OFFERS.TITLE,
  },
  ...PRODUCTS_AND_OFFERS_TRANSLATE,
};
export default ENGLISH_DEFAULT_PRODUCT_CATALOGUE;
