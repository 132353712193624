export const MERCHANT_MANAGER_FEATURE = {
  NAME: 'Merchant Manager',
  PATH: 'merchant-manager',
  PAGES: {
    OVERVIEW: {
      NAME: 'Overview',
      PATH: 'overview',
    },
    MERCHANTS: {
      NAME: 'Merchants',
      PATH: 'merchants',
      STORE_NAME: 'merchants',
      SUB_PAGES: {
        MERCHANT_PROFILE: {
          NAME: 'Merchant profile',
          PATH: 'merchant-profile',
        },
      },
    },
    PURCHASES: {
      NAME: 'Purchases',
      PATH: 'purchases',
      STORE_NAME: 'purchases',
    },
    MERCHANT_GROUPS: {
      NAME: 'Merchant Groups',
      PATH: 'merchant-groups',
      STORE_NAME: 'merchant-groups',
    },
  },
};

export const LOAN_MANAGER_FEATURE = {
  NAME: 'Credit Manager',
  PATH: 'credit-manager',
  PAGES: {
    LOANS: {
      NAME: 'Credits',
      PATH: 'credits',
      STORE_NAME: 'loans',
      SUB_PAGES: {
        LOAN_PROFILE: {
          NAME: 'Credits profile',
          PATH: 'credit-profile',
        },
      },
    },
  },
};
