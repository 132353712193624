import { RoutesMock } from '@app/core/models/routes-mock.model';
import { generateQueryParamsFromObject } from '@app/core/models/utils.model';
import { MOCK_OFFERS_BY_PRODUCT_FILTER } from '@app/plugins/modules/product-catalogue/core/constants/filters.constant';
import { OFFERS, OFFERS_TABLE } from '../mocks/offers.mock';
import { PRODUCT, PRODUCTS } from '../mocks/products.mock';
import { API_URLS } from './api.constants';
const ID_REGEX = '/[0-9a-z-]+';

export const PRODUCTS_AND_OFFERS_ROUTES_MOCK: RoutesMock[] = [
  // ********************
  // PRODUCTS AND OFFERS
  // ********************
  // Products
  {
    url: API_URLS.PRODUCTS_AND_OFFERS.GET_PRODUCTS,
    data: PRODUCTS,
    method: 'GET',
  },
  // Product
  {
    url: API_URLS.PRODUCTS_AND_OFFERS.GET_PRODUCT.replace('/:id', ID_REGEX),
    data: PRODUCT,
    method: 'GET',
  },

  // Offers
  {
    url: `${
      API_URLS.PRODUCTS_AND_OFFERS.GET_OFFERS
    }${generateQueryParamsFromObject(MOCK_OFFERS_BY_PRODUCT_FILTER)}`,
    data: OFFERS_TABLE,
    method: 'GET',
    validateParam: true,
  },
  {
    url: API_URLS.PRODUCTS_AND_OFFERS.GET_OFFERS,
    data: OFFERS,
    method: 'GET',
  },
];
