<div class="card-with-details" *ngIf="!isLoading; else loading">
  <div class="collapsed">
    <div class="header">
      <ng-content select="[slot='header']"></ng-content>
    </div>

    <div class="divider"></div>
    <div class="body">
      <ng-content select="[slot='body']"></ng-content>
    </div>
  </div>
  <div *ngIf="hasDetail">
    <i class="uil uil-angle-down"></i>
    <ng-content select="[slot='collapsed-content']"></ng-content>
  </div>
</div>

<ng-template #loading>
  <div class="card-with-details">
    <div class="collapsed">
      <div class="header">
        <layout-skeleton
          *ngFor="let skeleton of loaderHeader"
          [skeleton]="skeleton"
        ></layout-skeleton>
      </div>
      <div class="divider"></div>
      <div class="body">
        <layout-skeleton
          *ngFor="let skeleton of loaderBody"
          [skeleton]="skeleton"
        ></layout-skeleton>
      </div>
    </div>
  </div>
</ng-template>
