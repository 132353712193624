import ENGLISH_OVERRIDE_DEFAULT_TRANSLATE from '@app/override/i18n/lang-default/en';
import APP_TRANSLATE from './app/index.translate';
import AUTH_TRANSLATE from './auth/login.translate';
import MY_ACCOUNT_TRANSLATE from './my-account';

export const BASE_ENGLISH_TRASNLATE = {
  APP: { ...APP_TRANSLATE },
  AUTH: { ...AUTH_TRANSLATE },
  MY_ACCOUNT: { ...MY_ACCOUNT_TRANSLATE },
};

const ENGLISH_DEFAULT = {
  ...BASE_ENGLISH_TRASNLATE,
  ...ENGLISH_OVERRIDE_DEFAULT_TRANSLATE,
};
export default ENGLISH_DEFAULT;
