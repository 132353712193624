import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@app/core/models/user.model';
import { API_URLS } from '@app/data/constants/api.constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserApi {
  constructor(private _http: HttpClient) {}

  public getUserInfo(): Observable<User> {
    return this._http.get<User>(API_URLS.USER.GET_INFO);
  }
}
