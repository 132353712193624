import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'snakeToCapital',
})
export class SnakeToCapitalPipe implements PipeTransform {
  transform(value: string): string {
    value = value?.replaceAll('_', ' ') ?? '';
    if (value.length <= 0) return value;
    return `${value[0].toUpperCase()}${value.substring(1).toLowerCase()}`;
  }
}
