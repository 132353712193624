<div
  [class]="'sub-item ' + designClass"
  [class.sub-item--dashed]="isDashed"
  *ngIf="value"
>
  <img class="sub-item__icon" [src]="image" alt="sub-item" />
  <p class="sub-item__title">{{ name }}</p>
  <div class="sub-item__dashed-divider" *ngIf="isDashed"></div>
  <p class="sub-item__value">{{ value }}</p>
</div>
