<div class="profile-info-card">
  <ng-content *ngIf="!isLoading"></ng-content>
  <div class="profile-info-card__loader" *ngIf="isLoading">
    <div class="loader">
      <layout-skeleton
        *ngFor="let skeleton of skeletons"
        [skeleton]="skeleton"
      ></layout-skeleton>
    </div>
    <div class="loader right">
      <layout-skeleton
        *ngFor="let skeleton of skeletons"
        [skeleton]="skeleton"
      ></layout-skeleton>
    </div>
  </div>
</div>
