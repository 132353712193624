export const API_URLS = {
  // ** LOANS REQUEST **
  GET_LOANS_REQUEST: '/pre-loans',

  // ** LOANS **
  GET_LOANS: '/loans',
  GET_LOAN_TOTALS: '/loans/:id/totals',
  GET_LOAN_CHARGES: '/loans/:id/charges',
  GET_LOAN_SCORES: '/loans/:id/scores',

  // ** PAYMENTS **
  GET_PAYMENTS: '/payments',
};
