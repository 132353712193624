import { AppEffects } from './app/app.effects';
import { AppReducers } from './app/app.reducers';
import { AppState } from './app/app.state';
import { AuthEffects } from './auth/auth.effects';
import { AuthReducers } from './auth/auth.reducers';
import { AuthState } from './auth/auth.state';
import { ProjectEffects } from './project/project.effects';
import { ProjectReducers } from './project/project.reducers';
import { ProjectState } from './project/project.state';
import { UserEffects } from './user/user.effects';
import { UserReducers } from './user/user.reducers';
import { UserState } from './user/user.state';

export interface RootState {
  app: AppState;
  user: UserState;
  project: ProjectState;
  auth: AuthState;
}
export const appReducers = {
  app: AppReducers,
  user: UserReducers,
  project: ProjectReducers,
  auth: AuthReducers,
};

export const appEffects = [
  AppEffects,
  AuthEffects,
  UserEffects,
  ProjectEffects,
];
