import { Component, Input, OnInit } from '@angular/core';
import { APP_CONSTANTS } from '@app/core/constants/app.constants';
import { COUNTRY_CONSTANTS } from '@app/core/constants/country.constants';
import { CountryConfig } from '@app/core/models/country.model';

@Component({
  selector: 'layout-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss'],
})
export class CountryComponent implements OnInit {
  @Input() countryCode: string;
  public countryConfig: CountryConfig;
  constructor() {}

  ngOnInit(): void {
    this.countryConfig =
      COUNTRY_CONSTANTS[this.countryCode || APP_CONSTANTS.DEFAULT_COUNTRY];
  }
}
