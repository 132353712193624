<div class="page-description">
  <div class="page-description__details">
    <h1 class="title" [innerHTML]="title"></h1>
    <p class="description" [innerHTML]="description"></p>
  </div>
  <div class="page-description__extra-info" *ngIf="total || total == 0">
    <div class="count">
      <p class="count__title"><b>Total</b></p>
      <h1 class="count__total">{{ total | MONumber }}</h1>
    </div>
  </div>
</div>
