import { Filters } from '@app/core/models/filters.model';

export enum PURCHASE_STATUS {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  DISPUTE = 'dispute',
}

export interface Purchase {
  id: string;
  reference: string;
  merchant_reference: string;
  ticket: number;
  down_payment: number;
  status: PURCHASE_STATUS;
  credit_reference: string;
  created_at: Date;
}

export interface PurchaseFilters extends Filters {
  search?: string;
  created_at?: string;
  created_at__lte?: string;
  created_at__gte?: string;
  ticket?: string;
  ticket_lte?: string;
  ticket_gte?: string;
  down_payment?: string;
  down_payment_lte?: string;
  down_payment_gte?: string;
}
