<div class="breadcrumb">
  <p
    *ngFor="let breadcrumb of breadcrumbs; last as isLast"
    class="paths"
    [ngClass]="{ last: isLast }"
  >
    <a *ngIf="breadcrumb.url && !isLast" [routerLink]="breadcrumb.url">
      {{ breadcrumb.label | translate }}
    </a>
    <span *ngIf="!breadcrumb.url || isLast">
      {{ breadcrumb.label | translate }}
    </span>
    <span class="divider" *ngIf="!isLast">/</span>
  </p>
</div>
