import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FILTERS } from '@app/core/constants/filters.constant';
import {
  PAGE_DEFAULT,
  PAGE_SIZE_DEFAULT,
} from '@app/presentation/layout/mo-tables/constants/page-size.constant';

@Component({
  selector: 'table-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnChanges {
  @Output() change: EventEmitter<number>;
  @Input() dataLength: number;
  @Input() totalPages: number;
  @Input() pageSize: number;
  @Input() currentPage: number;

  private _MAX_PAGES_SHOW: number;
  private _currentPage: number;
  private _totalPages: number;

  public showEllipsis: boolean;
  public pages: Array<any>;

  public cantFirstLastPage = 0;
  constructor() {
    this._setInitialValues();
  }

  // TODO: Refactor this class and methods for testing coverage.

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      (changes.totalPages ||
        changes.dataLength ||
        changes.pageSize ||
        changes.rowsPerPage ||
        changes.currentPage)
    ) {
      this._calculateTotalPages();
      this._setupPages();
    }
    if (changes && changes.currentPage) {
      this._selectPage(+this.currentPage);
    }
  }

  onSelectPage(page: number) {
    this._selectPage(page);
  }

  onNext() {
    this._selectPage(this._currentPage + 1);
  }

  onPrev() {
    this._selectPage(this._currentPage - 1);
  }

  private _setInitialValues() {
    this.change = new EventEmitter();
    this.pageSize = PAGE_SIZE_DEFAULT;
    this._MAX_PAGES_SHOW = 5;
    this._currentPage = 1;
    this._totalPages = 1;
    this.showEllipsis = true;
  }

  private _calculateTotalPages() {
    if (this.totalPages > 0) {
      this._totalPages = this.totalPages;
      return;
    }
    const totalPages = Math.ceil(this.dataLength / this.pageSize);
    this._totalPages = Math.max(totalPages || 0, 1);
    this._setupPages();
  }

  private _setupPages() {
    const pages = [];
    const { firstPage, lastPage } = this._getFirstLastPage();
    const hasMorePages = this._MAX_PAGES_SHOW < this._totalPages;
    for (let numPage = firstPage; numPage <= lastPage; numPage++) {
      const page = this._addPage(
        numPage,
        numPage,
        numPage === this._currentPage
      );
      pages.push(page);
    }
    this.pages = pages;
    if (hasMorePages && this.showEllipsis) {
      this._addEllipsisLink();
    }
    this._addActive();
  }

  private _getFirstLastPage() {
    let firstPage = 1;
    let lastPage = this._totalPages;

    const isMaxSized = this._MAX_PAGES_SHOW < this._totalPages;
    let calcedMaxSize: number = isMaxSized ? this._MAX_PAGES_SHOW : 0;
    // If we want to limit the maxSize within the constraint of the adjacents, we can do so like this.
    // This adjusts the maxSize based on current page and current page and whether the front-end adjacents are added.
    if (
      isMaxSized &&
      this.showEllipsis &&
      this.cantFirstLastPage > 0 &&
      this._currentPage >= calcedMaxSize - 1 &&
      this._totalPages >= calcedMaxSize + this.cantFirstLastPage * 2
    ) {
      calcedMaxSize = this._MAX_PAGES_SHOW - this.cantFirstLastPage;
    }

    // Adjust max size if we are going to add the adjacents
    if (isMaxSized && this.showEllipsis && this.cantFirstLastPage > 0) {
      let tempStartPage =
        (Math.ceil(this._currentPage / calcedMaxSize) - 1) * calcedMaxSize + 1;
      let tempEndPage = Math.min(
        tempStartPage + calcedMaxSize - 1,
        this._totalPages
      );

      if (tempEndPage < this._totalPages) {
        if (this._totalPages - this.cantFirstLastPage > this._currentPage) {
          // && currentPage > adjacents) {
          calcedMaxSize = calcedMaxSize - this.cantFirstLastPage;
        }
      }
    }

    // recompute if maxSize
    if (isMaxSized) {
      if (!this.showEllipsis) {
        firstPage = Math.max(
          this._currentPage - Math.floor(calcedMaxSize / 2),
          1
        );
        lastPage = firstPage + calcedMaxSize - 1;

        // Adjust if limit is exceeded
        if (lastPage > this._totalPages) {
          lastPage = this._totalPages;
          firstPage = lastPage - calcedMaxSize + 1;
        }
      } else {
        // Visible pages are paginated with maxSize
        firstPage =
          (Math.ceil(this._currentPage / calcedMaxSize) - 1) * calcedMaxSize +
          1;

        // Adjust last page if limit is exceeded
        lastPage = Math.min(firstPage + calcedMaxSize - 1, this._totalPages);
      }
    }
    return { firstPage, lastPage };
  }
  private _addEllipsisLink() {
    const { firstPage, lastPage } = this._getFirstLastPage();
    if (firstPage > 1) {
      const previousPageSet = this._addPage(firstPage - 1, '...', false);
      this.pages.unshift(previousPageSet);
    }

    if (lastPage < this._totalPages) {
      const nextPageSet = this._addPage(lastPage + 1, '...', false);
      const addedNextPageSet = false;

      if (!addedNextPageSet) {
        this.pages.push(nextPageSet);
      }
    }
  }

  private _addPage(numberPage: number, text, active: boolean = false): any {
    return {
      number: numberPage,
      text,
      active: numberPage === this._currentPage,
    };
  }

  private _selectPage(page: number) {
    if (this._currentPage !== page && page > 0 && page <= this._totalPages) {
      this._updatePage(page);
      this.change.emit(this._currentPage);
      return;
    }
    if (page === FILTERS.offset) this._updatePage(PAGE_DEFAULT);
  }

  private _updatePage(page: number) {
    this._removeActive();
    this._currentPage = page;
    this._setupPages();
  }

  private _addActive() {
    const page = this.pages.filter(
      (current) => current.number === this._currentPage
    );
    if (page && page.length > 0) {
      page[0].active = true;
    }
  }

  private _removeActive() {
    const pageActive = this.pages.filter((page) => page.active);
    if (pageActive && pageActive.length > 0) {
      pageActive[0].active = false;
    }
  }
}
