import { Pipe, PipeTransform } from '@angular/core';
import { APP_CONSTANTS } from '@app/core/constants/app.constants';
import { COUNTRY_CONSTANTS } from '@app/core/constants/country.constants';

@Pipe({
  name: 'customCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(value: any, showCode: boolean = false, ...args: any[]): string {
    value = value ? value : 0;
    const [countryArgument] = args;
    const country = countryArgument || APP_CONSTANTS.DEFAULT_COUNTRY;
    const selectedCountry = COUNTRY_CONSTANTS[country];
    const formatValue = new Intl.NumberFormat(selectedCountry.language, {
      style: 'currency',
      currency: selectedCountry.currencyCode,
      minimumFractionDigits: selectedCountry.decimals,
    }).format(Number(value));
    return showCode
      ? `${formatValue} ${selectedCountry.currencyCode}`
      : formatValue;
  }
}
