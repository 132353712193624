export const FEATURES = {
  AUTH: {
    NAME: 'Auth',
    PATH: 'auth',
    PAGES: {
      LOGIN: {
        NAME: 'Login',
        PATH: 'login',
      },
    },
  },
  MY_ACCOUNT: {
    NAME: 'My Account',
    PATH: 'my-account',
  },
};
