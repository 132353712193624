import { OFFER_STATUS } from '@app/plugins/modules/product-catalogue/core/models/offer.model';
import {
  Product,
  ROUND_WAY,
} from '@app/plugins/modules/product-catalogue/core/models/product.model';
export const PRODUCTS: Product[] = [
  {
    id: '9d024c81-4749-4532-85cd-46d2d027b3231',
    reference: '9d024c81-4749-4532-85cd-46d2d027b3231',
    name: 'BNPL-Demo-03',
    number_of_offers: 5,
    offers: [
      {
        name: 'Paga en 1',
        status: OFFER_STATUS.HIDDEN,
      },
      {
        name: 'Paga en 2',
        status: OFFER_STATUS.LIVE,
      },
    ],
  },
  {
    id: '9d024c81-4749-4532-85cd-46d2d027b3232',
    reference: '9d024c81-4749-4532-85cd-46d2d027b3232',
    name: 'BNPL-Demo-01',
    number_of_offers: 7,
    offers: [
      {
        name: 'Paga en 1',
        status: OFFER_STATUS.HIDDEN,
      },
      {
        name: 'Paga en 2',
        status: OFFER_STATUS.LIVE,
      },
    ],
  },
  {
    id: '9d024c81-4749-4532-85cd-46d2d027b3233',
    reference: '9d024c81-4749-4532-85cd-46d2d027b3233',
    name: 'BNPL-Demo-02',
    number_of_offers: 10,
    offers: [
      {
        name: 'Paga en 1',
        status: OFFER_STATUS.HIDDEN,
      },
      {
        name: 'Paga en 2',
        status: OFFER_STATUS.LIVE,
      },
    ],
  },
];

export const PRODUCT: Product = {
  id: '9d024c81-4749-4532-85cd-46d2d027b323',
  name: 'product-test',
  reference: '2a913e67-84e6-470e-80a5-ea72038c4f1b',
  number_of_offers: 1,
  description: 'description-test',
  maximum_number_active_loans: 10,
  minimum_loan_amount: '10.00',
  maximum_loan_amount: '100.00',
  decimal_places: 2,
  decimal_round_way: ROUND_WAY.ROUND_UP,
  amount_requested_step: '10.00',
  status: 1,
  created_at: new Date('2022-07-12T20:34:37.274994Z'),
  updated_at: new Date('2022-07-12T20:34:37.513224Z'),
};
