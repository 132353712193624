<layout-card-base class="summary">
  <div class="summary__info">
    <h2 class="title">{{ title }}</h2>
    <div class="divider"></div>
    <h3 class="title" *ngIf="subtitle">{{ subtitle }}</h3>
    <ng-content *ngIf="!isLoading"></ng-content>
    <layout-summary-items-loader
      *ngIf="isLoading"
    ></layout-summary-items-loader>
  </div>
</layout-card-base>
