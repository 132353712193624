export const environment = {
  production: true,
  testServeWait: 1000,
  isEnablePutRecord: true,
  isTestServer: false,
  api_url: 'https://mo-services-payu-bnpl-co-production.moprestamo.com/admin',
  ga_key: '',
  gtag_id: '',
  pixel_id: '',
  hotjar_id: '3115887',
  hotjar_version: '6',
};
