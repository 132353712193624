import { PreCustomerPageTransle } from '../../models/pre-customer-translate';

const PRE_CUSTOMERS_TRANSLATE: PreCustomerPageTransle = {
  PRE_CUSTOMERS: {
    TITLE: 'Pre-clientes',
    DESCRIPTION:
      'Revise todos sus pre-clientes actuales. Filtre y descargue esta base de datos para <br />navegar y analizar rápidamente su base de clientes previos.',
    FILTERS: {
      TITLE: 'Herramientas de búsqueda',
      DESCRIPTION:
        'Filtre su base de datos previa al cliente utilizando nuestros filtros de búsqueda para campos específicos.',
      SEARCH_BAR: 'Nombre, Número de teléfono',
      ID: 'ID de Pre-cliente',
      CREATED_AT: 'Fecha de creación',
      UPDATED_AT: 'Fecha de modifición',
      SELECT_PRODUCT: 'Seleccionar producto',
    },
    TABLE: {
      REFERENCE: 'ID de Pre-cliente',
      DISPLAY_NAME: 'Nombre',
      PHONE_NUMBER: 'Número de teléfono',
      CREATED_AT: 'Fecha de creación',
      UPDATED_AT: 'Fecha de modifición',
      ASSOCIATED_PRODUCT: 'Productos asociados',
    },
  },

  PRE_CUSTOMER_PROFILE: {
    TITLE: 'Perfil de Pre-cliente',
    DESCRIPTION:
      'Revisa la información disponible de tu pre-cliente. Eche un vistazo <br />más de cerca a sus detalles básicos, puntajes actuales y productos.',
    INFO: {
      PRE_CUSTOMER_ID: 'ID de Pre-cliente',
      CREATED_AT: 'Fecha de creación:',
      UPDATED_AT: 'Fecha de modifición:',
    },
    DETAILS: {
      TITLE: 'Detalles de Pre-cliente',
      CUSTOMER_NAME: 'Nombre del cliente',
      DOCUMENT_TYPE: 'Tipo de Documento',
      NUMBER: 'Número',
      EMAIL: 'Correo electrónico',
      PHONE_NUMBER: 'Número de teléfono',
      OTHERS: 'Otro',
    },
    PRODUCTS_AND_SCORES: {
      TITLE: 'Productos y puntuaciones',
    },
  },
};
export default PRE_CUSTOMERS_TRANSLATE;
