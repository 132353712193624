<div class="page-header">
  <layout-breadcrumb></layout-breadcrumb>
  <layout-page-description
    [title]="title"
    [description]="description"
    [total]="total"
  >
  </layout-page-description>
  <ng-content></ng-content>
</div>
