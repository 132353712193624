import { AccountItem } from '../models/account.model';

export const PRODUCT_FAMILY = {
  BNPL: 'BNPL', // -> Buy Now Pay Later
  MCA: 'MCA', // -> Merchant Cash Advance
  MICRO_CREDIT: 'MICRO', // -> Micro Credit
  CREDIT_CARD: 'CREDIT', // -> Credit Card
};

export const MO_PRODUCTS = {
  MOSCORE: 'MOSCORE',
  MOGEO: 'MOGEO',
  MOSLABS: 'MOSLABS',
};

export const ITEM_BY_PRODUCT_FAMILY = {
  [PRODUCT_FAMILY.BNPL]: {
    icon: 'app/presentation/assets/img/icons/product-families/mobnpl.svg',
    name: 'MO BNPL',
  } as AccountItem,
  [PRODUCT_FAMILY.MCA]: {
    icon: 'app/presentation/assets/img/icons/product-families/mobnpl.svg',
    name: 'MO MCA',
  } as AccountItem,
  [PRODUCT_FAMILY.CREDIT_CARD]: {
    icon: 'app/presentation/assets/img/icons/product-families/mobnpl.svg',
    name: 'MO Credit Card',
  } as AccountItem,
  [PRODUCT_FAMILY.MICRO_CREDIT]: {
    icon: 'app/presentation/assets/img/icons/product-families/mobnpl.svg',
    name: 'MO Micro Credit',
  } as AccountItem,
};

export const ITEM_BY_MO_PRODUCT = {
  [MO_PRODUCTS.MOSCORE]: {
    icon: 'app/presentation/assets/img/icons/mo-products/moscore.svg',
    name: 'MO Score',
  } as AccountItem,
  [MO_PRODUCTS.MOGEO]: {
    icon: 'app/presentation/assets/img/icons/mo-products/mogeo.svg',
    name: 'MO Geo',
  } as AccountItem,
  [MO_PRODUCTS.MOSLABS]: {
    icon: 'app/presentation/assets/img/icons/mo-products/moscore.svg',
    name: 'MO Labs',
  } as AccountItem,
};
