export const MESSAGES = {
  ERRORS: {
    DEFAULT: 'An error has occurred, please try again',
    NOT_FOUND_RESOURCE:
      'The requested resource is not available, please try again',
    SESSION_FINISHED:
      'The session is invalid or has finished. Please log in again.',
    FIELD_REQUIRED: 'You must complete all the fields',
  },
};
