import { Credencials, RefreshToken, Token } from '@app/core/models/auth.model';
import { createAction, props } from '@ngrx/store';

export const login = createAction(
  '[Login Page] Login',
  props<{ credencials: Credencials }>()
);
export const successLogin = createAction(
  '[Auth Effects] Success Login',
  props<{ token: Token }>()
);
export const failedLogin = createAction('[Auth Effects] Failed Login');

export const refreshToken = createAction(
  '[Idle Service] Refresh Token',
  props<{ refreshToken: RefreshToken }>()
);
export const successRefreshToken = createAction(
  '[Auth Effects] Success Refresh Token',
  props<{ token: Token }>()
);
export const failedRefreshToken = createAction(
  '[Auth Effects] Failed Refresh Token'
);

export const updateIsAuthenticated = createAction(
  '[Auth Facade] Update Is Authenticated',
  props<{ isAuthenticated: boolean }>()
);

export const logout = createAction('[Auth Effects] Logout');
