import { Injectable } from '@angular/core';
import { LanguageService } from '@app/domain/services/app/language.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs';
import * as projectSelectors from '../project/project.selectors';
import * as appActions from './app.actions';

@Injectable()
export class AppEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _languageService: LanguageService
  ) {}

  updateLanguage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(appActions.updateLanguage),
        withLatestFrom(
          this._store.select(projectSelectors.selectProjectFamily)
        ),
        map(([{ language }, familyName]) => {
          this._languageService.setLanguage(language, familyName);
        })
      ),
    { dispatch: false }
  );
}
