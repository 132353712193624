<div class="mo-cell" (click)="onGoToUrlRow()">
  <div
    *ngIf="
      !header?.typeColumn ||
      header?.typeColumn === TYPE_COLUMN.LABEL ||
      header?.typeColumn === TYPE_COLUMN.ID
    "
    [class]="header?.customClass?.row"
  >
    <label>
      {{ getTextColumn(header, row) }}
    </label>
  </div>
  <div
    *ngIf="header.typeColumn === TYPE_COLUMN.TITLE_CASE"
    [class]="header?.customClass?.row + ' ' + getTextColumn(header, row)"
  >
    <label>
      {{ getTextColumn(header, row) | titlecase }}
    </label>
  </div>

  <div
    *ngIf="header.typeColumn === TYPE_COLUMN.DATE"
    [class]="header?.customClass?.row"
  >
    <label>{{ getDate(header, row) }}</label>
  </div>

  <div
    *ngIf="header.typeColumn === TYPE_COLUMN.STATUS"
    [class]="header?.customClass?.row + ' ' + getTextColumn(header, row)"
  >
    <label>{{ getTextColumn(header, row) }} </label>
  </div>

  <div
    *ngIf="header.typeColumn === TYPE_COLUMN.CURRENCY"
    [class]="header?.customClass?.row"
  >
    <label>{{ getCurrencyValue(header, row) }} </label>
  </div>

  <div
    *ngIf="header.typeColumn === TYPE_COLUMN.COMPOUND_LINK"
    [class]="header?.customClass?.row"
  >
    <a [routerLink]="header?.compoundLink" [queryParams]="queryParams"
      >{{ getTextLinkColumn(header, row) }}
    </a>
  </div>

  <div
    *ngIf="header.typeColumn === TYPE_COLUMN.CUSTOM_TEMPLATE"
    [class]="header?.customClass?.row"
  >
    <ng-container
      [ngTemplateOutlet]="header.template"
      [ngTemplateOutletContext]="{
        row,
        header
      }"
    >
    </ng-container>
  </div>
</div>
