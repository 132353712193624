<div class="container-user-card-menu">
  <div class="option">
    <mo-button
      [text]="'APP.MENUS.NAVBAR.LOGOUT' | translate"
      [designClass]="BUTTON_DESIGN_CLASS.PRIMARY"
      [displayBlock]="true"
      [size]="SIZES.MD"
      (click)="onLogout()"
    >
    </mo-button>
  </div>
</div>
