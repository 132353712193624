import { PaymentsPageTranslate } from '../../models/payments-translate';

const PAYMENTS_TRANSLATE: PaymentsPageTranslate = {
  PAYMENTS: {
    TITLE: 'Pagos',
    DESCRIPTION: '',
    FILTERS: {
      TITLE: 'Herramientas de búsqueda',
      DESCRIPTION:
        'Filtre su base de datos de crédito utilizando filtros predeterminados y avanzados o utilice la búsqueda de texto libre para campos específicos.',
      SEARCH_BAR: 'ID de pago, ID de cliente, ID de crédito',
      AMOUNT_TITLE: 'Monto del pago',
      AMOUNT_LESS: 'Desde',
      AMOUNT_GRATHER: 'hasta',
      STATUS: 'Estado',
      DATE_OF_PAYMENT_TITLE: 'Fecha del pago',
      DATE_OF_PAYMENT_PLACEHOLDER: 'Elige un rango',
    },
    TABLE: {
      ID: 'ID de pago',
      LOAN_ID: 'ID de crédito',
      CUSTOMER_ID: 'ID del cliente',
      STATUS: 'Estado',
      AMOUNT: 'Monto',
      TYPE: 'Método',
      PAID_AT: 'Fecha',
    },
  },
};
export default PAYMENTS_TRANSLATE;
