import { MENU_CONFIG_DISABLED } from '@instance-config/menu.config';
import { MERCHANT_MANAGER_FEATURE } from './feature.constants';
export const MERCHANT_MANAGER_ROUTES = [
  {
    path: MERCHANT_MANAGER_FEATURE.PATH,
    loadChildren: () =>
      import('../../presentation/merchant-manager.module').then(
        (m) => m.MerchantManagerModule
      ),
    data: {
      breadcrumb: 'MERCHANT_MANAGER._NAME',
      disabled: MENU_CONFIG_DISABLED.MERCHANT_MANAGER.MODULE ?? false
    },
  },
];
