import SPANISH_BNPL_CUSTOMERS from '@app/plugins/modules/customers/core/i18n/lang-bnpl/es';
import SPANISH_BNPL_LOAN_MANAGER from '@app/plugins/modules/loan-manager/core/i18n/lang-bnpl/es';
import SPANISH_BNPL_MERCHANT_MANAGER from '@app/plugins/modules/merchant-manager/core/i18n/lang-bnpl/es';
import SPANISH_BNPL_PRODUCT_CATALOGUE from '@app/plugins/modules/product-catalogue/core/i18n/lang-bnpl/es';

const SPANISH_PLUGINS_BNPL_TRANSLATE = {
  CUSTOMERS: { ...SPANISH_BNPL_CUSTOMERS },
  LOAN_MANAGER: { ...SPANISH_BNPL_LOAN_MANAGER },
  PRODUCT_CATALOGUE: { ...SPANISH_BNPL_PRODUCT_CATALOGUE },
  MERCHANT_MANAGER: { ...SPANISH_BNPL_MERCHANT_MANAGER },
};
export default SPANISH_PLUGINS_BNPL_TRANSLATE;
