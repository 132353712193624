<div class="accordion" [ngClass]="{ open: open, checked: checked }">
  <div class="accordion__header" (click)="onToggle()">
    <div class="title-container">
      <img
        src="/app/presentation/assets/img/icons/checked-green.svg"
        alt="checked"
        class="checked-icon"
        *ngIf="checked && !open"
      />
      <p class="title">{{ title }}</p>
    </div>
    <i
      class="uil expland-icon"
      [ngClass]="{ 'uil-plus': !open, 'uil-minus': open }"
    ></i>
  </div>
  <div class="accordion__body">
    <ng-content></ng-content>
  </div>
</div>
