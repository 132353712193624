import { FEATURES } from './feature.constants';

export const APP_CONSTANTS = {
  PROPS: {
    STORAGE_TOKEN: 'prop.session-token-mock',
    STORAGE_TOKEN_MOCK: 'prop.session-token',
    STORAGE_HOUR_TOKEN: 'prop.session-hour-token',
    STORAGE_PASSWORD_TOKEN: 'prop.create-password-token',
  },
  DEFAULT_DATE_FORMAT: 'E, dd MMM, yyyy',
  DEFAULT_COUNTRY: 'US',
  HTTP_CODES: {
    NOT_FOUND: 404,
    FORBIDDEN: 403,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    CONFLICT: 409,
    OK: 200,
  },
  TEST_INFO: {
    TOKEN: 'gR3MaJoR4R3C8rshY0',
    EMAIL: 'movers@wearemo.com',
    USER: 'movers@wearemo.com',
  },
  SUPPORT_EMAIL: 'motechsupport@wearemo.com',
  ROUTES: {
    AUTH: {
      LOGIN: `/${FEATURES.AUTH.PATH}/${FEATURES.AUTH.PAGES.LOGIN.PATH}`,
    },
    MY_ACCOUNT: `/${FEATURES.MY_ACCOUNT.PATH}`,
    CREDIT_SCORE: '/credit-score',
    SETTINGS: '/settings',
    NOTIFICATION_CENTER: '/notification-center',
    FAQ: '/faq',
    HELP_CENTER: '/help-center',
    SUPPORT: '/support',
  },

  API_URLS: {},
  DOCUMENTS: {},
};
