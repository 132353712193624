import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'mo-checkbox',
  templateUrl: './mo-checkbox.component.html',
  styleUrls: ['./mo-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MoCheckboxComponent),
      multi: true,
    },
  ],
})
export class MoCheckboxComponent implements ControlValueAccessor {
  @Input() id!: string;
  @Input() label?: string;
  public isSelected?: boolean;
  public isDisabled?: boolean;
  private _onChange = (value: boolean) => {};
  private _onTouch = () => {};

  constructor() {}

  writeValue(selected: boolean): void {
    this.isSelected = selected;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChange(event: Event) {
    const value = (<HTMLInputElement>event.target).checked;
    this.isSelected = value;
    this._onChange(value);
    this._onTouch();
  }
}
