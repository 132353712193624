import { Component, Input } from '@angular/core';

@Component({
  selector: 'sub-menu-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class SubMenuItemComponent {
  @Input() active: boolean;
  @Input() label: string;
  @Input() isSubMenu: boolean = false;
  @Input() product: string;
}
