import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CustomCurrencyPipe } from '@app/presentation/layout/pipes/currency.pipe';
import { MODatePipe } from '@app/presentation/layout/pipes/date.pipe';
import { TYPE_COLUMN } from '../../enums/table.enum';
import { TableHeader } from '../../interfaces/table.interface';

@Component({
  selector: 'table-row',
  templateUrl: './row.component.html',
  styleUrls: ['./row.component.scss'],
})
export class RowComponent<T> {
  @Input() row: T;
  @Input() header: TableHeader;
  @Input() urlRow: string;
  @Input() urlRowIdentifier: string = TYPE_COLUMN.ID;

  public TYPE_COLUMN = TYPE_COLUMN;
  public queryParams: any = {};

  constructor(
    private _appDate: MODatePipe,
    private _currency: CustomCurrencyPipe,
    private _router: Router
  ) {}

  public getTextColumn(header: TableHeader, row: T): string {
    let text =
      header.dataKey && (row[header.dataKey] || row[header.dataKey] === 0)
        ? String(row[header.dataKey])
        : '-';
    return header.transform ? header.transform(text) : text;
  }

  public getDate(header: TableHeader, row: T): string {
    return header.dataKey && row[header.dataKey]
      ? this._appDate.transform(row[header.dataKey])
      : '-';
  }

  public getCurrencyValue(header: TableHeader, row: T): string {
    return header.dataKey && row[header.dataKey]
      ? this._currency.transform(row[header.dataKey])
      : '-';
  }

  public getTextLinkColumn(header: TableHeader, row: T): string {
    if (this.header?.compoundLinkParams) {
      this.header?.compoundLinkParams
        .filter((params) => !!params)
        .forEach((param) => {
          this.queryParams = {
            ...this.queryParams,
            [param?.keyAlias ?? param?.key]: param?.value ?? row[param?.key],
          };
        });
    }
    return this.getTextColumn(header, row);
  }

  public onGoToUrlRow() {
    if (this.urlRow && this.header?.typeColumn != TYPE_COLUMN.COMPOUND_LINK) {
      const id = this.row[this.urlRowIdentifier];
      this._router.navigate([this.urlRow], { queryParams: { id } });
    }
  }
}
