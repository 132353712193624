import { Component, Input } from '@angular/core';

@Component({
  selector: 'layout-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent {
  @Input() title: string;
  @Input() subtitle?: string;
  @Input() isLoading?: boolean;
}
