import { formatDate } from '@angular/common';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {
  BACK_DATE_FORMAT_LANGUAJE,
  BACK_FILTERS_DATE_FORMAT,
} from '@app/core/constants/filters.constant';
import { DateTranslate } from '@app/core/i18n/models/date-translate';
import { ICON_COLORS } from '@app/presentation/layout/components/icon/icon.constants';
import { MODatePipe } from '@app/presentation/layout/pipes/date.pipe';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import {
  DateRange,
  DateRanges,
  TimePeriod,
} from 'ngx-daterangepicker-material/daterangepicker.component';
import { map, Observable } from 'rxjs';
import {
  DATE_PERIODS,
  DEFAULT_MO_DATE_FORMAT,
  TIMEZONES,
} from '../../constants/date.constants';
import { ICON_POSITION } from '../../enums/fields.type';
import {
  MoDateConfig,
  MoDateRange,
  MoDateToolip,
} from '../../interfaces/date.interface';

@Component({
  selector: 'mo-date',
  templateUrl: './mo-date.component.html',
  styleUrls: ['./mo-date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MoDateComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MoDateComponent),
      multi: true,
    },
  ],
})
export class MoDateComponent
  implements OnInit, ControlValueAccessor, Validator
{
  @Input() placeholder?: string = '';
  @Input() label?: string;
  @Input() singleDatePicker: boolean = false;
  @Input() autoApply!: boolean;
  @Input() showRangeLabelOnInput: boolean = false;
  @Input() showClearButton: boolean = false;
  @Input() linkedCalendars: boolean = false;
  @Input() keepCalendarOpeningWithRange: boolean = true;
  @Input() showCustomRangeLabel: boolean = true;
  @Input() alwaysShowCalendars: boolean = true;
  @Input() showCancel: boolean = true;
  @Input() maxDate: dayjs.Dayjs;
  @Input() minDate: dayjs.Dayjs;
  @Input() invalidDates: dayjs.Dayjs[] = [];
  @Input() tooltips: MoDateToolip[] = [];
  @Input() locale: LocaleConfig;
  @Input() ranges: DateRanges;
  // Icon attributes
  @Input() icon?: string = 'uil-calender';
  @Input() iconPosition?: ICON_POSITION = ICON_POSITION.RIGHT;
  @Input() iconColor?: ICON_COLORS = ICON_COLORS.NEUTRAL;

  public selected: TimePeriod;
  public dateConfig$: Observable<MoDateConfig>;
  public isDisabled?: boolean;
  public value?: string;

  private _onChange = (
    _?: string | Date | null | MoDateRange | TimePeriod | dayjs.Dayjs
  ) => {};
  private _onTouch = () => {};

  constructor(
    private _translateService: TranslateService,
    private _MODatePipe: MODatePipe
  ) {}

  ngOnInit(): void {
    this._setInitialValues();
  }

  validate(control: AbstractControl<any, any>): ValidationErrors {
    // TOOO PENDING VALIDATE
    return null;
  }

  writeValue(datePeriod: TimePeriod): void {
    this._setValue(datePeriod);
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public onRangeClicked(dateRange: DateRange): void {
    const datePeriod: TimePeriod = {
      startDate: dateRange?.dates[0] ?? dayjs(),
      endDate: dateRange?.dates[1] ?? dayjs(),
    };
    this._onChangeValueFormatted(datePeriod);
  }

  public onDatesUpdated(datePeriod: TimePeriod): void {
    this._setValue(datePeriod);
    this._onChangeValueFormatted(datePeriod);
  }

  public isInvalidDate = (m: dayjs.Dayjs): boolean => {
    return this.invalidDates.some((d) => d.isSame(m, DATE_PERIODS.DAY));
  };

  public isTooltipDate = (m: dayjs.Dayjs): string | boolean | null => {
    const tooltip = this.tooltips.find((tt) =>
      tt.date.isSame(m, DATE_PERIODS.DAYS)
    );
    if (tooltip) return tooltip?.text;
    else return false;
  };

  private _setInitialValues() {
    this.value = '';
    this.dateConfig$ = this._translateService.get('APP.DATES').pipe(
      map((DATE: DateTranslate) => {
        if (this.singleDatePicker) this._setSingleDatePicker();
        return {
          locale: this.locale ?? {
            applyLabel: DATE.BUTTONS.SET_DATE,
            cancelLabel: DATE.BUTTONS.CANCEL,
            customRangeLabel: DATE.RANGE_OPTIONS.CUSTMOM,
            displayFormat: DEFAULT_MO_DATE_FORMAT,
            format: BACK_FILTERS_DATE_FORMAT,
            daysOfWeek: DATE.DATE_OF_WEEK,
            monthNames: DATE.MONTH_NAMES,
          },
          ranges: this.ranges ?? {
            [DATE.RANGE_OPTIONS.LAST_7_DAYS]: [
              dayjs().subtract(6, DATE_PERIODS.DAYS),
              dayjs(),
            ],
            [DATE.RANGE_OPTIONS.LAST_14_DAYS]: [
              dayjs().subtract(13, DATE_PERIODS.DAYS),
              dayjs(),
            ],
            [DATE.RANGE_OPTIONS.LAST_30_DAYS]: [
              dayjs().subtract(29, DATE_PERIODS.DAYS),
              dayjs(),
            ],
            [DATE.RANGE_OPTIONS.LAST_3_MOUNTS]: [
              dayjs()
                .subtract(3, DATE_PERIODS.MONTH)
                .startOf(DATE_PERIODS.MONTH),
              dayjs().subtract(1, DATE_PERIODS.MONTH).endOf(DATE_PERIODS.MONTH),
            ],
            [DATE.RANGE_OPTIONS.LAST_12_MOUNTS]: [
              dayjs()
                .subtract(12, DATE_PERIODS.MONTH)
                .startOf(DATE_PERIODS.MONTH),
              dayjs().subtract(1, DATE_PERIODS.MONTH).endOf(DATE_PERIODS.MONTH),
            ],
            [DATE.RANGE_OPTIONS.MONTH_TO_DATE]: [
              dayjs().startOf(DATE_PERIODS.MONTH),
              dayjs(),
            ],
            [DATE.RANGE_OPTIONS.QUARTER_TO_DATE]: [
              dayjs()
                .subtract(2, DATE_PERIODS.MONTH)
                .startOf(DATE_PERIODS.MONTH),
              dayjs(),
            ],
          },
        };
      })
    );
  }

  private _setValue(datePeriod: TimePeriod) {
    this.value = !!datePeriod ? this._setValueFormat(datePeriod) : '';
  }

  private _setValueFormat(datePeriod: TimePeriod): string {
    return this.singleDatePicker
      ? this._MODatePipe.transform(
          datePeriod.startDate.toDate(),
          TIMEZONES.GREENWICH_MEAN_TIME
        )
      : `${this._MODatePipe.transform(
          datePeriod.startDate.toDate(),
          TIMEZONES.GREENWICH_MEAN_TIME
        )} - ${this._MODatePipe.transform(
          datePeriod.endDate.toDate(),
          TIMEZONES.GREENWICH_MEAN_TIME
        )}`;
  }

  private _setSingleDatePicker() {
    this.autoApply = this.singleDatePicker;
    this.showCustomRangeLabel = false;
    this.ranges = {};
  }

  private _onChangeValueFormatted(datePeriod: TimePeriod) {
    const formatedValue: string | MoDateRange = this.singleDatePicker
      ? this._setBackFormat(datePeriod.startDate)
      : {
          startDate: this._setBackFormat(datePeriod.startDate),
          endDate: this._setBackFormat(datePeriod.endDate),
        };
    this._onChange(formatedValue);
  }

  private _setBackFormat(date: dayjs.Dayjs): string {
    return formatDate(
      date.toDate(),
      BACK_FILTERS_DATE_FORMAT,
      BACK_DATE_FORMAT_LANGUAJE,
      TIMEZONES.GREENWICH_MEAN_TIME
    );
  }
}
