<div class="list-with-controls">
  <ng-container *ngIf="!isLoading; else loading">
    <div
      class="list-with-controls__content"
      *ngIf="response?.results?.length > 0; else empty"
      [ngStyle]="customContentStyles"
    >
      <ng-container *ngFor="let item of response?.results">
        <ng-container
          [ngTemplateOutlet]="customTemplate"
          [ngTemplateOutletContext]="{
            item
        }"
        ></ng-container>
      </ng-container>
    </div>
  </ng-container>

  <div class="list-with-controls__navigation">
    <table-paginator
      (change)="onChangePage($event)"
      [currentPage]="currentPage"
      [dataLength]="response?.filtered"
      [pageSize]="pageSize"
    ></table-paginator>
  </div>
</div>

<ng-template #loading>
  <div [ngStyle]="customContentStyles">
    <layout-skeleton
      *ngFor="let skeleton of skeletons"
      [skeleton]="skeleton"
    ></layout-skeleton>
  </div>
</ng-template>

<ng-template #empty>
  <table-empty></table-empty>
</ng-template>
