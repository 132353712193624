<div class="mo-cell" (click)="onHeaderClick(header)">
  <label class="header" [ngClass]="{ 'has-sort': header?.isSortable }">
    {{ header.label | translate }}
  </label>
  <img
    *ngIf="header?.isSortable"
    class="sort-icon"
    [src]="sortIcon"
    alt="sort"
  />
</div>
