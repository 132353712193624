<div class="options">
  <mo-button
    icon="uil-import"
    [text]="'APP.TABLES.OPTIONS.DOWNLOAD' | translate"
    [iconPosition]="ICON_POSITION.LEFT"
    [designClass]="BUTTON_DESIGN_CLASS.OUTLINE_PRIMARY_DARK"
    [size]="SIZES.SM"
    (click)="onDownload()"
  >
  </mo-button>
</div>
