import { Injectable } from '@angular/core';
import { LANGUAGES } from '@app/core/i18n/constants/translate.constants';
import { Menu } from '@app/core/models/menu.model';
import { Store } from '@ngrx/store';
import * as appActions from '@store/app/app.actions';
import * as appSelectors from '@store/app/app.selectors';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppFacade {
  public menu$: Observable<Menu>;
  public isMenuCollapsed$: Observable<boolean>;
  public language$: Observable<string>;

  constructor(private _store: Store) {
    this._setSelectors();
  }

  public updateLanguage(language: LANGUAGES) {
    this._store.dispatch(appActions.updateLanguage({ language }));
  }

  // TODO: implement
  public showGlobalMessage(
    message: string,
    callback: Function,
    title?: string
  ) {
    return;
  }

  // TODO: implement
  public showGlobalError(message: string) {
    return;
  }

  private _setSelectors() {
    this.menu$ = this._store.select(appSelectors.selectMenu);
    this.language$ = this._store.select(appSelectors.selectLanguage);
  }
}
