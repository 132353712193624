import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IKeyValue } from '@app/core/models/utils.model';
import { filter, Subscription } from 'rxjs';

@Component({
  selector: 'layout-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit, OnDestroy {
  @Input() steps: IKeyValue[];
  private _routerSubscription: Subscription;
  private _currentIndexStep: number;

  constructor(private _router: Router) {}

  ngOnInit(): void {
    if (!this._currentIndexStep) {
      this._currentIndexStep = this.steps.findIndex(
        (step) => this._router.url === step.value
      );
    }

    this._routerSubscription = this._router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(
        (event: NavigationEnd) =>
          (this._currentIndexStep = this.steps.findIndex(
            (step) => event.url === step.value
          ))
      );
  }

  ngOnDestroy(): void {
    this._routerSubscription.unsubscribe();
  }

  public getState(index: number): string {
    if (index === this._currentIndexStep) return 'selected';
    if (index < this._currentIndexStep) return 'completed';
    return 'disabled';
  }
}
