export enum CONTENT_TYPE {
  JSON = 'application/json',
  TEXT_CSV = 'text/csv',
}

export enum RESPONSE_TYPE {
  JSON = 'json',
  ARRAY_BUFFER = 'arraybuffer',
  TEXT = 'text',
  BLOB = 'blob',
}

export enum HTTP_HEADERS {
  CONTENT_TYPE = 'Content-Type',
  ACCEPT_LANGUAGE = 'Accept-Language',
  AUTHORIZATION = 'Authorization',
}
