import { MENU_CONFIG_DISABLED } from '@instance-config/menu.config';
import { LOAN_MANAGER_FEATURE } from './feature.constants';

export const LOAN_MANAGER_ROUTES = [
  {
    path: LOAN_MANAGER_FEATURE.PATH,
    loadChildren: () =>
      import('../../presentation/loan-manager.module').then(
        (m) => m.LoanManagerModule
      ),
    data: {
      breadcrumb: 'LOAN_MANAGER._NAME',
      disabled: MENU_CONFIG_DISABLED.LOAN_MANAGER.MODULE ?? false
    },
  },
];
