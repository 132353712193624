import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'layout-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss'],
})
export class StepComponent {
  @Input() label: string;
  @Input() status?: string;
  @Input() path?: string;

  constructor(private _router: Router) {}

  public onClick(): void {
    if (this.path && this.status !== 'disabled') {
      this._router.navigateByUrl(this.path);
    }
  }
}
