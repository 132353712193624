import { MERCHANT_STATUS } from '@app/plugins/modules/merchant-manager/core/constants/merchants.constants';
import { MerchantGroup } from '@app/plugins/modules/merchant-manager/core/models/merchant-groups.model';
import { TableResponse } from '@app/presentation/layout/mo-tables/interfaces/table.interface';

export const MERCHANT_GROUPS: TableResponse<MerchantGroup> = {
  filtered: 300,
  count: 3,
  results: [
    {
      name: 'Fashion',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam. Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam.',
      reference: '234234234234',
      merchants: [
        {
          id: '123123-asdasd-123123',
          reference: '0001',
          display_name: 'Merchant name',
          national_id: '1234567890',
          status: MERCHANT_STATUS.ACTIVE,
          sales: 1000,
          payout: 20000,
          created_at: new Date('2019-10-01T15:59:15-05:00'),
        },
      ],
      number_of_merchants: 25,
    },
    {
      name: 'Houte Couture',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam. Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam. Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam.',
      reference: '234234234234',
      merchants: [
        {
          id: '123123-asdasd-123123',
          reference: '0001',
          display_name: 'Merchant name',
          national_id: '1234567890',
          status: MERCHANT_STATUS.ACTIVE,
          sales: 1000,
          payout: 20000,
          created_at: new Date('2019-10-01T15:59:15-05:00'),
        },
      ],
      number_of_merchants: 35,
    },
    {
      name: 'Toys & Kids',
      description: 'Lorem ipsum dolor ',
      number_of_merchants: 15,
      reference: '234234234234',
      merchants: [
        {
          id: '123123-asdasd-123123',
          reference: '0001',
          display_name: 'Merchant name',
          national_id: '1234567890',
          status: MERCHANT_STATUS.ACTIVE,
          sales: 1000,
          payout: 20000,
          created_at: new Date('2019-10-01T15:59:15-05:00'),
        },
      ],
    },
    {
      name: 'Electronics',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam. Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam.',
      reference: '234234234234',
      merchants: [
        {
          id: '123123-asdasd-123123',
          reference: '0001',
          display_name: 'Merchant name',
          national_id: '1234567890',
          status: MERCHANT_STATUS.ACTIVE,
          sales: 1000,
          payout: 20000,
          created_at: new Date('2019-10-01T15:59:15-05:00'),
        },
      ],
      number_of_merchants: 55,
    },
    {
      name: 'Sports',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam. Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam.',
      reference: '234234234234',
      merchants: [
        {
          id: '123123-asdasd-123123',
          reference: '0001',
          display_name: 'Merchant name',
          national_id: '1234567890',
          status: MERCHANT_STATUS.ACTIVE,
          sales: 1000,
          payout: 20000,
          created_at: new Date('2019-10-01T15:59:15-05:00'),
        },
      ],
      number_of_merchants: 45,
    },
    {
      name: 'Homewear',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam. Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam.',
      reference: '234234234234',
      merchants: [
        {
          id: '123123-asdasd-123123',
          reference: '0001',
          display_name: 'Merchant name',
          national_id: '1234567890',
          status: MERCHANT_STATUS.ACTIVE,
          sales: 1000,
          payout: 20000,
          created_at: new Date('2019-10-01T15:59:15-05:00'),
        },
      ],
      number_of_merchants: 2,
    },
  ],
};
