import { MoDateRange } from '@app/presentation/layout/mo-forms/interfaces/date.interface';
import { FILTERS } from '../constants/filters.constant';
import {
  Filters,
  FiltersOptions,
  FiltersValidationsOptions,
} from './filters.model';

export interface IKeyValue {
  key: string;
  value: string;
}

export interface QueryParamRouting {
  key: string;
  value?: string;
  keyAlias?: string;
}

export const setFilterValidation = (
  state: any,
  filters: any,
  filtersOptions: FiltersOptions
): FiltersValidationsOptions => {
  const baseFilters: Filters = {
    offset: filters.offset ?? state.filters.offset,
    limit: filters.limit ?? state.filters.limit,
    ordering: filters.ordering ?? state.filters.ordering,
  };
  let updatedFilters = baseFilters;

  if (filtersOptions?.isFiltred)
    updatedFilters = { ...updatedFilters, ...filters };
  if (filtersOptions?.isNativationFilter)
    updatedFilters = { ...state?.filters, ...updatedFilters };

  const isFiltred = filtersOptions?.isNativationFilter
    ? state?.isFiltred
    : !!filtersOptions?.isFiltred;

  return { filters: updatedFilters, isFiltred };
};

export type Status = { [key: string]: string };

export const random = (min = 60, max = 100) =>
  Math.floor(Math.random() * (max - min)) + min;

export const generateQueryParamsFromObject = (params): string => {
  return `\\?${Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&')}`;
};

export type Mutable<T> = {
  -readonly [P in keyof T]: T[P];
};

/**
 * Performs a deep clone of an object.
 * https://javascript.plainenglish.io/deep-clone-an-object-and-preserve-its-type-with-typescript-d488c35e5574
 *
 * @param source: The object to clone.
 * @param preservePropertyDescriptor: Whether to preserve thee property descriptor. To see what this contains, refer to:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/getOwnPropertyDescriptor
 * @param excludedProperties A string or array of strings representing property names you do not want to copy into (at
 * any level) of the cloned object.
 *
 * @returns string A Mutable version of the source object.
 *
 */
export const deepCopy: Function = <T>(
  source: T,
  preservePropertyDescriptor: boolean = false,
  excludedProperties: string[]
): Mutable<T> => {
  return Array.isArray(source)
    ? source.map(
        (item: T): Mutable<T> =>
          deepCopy(item, preservePropertyDescriptor, excludedProperties)
      )
    : source instanceof Date
    ? new Date(source.getTime())
    : source && typeof source === 'object'
    ? Object.getOwnPropertyNames(source).reduce(
        (obj: any, prop: string): Mutable<T> => {
          if (excludedProperties?.includes(prop)) {
            // skip property
            return obj;
          } else {
            if (preservePropertyDescriptor) {
              Object.defineProperty(
                obj,
                prop,
                Object.getOwnPropertyDescriptor(source, prop)
              );
            }
            // @ts-ignore
            obj[prop] = deepCopy(
              source[prop],
              preservePropertyDescriptor,
              excludedProperties
            );
            return obj;
          }
        },
        Object.create(Object.getPrototypeOf(source))
      )
    : (source as Mutable<T>);
};

export const deepMergeWithConserveStructure: Function = (obj1, obj2) => {
  let result = { ...obj1 };
  const keys = Object.keys(obj2);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const value = obj2[key];
    if (typeof value === 'object' && value !== null) {
      result = {
        ...result,
        [key]: deepMergeWithConserveStructure(result[key], value),
      };
    } else {
      result = { ...result, [key]: value };
    }
  }
  return result;
};

export const setFiltersWithDateRanges: Function = (
  formValue: any,
  controlsWithDateRanges: string[],
  offset?: number
) => {
  let filters = formValue;
  controlsWithDateRanges.forEach((control) => {
    const ranges: MoDateRange = formValue[control];
    const rangeDates = {
      [`${control}__lte`]: ranges?.endDate,
      [`${control}__gte`]: ranges?.startDate,
    };
    filters = {
      ...filters,
      ...rangeDates,
      offset: offset ?? FILTERS.offset,
    };
    delete filters[control];
  });
  return filters;
};

export const clearFiltersWithDateRanges: Function = (
  formValue: any,
  controlsWithDateRanges: string[]
) => {
  let filters = formValue;
  controlsWithDateRanges.forEach((control) => {
    const rangeDates = {
      [`${control}__lte`]: '',
      [`${control}__gte`]: '',
    };
    filters = {
      ...filters,
      ...rangeDates,
      offset: FILTERS.offset,
    };
  });
  return filters;
};
