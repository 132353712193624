import { Component, Input } from '@angular/core';

@Component({
  selector: 'layout-summary-items-category',
  templateUrl: './summary-items-category.component.html',
  styleUrls: ['./summary-items-category.component.scss'],
})
export class SummaryItemsCategoryComponent {
  @Input() title: string;
}
