import { MerchantsPageTranslate } from '../../models/merchants-translate';

const MERCHANT_TRANSLATE: MerchantsPageTranslate = {
  MERCHANTS: {
    TITLE: 'Comercios',
    DESCRIPTION: '',
    FILTERS: {
      TITLE: 'Herramientas de búsqueda',
      DESCRIPTION:
        'Filtre su base de datos de crédito utilizando filtros predeterminados y avanzados o utilice la búsqueda de texto libre para campos específicos.',
      SEARCH_BAR: 'ID del comercio, Nombre del comercio, ID nacional.',
      STATUS: 'Estado',
      DATE_CREATED_TITLE: 'Fecha de creacion',
      DATE_CREATED_PLACEHOLDER: 'Elige un rango',
      SALES_TITLE: 'Ventas',
      SALES_LESS: 'Desde',
      SALES_GRATHER: 'hasta',
      PAYOUT_TITLE: 'Pago',
      PAYOUT_LESS: 'Desde',
      PAYOUT_GRATHER: 'hasta',
    },
    TABLE: {
      ID: 'ID del comercio',
      MERCHANT_NAME: 'Nombre del comercio',
      NATIONAL_ID: 'ID nacional',
      STATUS: 'Estado',
      SALES: 'Ventas',
      TOTAL_PAYOUT: 'Pago total',
      CREATED_AT: 'Fecha de creacion',
    },
  },
  MERCHANT_PROFILE: {
    TITLE: 'Perfil de comercio',
    DESCRIPTION:
      'Revise todos sus comercios actuales. Filtre y descargue esta base de datos para navegar y analizar rápidamente su base de comercios.',
    INFO: {
      MERCHANT_ID: 'ID del comercio',
      CREATED_AT: 'Fecha de creacion',
    },
    SUMMARY: {
      TITLE: 'Resumen',
      TOTAL_SALES: 'Ventas totales',
      TOTAL_NUMBER_OF_SALES: '# total de ventas',
      AVERAGE_TICKET: 'Boleto promedio',
      AVERAGE_NUMBER_PURCHASES_CUSTOMERS: 'Promedio # de compras / Cliente',
      MONTH: 'Mes',
      LAST_MONTH: 'Ultimo mes',
    },
    DETAILS: {
      TITLE: 'Detalles del comercio',
      MERCHANT_NAME: 'Nombre del comercio',
      TYPE_OF_ID: 'Tipo de ID',
      ID_NUMBER: 'número de ID',
      ADDRESS: 'Dirección',
      ONLINE_STORE: 'Tienda en línea',
      CONTACT_NUMBER: 'Número de contacto',
      CONTACT_EMAIL: 'Correo de contacto',
      TYPE_OF_SERVICE: 'Tipo de servicio BNPL',
    },
    PURCHASES: {
      TITLE: 'Compras recientes',
      DESCRIPTION:
        'Ver las compras recientes de este comercio. Revisa rápidamente el monto, fecha de compra.',
      TABLE: {
        PURCHASE_ID: 'ID de compra',
        CREDIT_ID: 'ID de crédito',
        AMOUNT: 'Monto',
        DATE_OF_PURCHASE: 'Fecha de compra',
      },
      BTN_VISIT_PURCHASES: 'Visitar todas las compras',
      BTN_VISIT_CREDITS: 'Visitar créditos',
    },
    MOVEMENTS: {
      TITLE: 'Movimientos recientes',
      DESCRIPTION:
        'Ver los movimientos recientes de este comercio. Revisa rápidamente el concepto, monto y fecha de compra.',
      TABLE: {
        MOVEMENT_ID: 'ID de movimiento',
        CONCEPT: 'Concepto',
        AMOUNT: 'Monto',
        DATE_OF_MOVEMENT: 'Fecha de movimiento',
      },
      BTN_VISIT_TRANSACTION: 'Visitar transacción',
    },
    GROUP_AND_OFFERS: {
      TITLE: 'Grupo y ofertas',
      DESCRIPTION:
        'Ver las ofertas asociadas a este comercio con sus respectivas comisiones.',
      BTN_VISIT_GROUPS: 'Visitar grupos',
    },
  },
};
export default MERCHANT_TRANSLATE;
