import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { APP_CONSTANTS } from '@app/core/constants/app.constants';
import { AppFacade } from '@app/facade/app/app.facade';
import { filter } from 'rxjs';

@Pipe({
  name: 'MODate',
})
export class MODatePipe implements PipeTransform {
  private _language: string;

  constructor(private _appFacade: AppFacade) {
    this._appFacade.language$
      .pipe(filter((lang) => !!lang))
      .subscribe((lang) => (this._language = lang));
  }

  transform(value: any, timezone?: string, ...args: any[]): any {
    if (!value) return '';
    const format =
      args && args.length > 0
        ? args.shift()
        : APP_CONSTANTS.DEFAULT_DATE_FORMAT;
    const date = formatDate(value, format, this._language, timezone);
    return this._titleCase(date);
  }

  private _titleCase(value: any) {
    return value.replace(/\w\S*/g, (txt: any) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}
