import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'mo-radio',
  templateUrl: './mo-radio.component.html',
  styleUrls: ['./mo-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MoRadioComponent),
      multi: true,
    },
  ],
})
export class MoRadioComponent implements ControlValueAccessor {
  @Input() id!: string;
  @Input() label?: string;
  @Input() name?: string;
  @Input() value?: any;

  public valueModel?: string;
  public isDisabled?: boolean;
  private _onChange: Function = () => {};
  private _onTouch: Function = () => {};

  writeValue(value: string): void {
    this.valueModel = value;
  }

  registerOnChange(fn: Function): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this._onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChange() {
    this._onTouch(this.value);
    this._onChange(this.value);
  }
}
