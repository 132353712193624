export interface Token {
  access_token: string;
  token_type?: 'Bearer' | 'Token';
}

export enum CurrencyTypes {
  COP = 'COP',
}

export type Currency = CurrencyTypes.COP;
