import { PaymentsPageTranslate } from '../../models/payments-translate';

const PAYMENTS_TRANSLATE: PaymentsPageTranslate = {
  PAYMENTS: {
    TITLE: 'Payments',
    DESCRIPTION: '',
    FILTERS: {
      TITLE: 'Search tools',
      DESCRIPTION:
        'Filter your credit database by using default and advanced filters or use <br />free text search for specific fields.',
      SEARCH_BAR: 'Payment ID, Customer ID, Credit  ID',
      AMOUNT_TITLE: 'Payment amount',
      AMOUNT_LESS: 'From',
      AMOUNT_GRATHER: 'to',
      STATUS: 'Status',
      DATE_OF_PAYMENT_TITLE: 'Date of payment',
      DATE_OF_PAYMENT_PLACEHOLDER: 'Choose a range',
    },
    TABLE: {
      ID: 'Payment ID',
      LOAN_ID: 'Credit  ID',
      CUSTOMER_ID: 'Customer ID',
      STATUS: 'Status',
      AMOUNT: 'Amount',
      TYPE: 'Method',
      PAID_AT: 'Date',
    },
  },
};
export default PAYMENTS_TRANSLATE;
