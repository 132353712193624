export const CUSTOMER_FEATURE = {
  NAME: 'Customers',
  PATH: 'customers',
  PAGES: {
    OVERVIEW: {
      NAME: 'Overview',
      PATH: 'overview',
    },
    PRE_CUSTOMERS: {
      NAME: 'Pre-customers',
      PATH: 'all-pre-customers',
      STORE_NAME: 'preCustomers',
      SUB_PAGES: {
        PRE_CUSTOMERS_PROFILE: {
          NAME: 'Pre-customer profile',
          PATH: 'pre-customer-profile',
        },
      },
    },
    CUSTOMERS: {
      NAME: 'Customers',
      PATH: 'all-customers',
      STORE_NAME: 'customers',
      SUB_PAGES: {
        CUSTOMERS_PROFILE: {
          NAME: 'Customer profile',
          PATH: 'customer-profile',
        },
      },
    },
  },
};

export const LOAN_MANAGER_FEATURE = {
  NAME: 'Credit Manager',
  PATH: 'credit-manager',
  PAGES: {
    LOANS: {
      NAME: 'Credits',
      PATH: 'credits',
      STORE_NAME: 'loans',
      SUB_PAGES: {
        LOAN_PROFILE: {
          NAME: 'Credits profile',
          PATH: 'credit-profile',
        },
      },
    },
    PAYMENTS: {
      NAME: 'Payments',
      PATH: 'payments',
      STORE_NAME: 'payments',
    },
  },
};
