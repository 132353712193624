import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Project } from '@app/core/models/project.model';
import { API_URLS } from '@app/data/constants/api.constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectApi {
  constructor(private _http: HttpClient) {}

  public getProject(): Observable<Project> {
    return this._http.get<Project>(API_URLS.PROJECT.GET_PROJECT);
  }
}
