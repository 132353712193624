import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  BUTTON_DESIGN_CLASS,
  BUTTON_TYPE,
  COLOR_TEMPLATE,
  ICON_POSITION,
  SIZES,
} from '../../enums/fields.type';
import { MoLoadingComponent } from '../mo-loading/mo-loading.component';

@Component({
  selector: 'mo-button',
  templateUrl: './mo-button.component.html',
  styleUrls: ['./mo-button.component.scss'],
  imports: [CommonModule, MoLoadingComponent],
  standalone: true,
})
export class MoButtonComponent {
  @Input() text!: string;
  @Input() isLoading!: boolean;
  @Input() isDisabled!: boolean;
  @Input() icon!: string;
  @Input() iconPosition!: ICON_POSITION;
  @Input() designClass!: BUTTON_DESIGN_CLASS;
  @Input() size!: SIZES;
  @Input() type: BUTTON_TYPE = BUTTON_TYPE.BUTTON;
  @Input() displayBlock!: boolean;

  public COLOR_TEMPLATES = COLOR_TEMPLATE;

  public ICON_POSITIONS = ICON_POSITION;
}
