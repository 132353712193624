import { Component, Input } from '@angular/core';
import { Skeleton } from '@app/core/models/skeleton.model';

@Component({
  selector: 'layout-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
})
export class SkeletonComponent {
  @Input() skeleton: Skeleton;

  constructor() {}
}
