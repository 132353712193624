import { MenuItem, MENU_ITEM_TYPE } from '@app/core/models/menu.model';
import { MENU_CONFIG_DISABLED } from '@instance-config/menu.config';
import { REPORTS_FEATURE } from './feature.constants';
import { REPORTS_URLS } from './urls.constants';

export const REPORTS_MENU: MenuItem[] = [
  {
    type: MENU_ITEM_TYPE.ITEM,
    icon: 'app/presentation/assets/img/icons/menu/reports.svg',
    label: REPORTS_FEATURE.NAME,
    path: REPORTS_URLS.REPORTS,
    disabled: MENU_CONFIG_DISABLED.REPORTS.MODULE ?? false,
  },
].filter(item => !item.disabled);;
