export interface Score {
  id?: string;
  pre_approved_amount?: number;
  pre_approved_at?: Date;
  score?: number;
  product_id?: string;
  product_name?: string;
  product_reference?: string;
  customer_id?: string;
  display_name?: string;
  created_at?: Date;
  customer_category?: CUSTOMER_CATEGORY;
  others?: object | null;
}

export enum CUSTOMER_CATEGORY {
  PRE_CUSTOMER = 1,
  ONBOARDING = 2,
  CUSTOMER = 3,
}
