import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CARD_CONTAINER_DESIGN_CLASS } from '../../enums/layout.enum';

@Component({
  selector: 'layout-order-card',
  templateUrl: './order-card.component.html',
  styleUrls: ['./order-card.component.scss'],
})
export class OrderCardComponent {
  @Input() title: string;
  @Input() label: string = 'Priority';
  @Input() max: number = 1;
  @Input() min: number = 1;
  @Input() value: number = 1;
  @Input() canDelete: boolean = true;
  @Output() updateValue: EventEmitter<number> = new EventEmitter<number>();
  @Output() delete: EventEmitter<void> = new EventEmitter<void>();

  public CARD_CONTAINER_DESIGN_CLASS = CARD_CONTAINER_DESIGN_CLASS;

  public onIncrease() {
    if (this.value >= this.max) return;
    this.value = ++this.value;
    this._emitUpdateValue();
  }

  public onDiminish() {
    if (this.value <= this.min) return;
    this.value = --this.value;
    this._emitUpdateValue();
  }

  public onDelete() {
    this.delete.emit();
  }

  private _emitUpdateValue() {
    this.updateValue.emit(this.value);
  }
}
