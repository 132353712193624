import { MenuItem } from '@app/core/models/menu.model';
import { CUSTOMERS_MENU } from '../modules/customers/core/constants/menus.constants';
import { LOAN_MANAGER_MENU } from '../modules/loan-manager/core/constants/menus.constants';
import { MERCHANT_MANAGER_MENU } from '../modules/merchant-manager/core/constants/menus.constants';
import { PRODUCT_CATALOGUE_MENU } from '../modules/product-catalogue/core/constants/menu.constants';
import { REPORTS_MENU } from '../modules/reports/core/constants/menus.constants';

export const PLUGINS_MENU: MenuItem[] = [
  ...LOAN_MANAGER_MENU,
  ...CUSTOMERS_MENU,
  ...MERCHANT_MANAGER_MENU,
  ...PRODUCT_CATALOGUE_MENU,
  ...REPORTS_MENU,
];
