import { ProductsAndOffersPageTranslate } from '../../models/products_and_offers-translate';

const PRODUCTS_AND_OFFERS_TRANSLATE: ProductsAndOffersPageTranslate = {
  PRODUCTS_AND_OFFERS: {
    TITLE: 'Productos y ofertas',
    DESCRIPTION:
      'Administre los productos y ofertas de su cartera de crédito, mientras supervisa el <br />desempeño de su operación crediticia.',
    PRODUCTS_COUNTER: 'Productos',
    OFFERS_COUNTER: 'Ofertas',
    PRODUCTS: {
      TITLE: 'Mapa de productos y ofertas',
      DESCRIPTION:
        'Vea, edite o cree nuevos productos y ofertas para <br />agregar a su cartera de crédito.',
      BTN_CREATE_NEW_PRODUCT: 'Crear nuevo producto',
      PRODUCT_CARD: {
        EMPTY:
          'Por el momento no tienes ofertas creadas, puedes crear una oferta y lo verás en este lugar',
        OFFERS_COUNTER: 'Ofertas',
        PRODUCT_ID: 'ID de producto',
        BTN_VIEW_MORE: 'Ver más',
        BTN_CREATE_NEW_OFFER: 'Crear nueva oferta',
        BTN_VIEW_PRODUCT_DETAILS: 'Ver detalle del producto',
      },
    },
  },
  PRODUCTS_PROFILE: {
    TITLE: 'Perfil del producto',
    DESCRIPTION:
      'Revise la información básica de este producto de crédito y las ofertas asociadas a este <br />producto. Realice un seguimiento del rendimiento de este producto dentro de su operación de crédito con <br />algunas métricas y visualizaciones clave.',
    INFO: {
      PRODUCT_ID: 'ID de producto',
      OFFERS_COUNTER: 'Ofertas',
      PRODUCTS: 'Productos',
      CREATED_AT: 'Fecha de creación',
      UPDATED_AT: 'Fecha de modifición',
    },
    OFFERS: {
      TITLE: 'Ofertas',
      DESCRIPTION:
        'Ver todas las ofertas de crédito asociadas con este producto. <br />Edite, archive o cree rápidamente nuevas ofertas para su producto de crédito.',
      BTN_CREATE_NEW_OFFER: 'Crear nueva oferta',
      EMPTY: 'No hay ninguna oferta asociada <br />a este producto',
      TABLE: {
        OFFER_NAME: 'Nombre de la oferta',
        OFFER_REFERENCE: 'ID de oferta',
        CREATED_AT: 'Fecha de creación',
        UPDATED_AT: 'Fecha de modifición',
        STATUS: 'Estado',
      },
    },
    DETAILS: {
      TITLE: 'Configuración general',
      MAXIMUM_NUMBER_OF_ACTIVE_LOANS: 'Número máximo de créditos activos',
      LOAN_AMOUNTS: 'Montos de créditos',
      CLOSING_TOLERANCE: 'Tolerancia de cierre',
      DECIMALS: 'Decimales',
      AMOUNT_REQUESTED_STEP: 'Monto solicitado paso',
      NEAREST_MULTIPLE_OF: 'Múltiplo más cercano de',
    },
  },
  OFFER_PROFILE: {
    TITLE: 'Perfil de oferta',
    DESCRIPTION:
      'Revise todas las configuraciones específicas y la información básica para esta oferta. Edite <br />campos específicos o archive esta oferta.',
    INFO: {
      OFFER_ID: 'ID de la oferta:',
      CREATED_AT: 'Fecha de creacion:',
      UPDATED_AT: 'Fecha de modifición:',
    },
    GENERAL_SETTINGS: {
      TITLE: 'Configuración general',
      MAXIMUM_ACTIVE_CREDITS: 'Número máximo de créditos activos:',
    },
    REPAYMENT_LOGIC: {
      TITLE: 'Lógicas de reembolso',
      TYPE: 'Tipo de reembolso:',
      PERIODICITY: 'Periodicidad:',
      CALENDAR_LOGIC: 'Lógica del calendario:',
      FIXED_OR_RELATIVE: 'Fijo o relativo:',
      FIXED_OR_RELATIVE_DAY_OF_WEEK: 'Día de la semana:',
      NUMBER_OF_INSTALLMENTS: 'Número de coutas:',
      TERM_OF_CREDIT: 'Plazo de crédito',
      TYPE_OF_INSTALLMENT: 'Tipo de cuota:',
      MISSED_REPAYMENT_LOGIC: 'Lógica de reembolso perdido:',
      GRACE_PERIOD: 'Periodo de gracia:',
      GRACE_PERIOD_NUMBER_OF_DAYS: 'Número de días:',
    },
    CHARGES: {
      TITLE: 'Cargos por orden de prioridad de cobro',
      CAPITAL: { TITLE: 'Capital', DOWN_PAYMENT: 'Depósito:' },
      INTEREST: {
        TITLE: 'Interés',
        VALUE: 'Valor de interés:',
        BASE: 'Base:',
        TAX: 'Impuesto:',
        TAX_FEE: 'Tarifa de impuestos:',
        PERIODICITY: 'Periodicidad:',
        GRACE_PERIOD: 'Periodo de gracia:',
        GRACE_PERIOD_NUMBER_OF_DAYS: 'Número de días:',
      },
      COSTS: {
        TITLE: 'Costos',
        PLATFORM: 'Plataforma',
        ADMINISTRATIVE: 'Administrativo',
        FIXED_OR_PERCENT: 'Fijo o por ciento',
        FIXED_OR_PERCENT_PERCENTAGE: 'Porcentaje:',
        TAX: 'Impuesto:',
        TAX_FEE: 'Tarifa de impuestos:',
      },
      COLLECTION_COSTS: {
        TITLE: 'Costes de recaudo',
        TRIGGER: 'Disparador',
        GRACE_PERIOD: 'Periodo de gracia:',
        GRACE_PERIOD_TIME_UNIT: 'Unidad de tiempo:',
        GRACE_PERIOD_PERCENTAGE: 'Porcentaje:',
        FIXED_OR_PERCENT: 'Fijo o por ciento',
        FIXED_OR_PERCENT_PERCENTAGE: 'Porcentaje:',
        FIXED_OR_PERCENT_BASE: 'Base:',
        TAX: 'Impuesto:',
        TAX_FEE: 'Tarifa de impuestos:',
      },
      DEFAULT_INTEREST: {
        TITLE: 'Interés por defecto',
        TRIGGER: 'Disparador',
        INTEREST_VALUE: 'Valor de interés:',
        BASE: 'Base:',
        TAX: 'Impuesto:',
        TAX_FEE: 'Tarifa de impuestos:',
        PERIODICITY: 'Periodicidad:',
        FIXED_OR_RELATIVE: 'Fijo o relativo:',
        GRACE_PERIOD: 'Periodo de gracia:',
        GRACE_PERIOD_NUMBER_OF_DAYS: 'Número de días:',
      },
    },
  },
};

export default PRODUCTS_AND_OFFERS_TRANSLATE;
