<div class="stepper">
  <layout-step
    *ngFor="let step of steps; let i = index"
    [label]="step.key"
    [status]="getState(i)"
    [path]="step.value"
  ></layout-step>
</div>

<router-outlet></router-outlet>
