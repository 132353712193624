import { FeatureDetailsTranslate } from '@app/core/i18n/models/feature-details-translate';
import CUSTOMERS_TRANSLATE from './customers.translate';
import OVERVIEW_TRANSLATE from './overview.translate';
import PRE_CUSTOMERS_TRANSLATE from './pre-customers.translate';

const SPANISH_DEFAULT_CUSTOMERS: FeatureDetailsTranslate = {
  _NAME: 'Clientes',
  _MENU: {
    OVERVIEW: OVERVIEW_TRANSLATE.OVERVIEW.TITLE,
    PRE_CUSTOMERS: PRE_CUSTOMERS_TRANSLATE.PRE_CUSTOMERS.TITLE,
    CUSTOMERS: CUSTOMERS_TRANSLATE.CUSTOMERS.TITLE,
  },
  ...OVERVIEW_TRANSLATE,
  ...PRE_CUSTOMERS_TRANSLATE,
  ...CUSTOMERS_TRANSLATE,
};
export default SPANISH_DEFAULT_CUSTOMERS;
