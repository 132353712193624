import { environment } from 'src/environments/environment';

export function AppInitializer() {
  const _gaKey = environment.ga_key;
  const _gtagId = environment.gtag_id;
  const _hotjarId = environment.hotjar_id;
  const _hotjarVersion = environment.hotjar_version;
  const _pixelId = environment.pixel_id;

  return async () => {
    // Hotjar
    if (_hotjarId) {
      (function (h: any, o: any, t: any, j: any, a?: any, r?: any) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: _hotjarId, hjsv: _hotjarVersion || 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    } else {
      console.warn('SIN INFORMACIÓN EN HOTJAR');
    }

    // Google TagManager
    if (_gtagId) {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j['async'] = true;
        j['src'] = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', _gtagId);

      const ifrm = document.createElement('iframe');
      ifrm.setAttribute(
        'src',
        'https://www.googletagmanager.com/ns.html?id=' + _gtagId
      );
      ifrm.style.width = '0';
      ifrm.style.height = '0';
      ifrm.style.display = 'none';
      ifrm.style.visibility = 'hidden';

      const noscript = document.createElement('noscript');
      noscript.id = 'GTMiframe';
      noscript.appendChild(ifrm);

      document.body.insertBefore(noscript, document.body.firstChild);
    } else {
      console.warn('SIN INFORMACIÓN EN GOOGLE TAGMANAGER');
    }

    // Google Analytics
    if (_gaKey) {
      (function (w: any, d, s, l, i, n) {
        w[l] = w[l] || [];
        w[n] =
          w[n] ||
          function () {
            w[l].push(arguments);
          };
        w[n]('js', new Date());
        w[n]('config', _gaKey);
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j['async'] = true;
        j['src'] = 'https://www.googletagmanager.com/gtag/js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', _gaKey, 'gtag');
    } else {
      console.warn('SIN INFORMACIÓN DE GOOGLE ANALYTICS');
    }

    // Pixel - FB
    if (_pixelId) {
      (function (f: any, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js'
      );
      (window as any).fbq('init', _pixelId);
      (window as any).fbq('track', 'PageView');

      const img = document.createElement('img');
      img.setAttribute(
        'src',
        'https://www.facebook.com/tr?id=' + _pixelId + '&ev=PageView&noscript=1'
      );
      img.style.width = '0';
      img.style.height = '0';
      img.style.display = 'none';
      img.style.visibility = 'hidden';

      const noscript = document.createElement('noscript');
      noscript.id = 'FBPixeliframe';
      noscript.appendChild(img);

      document.body.insertBefore(noscript, document.body.firstChild);
    } else {
      console.warn('SIN INFORMACIÓN EN FACEBOOK PIXEL');
    }
  };
}
