import { CUSTOMERS_ROUTES } from '../modules/customers/core/constants/routes.constants';
import { LOAN_MANAGER_ROUTES } from '../modules/loan-manager/core/constants/routes.constants';
import { MERCHANT_MANAGER_ROUTES } from '../modules/merchant-manager/core/constants/routes.constants';
import { PRODUCT_CATALOGUE_ROUTES } from '../modules/product-catalogue/core/constants/routes.constants';
import { REPORTS_ROUTES } from '../modules/reports/core/constants/routes.constant';

export const PLUGINS_ROUTES = [
  ...CUSTOMERS_ROUTES,
  ...LOAN_MANAGER_ROUTES,
  ...PRODUCT_CATALOGUE_ROUTES,
  ...MERCHANT_MANAGER_ROUTES,
  ...REPORTS_ROUTES,
];
