export const MENU_CONFIG_DISABLED = {
  // BASE
  MY_ACCOUNT: {
    MODULE: false,
  },
  CREDIT_SCORE: {
    MODULE: true,
  },
  SETTINGS: {
    MODULE: true,
  },
  NOTIFICATION_CENTER: {
    MODULE: true,
  },
  NEED_HELP: {
    ITEM: true,
  },
  FAQ: {
    MODULE: true,
  },
  HELP_CENTER: {
    MODULE: true,
  },
  NEED_SUPPORT: {
    MODULE: true,
  },
  // PLUGINS
  LOAN_MANAGER: {
    MODULE: false,
    OVERVIEW: true,
    LOANS_REQUEST: false,
    LOANS: false,
    PAYMENTS: false,
  },
  CUSTOMER: {
    MODULE: false,
    OVERVIEW: true,
    PRE_CUSTOMERS: false,
    CUSTOMERS: false,
  },
  MERCHANT_MANAGER: {
    MODULE: false,
    OVERVIEW: true,
    MERCHANTS: false,
    PURCHASES: true,
    MERCHANT_GROUPS: false,
  },
  PRODUCT_CATALOGUE: {
    MODULE: false,
    PRODUCTS_AND_OFFERS: false,
  },
  REPORTS: {
    MODULE: true,
  },
};
