import { Component, Input } from '@angular/core';
import { SETTINGS_ITEM_DESIGN_CLASS } from '../../enums/layout.enum';

@Component({
  selector: 'layout-setting-item',
  templateUrl: './setting-item.component.html',
  styleUrls: ['./setting-item.component.scss'],
})
export class SettingItemComponent {
  @Input() title: string;
  @Input() description?: string;
  @Input() designClass?: SETTINGS_ITEM_DESIGN_CLASS =
    SETTINGS_ITEM_DESIGN_CLASS.DEFAULT;
}
