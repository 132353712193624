<div class="base">
  <layout-menu-sidebar
    class="sidebar"
    typeSidebar="FIXED"
    [menu]="menu$ | async"
  >
    <layout-navbar
      class="navbar"
      (logout)="onLogout()"
      [user]="user$ | async"
    ></layout-navbar>
    <div class="wrapper">
      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </layout-menu-sidebar>
</div>
