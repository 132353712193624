import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionItemComponent } from './components/accordion-item/accordion-item.component';
import { PrimaryItemComponent } from './components/primary-item/primary-item.component';
import { SubMenuItemComponent } from './components/sub-menu/item/item.component';
import { SubMenuListComponent } from './components/sub-menu/list/list.component';
import { SubMenuTitleComponent } from './components/sub-menu/title/title.component';
import { TitleComponent } from './components/title/title.component';
import { MenuSidebarComponent } from './menu-sidebar.component';

const COMPONENTS = [
  MenuSidebarComponent,
  PrimaryItemComponent,
  TitleComponent,
  AccordionItemComponent,
  SubMenuItemComponent,
  SubMenuListComponent,
  SubMenuTitleComponent,
];

@NgModule({
  declarations: COMPONENTS,
  exports: COMPONENTS,
  imports: [CommonModule, TranslateModule],
})
export class MenuSidebarModule {}
