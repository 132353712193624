import { HttpClient } from '@angular/common/http';
import { PRODUCT_FAMILY } from '@app/core/constants/product.constants';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import ENGLISH_BNPL from '../lang-bnpl/en';
import SPANISH_BNPL from '../lang-bnpl/es';
import ENGLISH_CREDIT_CARD from '../lang-credit-card/en';
import SPANISH_CREDIT_CARD from '../lang-credit-card/es';
import ENGLISH_DEFAULT from '../lang-default/en';
import SPANISH_DEFAULT from '../lang-default/es';
import ENGLISH_MCA from '../lang-mca/en';
import SPANISH_MCA from '../lang-mca/es';
import ENGLISH_MICRO_CREDIT from '../lang-micro-credit/en';
import SPANISH_MICRO_CREDIT from '../lang-micro-credit/es';

export const LANGUAGE_PATH = './app/core/i18n/';

export const LANGUAGE_SEPARATOR = '/';
export const LANGUAGE_SUFFIX = '.ts';
export const DEFAULT_FAMILY = 'DEFAULT';

export enum LANGUAGES {
  ES = 'es',
  EN = 'en',
}

export const DEFAULT_LANGUAGE = LANGUAGES.EN;
export const LANGUAGES_LIST = [LANGUAGES.EN, LANGUAGES.ES];

export const LANGUAGES_BY_PRODUCT_FAMILY = {
  [DEFAULT_FAMILY]: {
    [LANGUAGES.ES]: `${DEFAULT_FAMILY}${LANGUAGE_SEPARATOR}${LANGUAGES.ES}`,
    [LANGUAGES.EN]: `${DEFAULT_FAMILY}${LANGUAGE_SEPARATOR}${LANGUAGES.EN}`,
  },
  [PRODUCT_FAMILY.BNPL]: {
    [LANGUAGES.ES]: `${PRODUCT_FAMILY.BNPL}${LANGUAGE_SEPARATOR}${LANGUAGES.ES}`,
    [LANGUAGES.EN]: `${PRODUCT_FAMILY.BNPL}${LANGUAGE_SEPARATOR}${LANGUAGES.EN}`,
  },
  [PRODUCT_FAMILY.MCA]: {
    [LANGUAGES.ES]: `${PRODUCT_FAMILY.MCA}${LANGUAGE_SEPARATOR}${LANGUAGES.ES}`,
    [LANGUAGES.EN]: `${PRODUCT_FAMILY.MCA}${LANGUAGE_SEPARATOR}${LANGUAGES.EN}`,
  },
  [PRODUCT_FAMILY.CREDIT_CARD]: {
    [LANGUAGES.ES]: `${PRODUCT_FAMILY.CREDIT_CARD}${LANGUAGE_SEPARATOR}${LANGUAGES.ES}`,
    [LANGUAGES.EN]: `${PRODUCT_FAMILY.CREDIT_CARD}${LANGUAGE_SEPARATOR}${LANGUAGES.EN}`,
  },
  [PRODUCT_FAMILY.MICRO_CREDIT]: {
    [LANGUAGES.ES]: `${PRODUCT_FAMILY.MICRO_CREDIT}${LANGUAGE_SEPARATOR}${LANGUAGES.ES}`,
    [LANGUAGES.EN]: `${PRODUCT_FAMILY.MICRO_CREDIT}${LANGUAGE_SEPARATOR}${LANGUAGES.EN}`,
  },
};

export const TRANSLATION_BY_PRODUCT_FAMILY = {
  [DEFAULT_FAMILY]: {
    [LANGUAGES.ES]: SPANISH_DEFAULT,
    [LANGUAGES.EN]: ENGLISH_DEFAULT,
  },
  [PRODUCT_FAMILY.BNPL]: {
    [LANGUAGES.ES]: SPANISH_BNPL,
    [LANGUAGES.EN]: ENGLISH_BNPL,
  },
  [PRODUCT_FAMILY.MCA]: {
    [LANGUAGES.ES]: SPANISH_MCA,
    [LANGUAGES.EN]: ENGLISH_MCA,
  },
  [PRODUCT_FAMILY.CREDIT_CARD]: {
    [LANGUAGES.ES]: SPANISH_CREDIT_CARD,
    [LANGUAGES.EN]: ENGLISH_CREDIT_CARD,
  },
  [PRODUCT_FAMILY.MICRO_CREDIT]: {
    [LANGUAGES.ES]: SPANISH_MICRO_CREDIT,
    [LANGUAGES.EN]: ENGLISH_MICRO_CREDIT,
  },
};

export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient, LANGUAGE_PATH, LANGUAGE_SUFFIX);
}
