import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APP_CONSTANTS } from '@app/core/constants/app.constants';
import { AuthApi } from '@app/data/api/auth/auth.api';
import { AuthService } from '@app/domain/services/auth/auth.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import * as authActions from './auth.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private _actions$: Actions,
    private _authApi: AuthApi,
    private _router: Router,
    private _authService: AuthService
  ) {}

  login$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.login),
      switchMap(({ credencials }) =>
        this._authApi.login(credencials).pipe(
          map((token) => authActions.successLogin({ token })),
          catchError((_) => of(authActions.failedLogin()))
        )
      )
    )
  );

  successLogin$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(authActions.successLogin),
        tap(({ token }) => {
          this._authService.updateSessionToken(token);
          this._router.navigateByUrl(APP_CONSTANTS.ROUTES.MY_ACCOUNT);
        })
      ),
    { dispatch: false }
  );

  refreshToken$ = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.refreshToken),
      switchMap(({ refreshToken }) =>
        this._authApi.refreshToken(refreshToken).pipe(
          map((token) => authActions.successRefreshToken({ token })),
          catchError((_) => of(authActions.failedRefreshToken()))
        )
      )
    )
  );

  logout$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(authActions.logout),
        tap(() => {
          this._authService.logout();
          this._router.navigateByUrl(APP_CONSTANTS.ROUTES.AUTH.LOGIN);
        })
      ),
    { dispatch: false }
  );
}
