import { PurchasesPageTranslate } from '../../models/purchases-translate';

const PURCHASES_TRANSLATE: PurchasesPageTranslate = {
  PURCHASES: {
    TITLE: 'Purchases',
    DESCRIPTION: '',
    FILTERS: {
      TITLE: 'Search tools',
      DESCRIPTION:
        'Filter your credit database by using default and advanced filters or use free text search for specific fields.',
      SEARCH_BAR: 'Purchase ID, Merchant ID, Ticket',
      DATE_CREATED_TITLE: 'Date created',
      DATE_CREATED_PLACEHOLDER: 'Choose a range',
      TICKETS_TITLE: 'Tickets',
      TICKETS_LESS: 'From',
      TICKETS_GRATHER: 'to',
      DOWN_PAYMENT_TITLE: 'Down-payment',
      DOWN_PAYMENT_LESS: 'From',
      DOWN_PAYMENT_GRATHER: 'to',
    },
    TABLE: {
      ID: 'Purchase ID',
      MERCHANT_ID: 'Merchant ID',
      TICKET: 'Ticket',
      DOWN_PAYMENT: 'Down-payment',
      CREDIT_ID: 'Credit ID',
      STATUS: 'Status',
      CREATED_AT: 'Date created',
    },
  },
};
export default PURCHASES_TRANSLATE;
