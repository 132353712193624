import { Action, createReducer, on } from '@ngrx/store';
import * as userActions from '@store/user/user.actions';
import { UserState } from './user.state';

export const initialUserState: UserState = {
  data: null,
};

const _userReducer = createReducer(
  initialUserState,

  on(userActions.successGetUserInfo, (state, { data }) => ({
    ...state,
    data,
  }))
);

export function UserReducers(state: UserState | undefined, action: Action) {
  return _userReducer(state, action);
}
