import {
  Purchase,
  PURCHASE_STATUS,
} from '@app/plugins/modules/merchant-manager/core/models/purchase.model';
import { TableResponse } from '@app/presentation/layout/mo-tables/interfaces/table.interface';

export const PURCHASES: TableResponse<Purchase> = {
  filtered: 500,
  count: 3,
  results: [
    {
      id: '123123-asdasd-123123',
      reference: '0001',
      merchant_reference: '1234567890',
      ticket: 1000,
      down_payment: 2000,
      credit_reference: '12312PASD',
      status: PURCHASE_STATUS.ACTIVE,
      created_at: new Date('2019-10-01T15:59:15-05:00'),
    },
    {
      id: '123123-asdasd-123123',
      reference: '0002',
      merchant_reference: '1234567890',
      ticket: 21000,
      down_payment: 32000,
      credit_reference: '12312PASD',
      status: PURCHASE_STATUS.CANCELED,
      created_at: new Date('2019-10-01T15:59:15-05:00'),
    },
    {
      id: '123123-asdasd-123123',
      reference: '0003',
      merchant_reference: '1234567890',
      ticket: 41000,
      down_payment: 52000,
      credit_reference: '12312PASD',
      status: PURCHASE_STATUS.DISPUTE,
      created_at: new Date('2019-10-01T15:59:15-05:00'),
    },
  ],
};
