import { PurchasesPageTranslate } from '../../models/purchases-translate';

const PURCHASES_TRANSLATE: PurchasesPageTranslate = {
  PURCHASES: {
    TITLE: 'Compras',
    DESCRIPTION: '',
    FILTERS: {
      TITLE: 'Herramientas de búsqueda',
      DESCRIPTION:
        'Filtre su base de datos de crédito utilizando filtros predeterminados y avanzados o utilice la búsqueda de texto libre para campos específicos.',
      SEARCH_BAR: 'ID de compra, ID de comercio, Ticket',
      DATE_CREATED_TITLE: 'Fecha de creacion',
      DATE_CREATED_PLACEHOLDER: 'Elige un rango',
      TICKETS_TITLE: 'Tickets',
      TICKETS_LESS: 'Desde',
      TICKETS_GRATHER: 'hasta',
      DOWN_PAYMENT_TITLE: 'Depósito',
      DOWN_PAYMENT_LESS: 'Desde',
      DOWN_PAYMENT_GRATHER: 'hasta',
    },
    TABLE: {
      ID: 'ID de compra',
      MERCHANT_ID: 'ID de comercio',
      TICKET: 'Ticket',
      DOWN_PAYMENT: 'Depósito',
      CREDIT_ID: 'ID de crédito',
      STATUS: 'Estado',
      CREATED_AT: 'Fecha de creacion',
    },
  },
};
export default PURCHASES_TRANSLATE;
