import { CustomerPageTranslate } from '../../models/customer-translate';

const CUSTOMERS_TRANSLATE: CustomerPageTranslate = {
  CUSTOMERS: {
    TITLE: 'Clientes',
    DESCRIPTION:
      'Revisa todos tus clientes actuales. Filtre y descargue esta base de datos para navegar y analizar <br />rápidamente su base de clientes.',
    FILTERS: {
      TITLE: 'Herramientas de búsqueda',
      DESCRIPTION:
        'Filtre su base de datos de clientes utilizando nuestros filtros de búsqueda <br/> para campos específicos.',
      SEARCH_BAR: 'Nombre del cliente, ID o DNI, Correo o teléfono',
      NUMBER_OF_ACTIVE_LOANS: '# de créditos activos',
      CUSTOMER_STATUS: 'Estado del cliente',
      CREATED_AT: 'Fecha de creación',
      UPDATED_AT: 'Fecha de modifición',
      SELECT_PRODUCT: 'Seleccionar producto',
    },

    TABLE: {
      REFERENCE: 'ID de Cliente',
      NAME: 'Nombre',
      NUMBER_OF_ACTIVE_LOANS: 'Créditos activos',
      EMAIL: 'Correo electrónico',
      PHONE_NUMBER: 'Número de teléfono',
      IDENTIFICATION_NUMBER: 'DNI',
      CREATED_AT: 'Fecha de creación',
      UPDATED_AT: 'Fecha de modifición',
    },
  },
  CUSTOMER_PROFILE: {
    TITLE: 'Perfil de cliente',
    DESCRIPTION:
      'Revisa la información disponible de tu cliente. Eche un vistazo más de cerca a su información personal, créditos asociados, puntajes y pagos recientes.',
    INFO: {
      CUSTOMER_ID: 'ID de Cliente',
      CREATED_AT: 'Fecha de creación',
      UPDATED_AT: 'Fecha de modifición',
    },
    DETAILS: {
      TITLE: 'Detalles del cliente',
      CONTACT: 'Contacto',
      CUSTOMER_NAME: 'Nombre del cliente',
      DOCUMENT_TYPE: 'Tipo de Documento',
      NUMBER: 'Número',
      EMAIL: 'Correo electrónico',
      PHONE_NUMBER: 'Número de teléfono',
      WEBSITE: 'Página web',
      OTHERS: 'Otro',
    },
    PRODUCTS_AND_SCORES: {
      TITLE: 'Productos y puntuaciones',
      DESCRIPTION:
        'Vea los productos asociados para este cliente, sus respectivos puntajes actuales y montos preaprobados. Vaya al historial de puntajes para obtener detalles adicionales.',
    },
    ASSOCIATED_LOANS: {
      TITLE: 'Créditos asociados',
      DESCRIPTION:
        'Ver los créditos existentes para este cliente. Vaya al perfil de crédito para obtener información adicional sobre un crédito específico para este cliente.',
      TABLE: {
        ID: 'ID de crédito',
        STATUS: 'Estado',
        LOAN_AMOUNT: 'Monto del créditos',
        CREATED_AT: 'Fecha de creacion',
      },
      BTN_VISIT_LOAN_MANAGER: 'Visitar el administrador de créditos',
    },
    RECENT_PAYMENTS: {
      TITLE: 'Pagos recientes',
      DESCRIPTION:
        'Visualice los pagos más recientes realizados por este cliente. Vea rápidamente su próximo pago para su próxima cuota de crédito.',
      TABLE: {
        REFERENCE: 'ID de pago',
        CREDIT_REFERENCE: 'ID de crédito',
        AMOUNT: 'Monto',
        PAID_AT: 'Fecha del pago',
      },
      BTN_VISIT_PAYMENTS: 'Visitar los pagos',
    },
  },
};
export default CUSTOMERS_TRANSLATE;
