export enum CUSTOMER_STATUS {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  INACTIVE = 'inactive',
  UNSIGNED = 'unsigned',
  UNREGISTERED = 'unregistered',
}

export enum LOANS_STATUS {
  ACTIVE = 'active',
  PAID = 'paid',
  FROZEN = 'frozen',
  CANCELED = 'canceled',
}
