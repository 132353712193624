import { Component, Input } from '@angular/core';
import { SUMMARY_DESIGN_CLASS } from '../../enums/layout.enum';

@Component({
  selector: 'layout-summary-sub-item',
  templateUrl: './summary-sub-item.component.html',
  styleUrls: ['./summary-sub-item.component.scss'],
})
export class SummarySubItemComponent {
  @Input() name: string;
  @Input() value: string;
  @Input() image: string = 'app/presentation/assets/img/icons/reply.svg';
  @Input() isDashed?: boolean = true;
  @Input() designClass?: SUMMARY_DESIGN_CLASS = SUMMARY_DESIGN_CLASS.DEFAULT;
}
