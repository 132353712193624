import { PAGE_SIZE_DEFAULT } from '@app/presentation/layout/mo-tables/constants/page-size.constant';
import { Filters } from '../models/filters.model';

/**
 * @description Constant for default filters
 */
export const FILTERS: Filters = {
  limit: PAGE_SIZE_DEFAULT,
  offset: 0,
};

export const BACK_FILTERS_DATE_FORMAT = 'YYYY-MM-dd';
export const BACK_DATE_FORMAT_LANGUAJE = 'en';
