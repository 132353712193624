import SPANISH_DEFAULT_CUSTOMERS from '@app/plugins/modules/customers/core/i18n/lang-default/es';
import SPANISH_DEFAULT_LOAN_MANAGER from '@app/plugins/modules/loan-manager/core/i18n/lang-default/es';
import SPANISH_DEFAULT_MERCHANT_MANAGER from '@app/plugins/modules/merchant-manager/core/i18n/lang-default/es';
import SPANISH_DEFAULT_PRODUCT_CATALOGUE from '@app/plugins/modules/product-catalogue/core/i18n/lang-default/es';

const SPANISH_PLUGINS_DEFAULT_TRANSLATE = {
  CUSTOMERS: { ...SPANISH_DEFAULT_CUSTOMERS },
  LOAN_MANAGER: { ...SPANISH_DEFAULT_LOAN_MANAGER },
  PRODUCT_CATALOGUE: { ...SPANISH_DEFAULT_PRODUCT_CATALOGUE },
  MERCHANT_MANAGER: { ...SPANISH_DEFAULT_MERCHANT_MANAGER },
};
export default SPANISH_PLUGINS_DEFAULT_TRANSLATE;
