import { ManipulateType, OpUnitType, UnitType } from 'dayjs';

export const DATE_PERIODS = {
  DAY: 'day' as OpUnitType,
  DAYS: 'days' as ManipulateType,
  WEEKS: 'weeks' as ManipulateType,
  MONTH: 'month' as ManipulateType,
  HOURS: 'hours' as UnitType,
  MINUTES: 'minutes' as UnitType,
};

export const DEFAULT_MO_DATE_FORMAT = 'ddd, DD MMM, YYYY';

export const TIMEZONES = {
  ALPHA_TIME_ZONE: 'UTC+1',
  AUSTRALIAN_CENTRAL_TIME: 'UTC+9:30/ +10:30',
  ARABIA_STANDARD_TIME: 'UTC+3',
  CENTRAL_STANDARD_TIME: 'UTC-6',
  CHINA_STANDARD_TIME: 'UTC+8',
  DELTA_TIME_ZONE: 'UTC+4',
  GREENWICH_MEAN_TIME: 'UTC+0',
  GULF_STANDARD_TIME: 'UTC+4',
  HAWAII_STANDARD_TIME: 'UTC-10',
  INDIA_STANDARD_TIME: 'UTC+4',
};
