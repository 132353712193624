import { PLUGINS_MENU } from '@app/plugins/constants/menus.constants';
import { MENU_CONFIG_DISABLED } from '@instance-config/menu.config';
import { Menu, MENU_ITEM_TYPE } from '../models/menu.model';
import { APP_CONSTANTS } from './app.constants';

// SIDE MENU
export const APP_MENU: Menu = {
  logo: 'app/presentation/assets/img/icons/menu/mo-manage.svg',
  logoCollapsed: 'app/presentation/assets/img/icons/menu/mo-manage-text.svg',
  product: 'mo-manage',
  items: [
    {
      type: MENU_ITEM_TYPE.ITEM,
      icon: 'app/presentation/assets/img/icons/menu/my-account.svg',
      label: 'APP.MENUS.SIDE_MENU.MY_ACCOUNT',
      path: APP_CONSTANTS.ROUTES.MY_ACCOUNT,
      disabled: MENU_CONFIG_DISABLED.MY_ACCOUNT.MODULE ?? false
    },
    ...PLUGINS_MENU,
    // TODO MOVE TO NEW PLUGINGS
    {
      type: MENU_ITEM_TYPE.ITEM,
      icon: 'app/presentation/assets/img/icons/menu/credit-score.svg',
      label: 'Credit Score',
      path: APP_CONSTANTS.ROUTES.CREDIT_SCORE,
      disabled: MENU_CONFIG_DISABLED.CREDIT_SCORE.MODULE ?? false
    },
    {
      type: MENU_ITEM_TYPE.ITEM,
      icon: 'app/presentation/assets/img/icons/menu/settings.svg',
      label: 'Settings',
      path: APP_CONSTANTS.ROUTES.SETTINGS,
      disabled: MENU_CONFIG_DISABLED.SETTINGS.MODULE ?? false
    },
    {
      type: MENU_ITEM_TYPE.ITEM,
      icon: 'app/presentation/assets/img/icons/menu/notification-center.svg',
      label: 'Notification Center',
      path: APP_CONSTANTS.ROUTES.NOTIFICATION_CENTER,
      disabled: MENU_CONFIG_DISABLED.NOTIFICATION_CENTER.MODULE ?? false
    },
    {
      type: MENU_ITEM_TYPE.TITLE,
      label: 'NEED HELP?',
      shortLabel: 'HELP?',
      disabled: MENU_CONFIG_DISABLED.NEED_HELP.ITEM ?? false
    },
    {
      type: MENU_ITEM_TYPE.ITEM,
      label: 'FAQ',
      path: APP_CONSTANTS.ROUTES.FAQ,
      disabled: MENU_CONFIG_DISABLED.FAQ.MODULE ?? false
    },
    {
      type: MENU_ITEM_TYPE.ITEM,
      label: 'Help Center',
      path: APP_CONSTANTS.ROUTES.HELP_CENTER,
      disabled: MENU_CONFIG_DISABLED.HELP_CENTER.MODULE ?? false
    },
    {
      type: MENU_ITEM_TYPE.ITEM,
      label: 'Need Support?',
      path: APP_CONSTANTS.ROUTES.SUPPORT,
      disabled: MENU_CONFIG_DISABLED.NEED_SUPPORT.MODULE ?? false
    },
  ].filter(menuItem => !menuItem.disabled),
};
