import { OFFER_CARD_TEMPLATE } from '@app/plugins/modules/product-catalogue/presentation/pages/products-and-offers/products-and-offers/enums/offer-card-enum';
import { Offer } from './offer.model';

export interface Product {
  id?: string;
  name?: string;
  reference?: string;
  number_of_offers?: number;
  description?: string;
  maximum_number_active_loans?: number;
  minimum_loan_amount?: string;
  maximum_loan_amount?: string;
  decimal_places?: number;
  decimal_round_way?: string;
  closing_tolerance_amount?: number;
  closing_tolerance_percentage?: number;
  amount_requested_step?: string;
  status?: number;
  created_at?: Date;
  updated_at?: Date;
  offers?: Offer[];
  style?: ProductCardStyle;
  offersDisplayed?: boolean;
}

export interface ProductCard {
  title?: string;
  productId?: string;
  template?: OFFER_CARD_TEMPLATE;
  iconPath?: string;
  offers?: Offer[];
}

export interface ProductsAndOfferCounter {
  productsCounter: number;
  offersCounter: number;
}

export enum CLOSING_TOLERANCE_TYPE {
  PERCENTAGE = 'percentage',
  FIXED_AMOUNT = 'fixed_amount',
}

export enum CLOSING_TOLERANCE_LABEL {
  PERCENTAGE = 'Number',
  FIXED_AMOUNT = '$ Less than',
}

export enum ROUND_WAY {
  ROUND_UP = 'up',
  ROUND_DOWN = 'down',
  // TODO TO BE DEFINED
  NATURAL_APROX = 'natural',
}

export enum ROUND_WAY_LABEL {
  ROUND_UP = 'Round up',
  ROUND_DOWN = 'Round down',
  // TODO TO BE DEFINED
  NATURAL_APROX = 'Natural aprox',
}

export const ROUND_WAY_LABEL_BY_VALUE = {
  [ROUND_WAY.ROUND_UP]: ROUND_WAY_LABEL.ROUND_UP,
  [ROUND_WAY.ROUND_DOWN]: ROUND_WAY_LABEL.ROUND_DOWN,
};

export interface ProductCardStyle {
  template?: OFFER_CARD_TEMPLATE;
  icon?: string;
}
