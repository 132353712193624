import { LoansPageTranslate } from '../../models/loans-translate';

const LOANS_TRANSLATE: LoansPageTranslate = {
  LOANS: {
    TITLE: 'Credits',
    DESCRIPTION:
      'Review all your serviced credits. Filter and download the credit database to quickly <br />navigate and analyze your credit operation data.',
    FILTERS: {
      TITLE: 'Search tools',
      DESCRIPTION:
        'Filter your credit database by using default and advanced filters or use free text search for specific fields.',
      SEARCH_BAR: 'Credit ID or National ID',
      AMOUNT_TITLE: 'Credit amount',
      AMOUNT_LESS: 'From',
      AMOUNT_GRATHER: 'to',
      STATUS: 'Credit status',
      TAKEN_AT: 'Taken at',
    },
    TABLE: {
      REFERENCE: 'Credit ID',
      CUSTOMER_REFERENCE: 'Customer ID',
      MERCHANT_REFERENCE: 'Merchant  ID',
      DISPLAY_NAME: 'Customer name',
      NATIONAL_ID: 'National ID',
      STATUS: 'Credit status',
      AMOUNT: 'Amount',
      TAKEN_AT: 'Taken at',
      PRODUCT_NAME: 'Product',
      OFFER_NAME: 'Offer',
    },
  },
  LOAN_PROFILE: {
    TITLE: 'Credit profile',
    DESCRIPTION:
      'Manage review the basic information for with this credit. Oversee payment history, <br/ > customer´s scores, and the overall amortization progress for this credit. ',
    INFO: {
      LOAN_ID: 'Credit ID',
      MERCHANT: 'Merchant',
      CUSTOMER_ID: 'Customer ID',
      TAKEN_AT: 'Taken at',
      MAX_DUE_DATE: 'Maximum due - date',
    },
    LOAN_SUMMARY: {
      TITLE: 'Credit summary',
      LOAN_DETAILS: {
        TITLE: 'Credit details',
        PURCHASE_AMOUNT: 'Purchase amount',
        REQUESTED_AMOUNT: 'Requested amount',
        LOAN_TERM: 'Credit term',
        REPAYMENT_FRECUENCY: 'Repayment frequency',
        INSTALLMENT: 'Installment',
        NUMBER_OF_INSTALLMENTS: '# of installments',
      },
      CHARGES: 'Charges',
      LOAN_TYC: 'Visit the terms and conditions for your credit',
    },
    PAYMENT_SUMMARY: {
      TITLE: 'Payment summary',
      DESCRIPTION:
        'See the associated products for this customer, their current respective scores and preapproved amounts. Go to score history for additional details.',
      TABLE: {
        REFERENCE: 'Payment ID',
        TYPE: 'Payment method',
        AMOUNT: 'Amount',
        PAID_AT: 'Date of payment',
      },
      BTN_VISIT_PAYMENTS: 'Visit payments',
    },
  },
};
export default LOANS_TRANSLATE;
