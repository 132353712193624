import { ItemSubMenu, MENU_ITEM_TYPE } from '@app/core/models/menu.model';
import { MENU_CONFIG_DISABLED } from '@instance-config/menu.config';
import { PRODUCT_CATALOGUE_URLS } from './urls.constants';

const SUB_ITEMS_MENU: ItemSubMenu[] = [
  {
    type: MENU_ITEM_TYPE.SUB_MENU_ITEM,
    label: 'PRODUCT_CATALOGUE._MENU.PRODUCTS_AND_OFFERS',
    path: PRODUCT_CATALOGUE_URLS.ROOT_PATH,
    isSubMenu: true,
    disabled: MENU_CONFIG_DISABLED.PRODUCT_CATALOGUE.PRODUCTS_AND_OFFERS ?? false
  },
].filter(item => !item.disabled);

export const PRODUCT_CATALOGUE_MENU = [
  {
    type: MENU_ITEM_TYPE.ITEM_ACCORDION,
    icon: 'app/presentation/assets/img/icons/menu/product-catalog.svg',
    label: 'PRODUCT_CATALOGUE._NAME',
    path: SUB_ITEMS_MENU[0]?.path ?? '',
    disabled: MENU_CONFIG_DISABLED.PRODUCT_CATALOGUE.MODULE ?? false,
    item: {
      type: MENU_ITEM_TYPE.SUB_MENU,
      items: SUB_ITEMS_MENU
    },
  },
];
