import { CurrencyTypes } from '@app/core/models/app.model';
import { CUSTOMER_CATEGORY } from '@app/core/models/score.model';
import {
  LOANS_REQUEST_STATUS,
  LOANS_STATUS,
} from '@app/plugins/modules/loan-manager/core/constants/loans.constants';
import {
  ChargePeriodicity,
  ChargeType,
  ChargeValueType,
} from '@app/plugins/modules/loan-manager/core/models/charge.model';
import {
  Loan,
  LoanCharge,
  LoanRequest,
  LoansScore,
  LoanTotals,
} from '@app/plugins/modules/loan-manager/core/models/loan.model';
import { TableResponse } from '@app/presentation/layout/mo-tables/interfaces/table.interface';

export const LOAN_REQUEST: TableResponse<LoanRequest> = {
  results: [
    {
      id: '1234567',
      external_id: '579bcb68-3679-4ceb-a52d-0242ec3cb6ae',
      reference: 'ASDB1234',
      customer_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
      customer_reference: '4e7e471703',
      merchant_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
      merchant_reference: '4e7e471703',
      display_name: 'Sherman Lindgren',
      status: LOANS_REQUEST_STATUS.COMPLETED,
      amount: '10000.10',
      created_at: new Date('2022-07-01 21:36:56'),
      product_name: 'Product',
      offer_name: 'Offer',
    },
    {
      id: '1234567',
      external_id: '5f31ecee-77a6-44a7-b90c-77b58574d4ef',
      reference: 'ASDB1234',
      customer_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
      customer_reference: '4e7e471703',
      merchant_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
      merchant_reference: '4e7e471703',
      display_name: 'Sherman Lindgren',
      status: LOANS_REQUEST_STATUS.PENDING,
      amount: '10000.00',
      created_at: new Date('2022-06-30 14:43:58'),
      product_name: 'Product',
      offer_name: 'Offer',
    },
    {
      id: '1234567',
      external_id: '4e-44-4d-bec3-7729a3ee9103',
      reference: 'ASDB1234',
      customer_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
      customer_reference: '4e7e471703',
      merchant_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
      merchant_reference: '4e7e471703',
      display_name: 'Sherman Lindgren',
      status: LOANS_REQUEST_STATUS.REJECTED,
      amount: '10000.10',
      created_at: new Date('2022-07-01 21:37:29'),
      product_name: 'Product',
      offer_name: 'Offer',
    },
    {
      id: '1234567',
      external_id: 'a4452aaa-9a60-441d-bc69-0b9d12895516',
      reference: 'ASDB1234',
      customer_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
      customer_reference: '4e7e471703',
      merchant_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
      merchant_reference: '4e7e471703',
      display_name: 'Sherman Lindgren',
      status: LOANS_REQUEST_STATUS.COMPLETED,
      amount: '1000.50',
      created_at: new Date('2022-06-30 20:47:49'),
      product_name: 'Product',
      offer_name: 'Offer',
    },
    {
      id: '1234567',
      external_id: 'a4452aaa-9a60-441d-bc69-0b9d12895516',
      reference: 'ASDB1234',
      customer_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
      customer_reference: '4e7e471703',
      merchant_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
      merchant_reference: '4e7e471703',
      display_name: 'Sherman Lindgren',
      status: LOANS_REQUEST_STATUS.REJECTED,
      amount: '10000.10',
      created_at: new Date('2022-07-01 21:38:27'),
      product_name: 'Product',
      offer_name: 'Offer',
    },
    {
      id: '1234567',
      external_id: '10.0009',
      reference: 'ASDB1234',
      customer_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
      customer_reference: '4e7e471703',
      merchant_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
      merchant_reference: 'merch4e7e471703',
      display_name: 'Sherman Lindgren',
      status: LOANS_REQUEST_STATUS.COMPLETED,
      amount: '10000.10',
      created_at: new Date('2022-07-01 21:39:10'),
      product_name: 'Product',
      offer_name: 'Offer',
    },
    {
      id: '1234567',
      external_id: '7b623f1d-3b83-4b52-a268-ebddcbb317d0',
      reference: 'ASDB1234',
      customer_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
      customer_reference: '4e7e471703',
      merchant_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
      merchant_reference: 'merch4e7e471703',
      display_name: 'Sherman Lindgren',
      status: LOANS_REQUEST_STATUS.COMPLETED,
      amount: '1000.50',
      created_at: new Date('2022-06-30 20:49:44'),
      product_name: 'Product',
      offer_name: 'Offer',
    },
    {
      id: '1234567',
      external_id: '78de02f7-1c8b-44af-a3a6-a1bb4d34ac2c',
      reference: 'ASDB1234',
      customer_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
      customer_reference: '4e7e471703',
      merchant_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
      merchant_reference: 'merch4e7e471703',
      display_name: 'Sherman Lindgren',
      status: LOANS_REQUEST_STATUS.COMPLETED,
      amount: '1000.50',
      created_at: new Date('2022-06-30 20:49:07'),
      product_name: 'Product',
      offer_name: 'Offer',
    },
    {
      id: '1234567',
      external_id: '0',
      reference: 'ASDB1234',
      customer_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
      customer_reference: '4e7e471703',
      merchant_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
      merchant_reference: 'merch4e7e471703',
      display_name: 'Sherman Lindgren',
      status: LOANS_REQUEST_STATUS.COMPLETED,
      amount: '10000.10',
      created_at: new Date('2022-07-01 21:40:18'),
      product_name: 'Product',
      offer_name: 'Offer',
    },
    {
      id: '1234567',
      external_id: '88f9a11d-ff34-4835-b870-6a4b44dd0eda',
      reference: 'ASDB1234',
      customer_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
      customer_reference: '4e7e471703',
      merchant_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
      merchant_reference: 'merch4e7e471703',
      display_name: 'Sherman Lindgren',
      status: LOANS_REQUEST_STATUS.COMPLETED,
      amount: '1000.50',
      created_at: new Date('2022-07-01 13:59:24'),
      product_name: 'Product',
      offer_name: 'Offer',
    },
  ],
  filtered: 45,
  count: 45,
};

export const LOANS: TableResponse<Loan> = {
  filtered: 50,
  count: 5000,
  results: [
    {
      id: '123456',
      reference: '12345ASD',
      customer_id: 'ALSKJDKL',
      customer_reference: 'QWEQWE123123',
      merchant_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
      merchant_reference: 'merch4e7e471703',
      identification_number: '12345678',
      display_name: 'Andres',
      status: LOANS_STATUS.ACTIVE,
      amount: 10000,
      created_at: new Date('2019-10-01T15:59:15-05:00'),
      product_name: 'Product',
      offer_name: 'Offer',
    },
    {
      id: '123456',
      reference: '12345ASD',
      customer_id: 'ALSKJDKL',
      customer_reference: 'QWEQWE123123',
      merchant_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
      merchant_reference: 'merch4e7e471703',
      identification_number: '12345678',
      display_name: 'Sebastian',
      status: LOANS_STATUS.CANCELED,
      amount: 10000,
      created_at: new Date('2019-10-01T15:59:15-05:00'),
      product_name: 'Product',
      offer_name: 'Offer',
    },
    {
      id: '123456',
      reference: '12345ASD',
      customer_id: 'ALSKJDKL',
      customer_reference: 'QWEQWE123123',
      merchant_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
      merchant_reference: 'merch4e7e471703',
      identification_number: '12345678',
      display_name: 'July',
      status: LOANS_STATUS.FROZEN,
      amount: 10000,
      created_at: new Date('2019-10-01T15:59:15-05:00'),
      product_name: 'Product',
      offer_name: 'Offer',
    },
    {
      id: '123456',
      reference: '12345ASD',
      customer_id: 'ALSKJDKL',
      customer_reference: 'QWEQWE123123',
      merchant_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
      merchant_reference: 'merch4e7e471703',
      identification_number: '12345678',
      display_name: 'Henry',
      status: LOANS_STATUS.PAID,
      amount: 10000,
      created_at: new Date('2019-10-01T15:59:15-05:00'),
      product_name: 'Product',
      offer_name: 'Offer',
    },
  ],
};

export const LOAN: Loan = {
  reference: '12345ASD',
  id: '13215498121984',
  customer_id: '1232546',
  customer_reference: 'QWEQWE123123',
  identification_number: '12345678',
  display_name: 'Adidas',
  status: LOANS_STATUS.ACTIVE,
  amount: 300000,
  created_at: new Date('2022-06-03T15:25:49.924Z'),
  maximum_payment_date: new Date('2022-06-03T15:25:49.924Z'),
  currency: 'COP',
  requested_amount: 300000,
  loan_term_value: 6,
  loan_term_periodicity: 'weeks',
  repayment_frecuency: 'weeks',
  installment_value: 300000,
  installments_number: 3,
};

export const LOAN_TOTALS: LoanTotals = {
  debt: {
    total_loan: 135000,
    total_debt: 99300,
    capital_debt: 33000,
    charges_debt: 33000,
    interest: 2000,
    administrative_costs: 2000,
  },
  paid: {
    total_paid: 99300,
    paid_capital: 33000,
    paid_charges: 33000,
    interest: 2000,
    administrative_costs: 2000,
  },
  currency: CurrencyTypes.COP,
};

export const LOAN_CHARGES: LoanCharge[] = [
  {
    name: 'cost',
    type: ChargeType.COST,
    value_type: ChargeValueType.FIXED,
    value: '100.00',
    tax: { name: 'IVA', value: '10.0' },
    detail: { periodicity: ChargePeriodicity.DAILY, value: '120.00' },
  },
  {
    name: 'collection_cost',
    type: ChargeType.COLLECTION_COST,
    value_type: ChargeValueType.PERCENTAGE,
    value: '30.00',
    tax: { name: null, value: null },
    detail: { periodicity: ChargePeriodicity.MONTHLY, value: '20.00' },
  },
  {
    name: 'interest',
    type: ChargeType.INTEREST,
    value_type: ChargeValueType.PERCENTAGE,
    value: '5.00',
    tax: { name: null, value: null },
    detail: { periodicity: ChargePeriodicity.WEEKLY, value: '0.01' },
  },
  {
    name: 'capital',
    type: ChargeType.CAPITAL,
    value_type: ChargeValueType.PERCENTAGE,
    value: '90.00',
    tax: { name: null, value: null },
    detail: { periodicity: ChargePeriodicity.UNIQUE, value: '0.09' },
  },
  {
    name: 'default_interest',
    type: ChargeType.DEFAULT_INTEREST,
    value_type: ChargeValueType.PERCENTAGE,
    value: '10.00',
    tax: { name: 'capital', value: '19.00' },
    detail: { periodicity: ChargePeriodicity.DAILY, value: '0.19' },
  },
];

export const LOAN_SCORES: LoansScore[] = [
  {
    id: 'e9d7b2a7-c010-4c51-bf21-b6911bd4ec45',
    pre_approved_amount: 20000.0,
    pre_approved_at: new Date('2022-06-29 00:00:00'),
    score: 0.9,
    product_id: '6cfe450c-add3-41b3-b2ba-4e704dafcc30',
    product_name: 'Ejemplo_MCA',
    product_reference: '783e45b5-3ba3-4702-8129-34bc0a3b67c3',
    customer_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
    customer_category: CUSTOMER_CATEGORY.CUSTOMER,
    display_name: 'Bridget Bradtke',
    created_at: new Date('2022-06-29 20:33:56'),
    others: {
      estimated_monthly_sales: '50',
      maximum_discount_percentage: '500000',
    },
  },
  {
    id: 'e9d7b2a7-c010-4c51-bf21-b6911bd4ec45',
    pre_approved_amount: 20000.0,
    pre_approved_at: new Date('2022-06-29 00:00:00'),
    score: 0.9,
    product_id: '6cfe450c-add3-41b3-b2ba-4e704dafcc30',
    product_name: 'Ejemplo_MCA',
    product_reference: '783e45b5-3ba3-4702-8129-34bc0a3b67c3',
    customer_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
    customer_category: CUSTOMER_CATEGORY.PRE_CUSTOMER,
    display_name: 'Bridget Bradtke',
    created_at: new Date('2022-06-29 20:33:56'),
    others: {
      estimated_monthly_sales: '50',
      maximum_discount_percentage: '500000',
    },
  },
];
