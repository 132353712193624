import { FeatureDetailsTranslate } from '@app/core/i18n/models/feature-details-translate';
import LOAN_REQUEST_TRANSLATE from './loan-request.translate';
import LOANS_TRANSLATE from './loans.translate';
import OVERVIEW_TRANSLATE from './overview.translate';
import PAYMENTS_TRANSLATE from './paymants.translate';

const ENGLISH_DEFAULT_LOAN_MANAGER: FeatureDetailsTranslate = {
  _NAME: 'Credit Manager',
  _MENU: {
    OVERVIEW: OVERVIEW_TRANSLATE.OVERVIEW.TITLE,
    LOAN_REQUEST: LOAN_REQUEST_TRANSLATE.LOAN_REQUEST.TITLE,
    LOANS: LOANS_TRANSLATE.LOANS.TITLE,
    PAYMENTS: PAYMENTS_TRANSLATE.PAYMENTS.TITLE,
  },
  ...OVERVIEW_TRANSLATE,
  ...LOAN_REQUEST_TRANSLATE,
  ...LOANS_TRANSLATE,
  ...PAYMENTS_TRANSLATE,
};
export default ENGLISH_DEFAULT_LOAN_MANAGER;
