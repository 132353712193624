import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  BUTTON_DESIGN_CLASS,
  SIZES,
} from '@app/presentation/layout/mo-forms/enums/fields.type';
@Component({
  selector: 'layout-filter-content',
  templateUrl: './filter-content.component.html',
  styleUrls: ['./filter-content.component.scss'],
})
export class FilterContentComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() results?: number;
  @Input() hasFiltredData?: boolean = false;
  @Input() hasAdvanceFilters?: boolean = false;
  @Output() cleanFilters: EventEmitter<void> = new EventEmitter<void>();
  @Output() toggleAdvanceFilters: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public BUTTON_DESIGN_CLASS = BUTTON_DESIGN_CLASS;
  public SIZES = SIZES;

  public openAdvanceFilters?: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  public onToggleAdvanceFilters() {
    this.openAdvanceFilters = !this.openAdvanceFilters;
    this.toggleAdvanceFilters.emit(this.openAdvanceFilters);
  }

  public onCleanFilters() {
    this.cleanFilters.emit();
  }
}
