import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SkeletonModule } from '../components/skeleton/skeleton.module';
import { MoFormsModule } from '../mo-forms/mo-forms.module';
import { EmptyComponent } from './components/empty/empty.component';
import { HeaderComponent } from './components/header/header.component';
import { ListWithControlsComponent } from './components/list-with-controls/list-with-controls.component';
import { OptionsComponent } from './components/options/options.component';
import { PageSizeComponent } from './components/page-size/page-size.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { RowComponent } from './components/row/row.component';
import { TableCellLoaderComponent } from './components/table-cell-loader/table-cell-loader.component';
import { TableWithControlsComponent } from './components/table-with-controls/table-with-controls.component';
import { TableComponent } from './components/table/table.component';

const COMPONENTS = [
  TableComponent,
  RowComponent,
  PaginatorComponent,
  PageSizeComponent,
  HeaderComponent,
  EmptyComponent,
  TableWithControlsComponent,
  OptionsComponent,
  TableCellLoaderComponent,
  ListWithControlsComponent,
];

@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    MoFormsModule,
    SkeletonModule,
    TranslateModule,
    RouterModule,
  ],
  exports: COMPONENTS,
})
export class MoTablesModule {}
