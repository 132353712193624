import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { IconModule } from '../components/icon/icon.module';
import { MoButtonComponent } from './components/mo-button/mo-button.component';
import { MoCheckboxComponent } from './components/mo-checkbox/mo-checkbox.component';
import { MoCodeComponent } from './components/mo-code/mo-code.component';
import { MoDateComponent } from './components/mo-date/mo-date.component';
import { MoDropDownComponent } from './components/mo-drop-down/mo-drop-down.component';
import { MoInputComponent } from './components/mo-input/mo-input.component';
import { MoLoadingComponent } from './components/mo-loading/mo-loading.component';
import { MoRadioComponent } from './components/mo-radio/mo-radio.component';
import { MoSelectComponent } from './components/mo-select/mo-select.component';
import { MoTextAreaComponent } from './components/mo-textarea/mo-textarea.component';
import { MoUploadComponent } from './components/mo-upload/mo-upload.component';

const STANDALONE_COMPONENTS = [MoButtonComponent, MoLoadingComponent];

const COMPONENTS = [
  MoInputComponent,
  MoCodeComponent,
  MoCheckboxComponent,
  MoSelectComponent,
  MoDropDownComponent,
  MoTextAreaComponent,
  MoRadioComponent,
  MoUploadComponent,
  MoDateComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    IconModule,
    NgxDaterangepickerMd.forRoot(),
    ...STANDALONE_COMPONENTS,
  ],
  exports: [
    ...COMPONENTS,
    ...STANDALONE_COMPONENTS,
    ReactiveFormsModule,
    FormsModule,
  ],
  declarations: COMPONENTS,
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MoFormsModule {}
