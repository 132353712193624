export const API_URLS = {
  MERCHANTS: {
    GET_MERCHANTS: '/merchants',
    GET_MERCHANTS_PURCHASES: '/loans',
    GET_MERCHANTS_SUMMARY: '/merchants/:id/summary',
    GET_MERCHANTS_GROUP_AND_OFFERS: '/merchants/:id/group-and-offers',
  },
  PURCHASES: {
    GET_PURCHASES: '/purchases',
  },
  MERCHANT_GROUPS: {
    GET_MERCHANT_GROUPS: '/merchants/groups',
  },
};
