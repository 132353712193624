export enum LOANS_STATUS {
  ACTIVE = 'active',
  PAID = 'paid',
  FROZEN = 'frozen',
  CANCELED = 'canceled',
}

export enum LOANS_REQUEST_STATUS {
  PENDING = 'pending',
  COMPLETED = 'completed',
  REJECTED = 'rejected',
}
