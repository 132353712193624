import { Component, Input } from '@angular/core';
import { Skeleton } from '@app/core/models/skeleton.model';
import { SKELETON_BODY, SKELETON_HEADER } from './card-with-details.constants';

@Component({
  selector: 'layout-card-with-details',
  templateUrl: './card-with-details.component.html',
  styleUrls: ['./card-with-details.component.scss'],
})
export class CardWithDetailsComponent {
  @Input() hasDetail?: boolean = false;
  @Input() isLoading?: boolean;
  @Input() loaderHeader?: Skeleton[] = SKELETON_HEADER;
  @Input() loaderBody?: Skeleton[] = SKELETON_BODY;
}
