import { ItemSubMenu, MenuItem, MENU_ITEM_TYPE } from '@app/core/models/menu.model';
import { MENU_CONFIG_DISABLED } from '@instance-config/menu.config';
import { CUSTOMERS_URLS } from './urls.constants';

const SUB_ITEMS_MENU: ItemSubMenu[] = [
  {
    type: MENU_ITEM_TYPE.SUB_MENU_ITEM,
    label: 'CUSTOMERS._MENU.OVERVIEW',
    path: CUSTOMERS_URLS.OVERVIEW,
    isSubMenu: true,
    disabled: MENU_CONFIG_DISABLED.CUSTOMER.OVERVIEW ?? false
  },
  {
    type: MENU_ITEM_TYPE.SUB_MENU_ITEM,
    label: 'CUSTOMERS._MENU.PRE_CUSTOMERS',
    path: CUSTOMERS_URLS.PRE_CUSTOMERS,
    isSubMenu: true,
    disabled: MENU_CONFIG_DISABLED.CUSTOMER.PRE_CUSTOMERS ?? false
  },
  {
    type: MENU_ITEM_TYPE.SUB_MENU_ITEM,
    label: 'CUSTOMERS._MENU.CUSTOMERS',
    path: CUSTOMERS_URLS.CUSTOMERS,
    isSubMenu: true,
    disabled: MENU_CONFIG_DISABLED.CUSTOMER.CUSTOMERS ?? false
  },
].filter(item => !item.disabled);

export const CUSTOMERS_MENU: MenuItem[] = [
  {
    type: MENU_ITEM_TYPE.ITEM_ACCORDION,
    icon: 'app/presentation/assets/img/icons/menu/customers.svg',
    label: 'CUSTOMERS._NAME',
    path: SUB_ITEMS_MENU[0]?.path ?? '',
    disabled: MENU_CONFIG_DISABLED.CUSTOMER.MODULE ?? false,
    item: {
      type: MENU_ITEM_TYPE.SUB_MENU,
      items: SUB_ITEMS_MENU
    },
  },
];
