import { Action, createReducer, on } from '@ngrx/store';
import {
  failedGetProject,
  getProject,
  successGetProject,
} from './project.actions';
import { ProjectState } from './project.state';
export const initialProjectState: ProjectState = {};

const _projectReducer = createReducer(
  initialProjectState,
  on(getProject, (state) => ({
    ...state,
    isLoadingProject: true,
  })),
  on(successGetProject, (state, { project }) => ({
    ...state,
    data: project,
    isLoadingProject: false,
  })),
  on(failedGetProject, (state) => ({
    ...state,
    isLoadingProject: false,
  }))
);

export function ProjectReducers(
  state: ProjectState | undefined,
  action: Action
) {
  return _projectReducer(state, action);
}
