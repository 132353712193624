export interface Menu {
  logo: string;
  logoCollapsed: string;
  product: string;
  items: MenuItem[];
}

export interface MenuItem {
  type: string;
  label?: string;
  icon?: string;
  shortLabel?: string;
  path?: string;
  item?: SubMenu;
  disabled?: boolean
}

interface SubMenu {
  type: string;
  items: ItemSubMenu[];
}

export interface ItemSubMenu {
  type: string;
  label?: string;
  shortLabel?: string;
  path?: string;
  isSubMenu?: boolean;
  icon?: string;
  disabled?: boolean;
}

export enum MENU_ITEM_TYPE {
  TITLE = 'title',
  ITEM = 'item',
  ITEM_ACCORDION = 'item-accordion',
  SUB_MENU = 'sub-menu',
  SUB_MENU_ITEM = 'sub-menu-item',
  SUB_MENU_ITEM_ICON = 'sub-menu-item-icon',
  SUB_MENU_TITLE = 'sub-menu-title',
}
