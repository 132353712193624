<div class="filter-content">
  <div class="filter-content__head">
    <div class="details">
      <h2 class="details__title" [innerHTML]="title"></h2>
      <p class="details__description" [innerHTML]="description"></p>
    </div>
    <div class="filter">
      <ng-content select="[slot='main-filter']"></ng-content>
    </div>
  </div>
  <div class="filter-content__body">
    <ng-content select="[slot='filters']"></ng-content>
  </div>
  <div class="filter-content__advanced-filters" *ngIf="hasAdvanceFilters">
    <div
      class="header"
      [ngClass]="{ 'header--open': openAdvanceFilters }"
      (click)="onToggleAdvanceFilters()"
    >
      <p class="header__title">
        {{ "APP.FILTERS.ADVANCED_FILTERS" | translate }}
      </p>
      <i class="header__icon uil uil-filter"></i>
    </div>
    <div class="body" *ngIf="openAdvanceFilters">
      <ng-content select="[slot='advanced-filters']"></ng-content>
    </div>
  </div>
  <div class="filter-content__results" *ngIf="hasFiltredData">
    <p class="total-filtred">
      <span class="count">{{ results }}</span>
      {{ "APP.FILTERS.RESULTS" | translate }}
    </p>
    <mo-button
      [text]="'APP.FILTERS.BTN_CLEAN_FILTERS' | translate"
      [designClass]="BUTTON_DESIGN_CLASS.OUTLINE_PRIMARY"
      [displayBlock]="true"
      [size]="SIZES.MD"
      (click)="onCleanFilters()"
    >
    </mo-button>
  </div>
</div>
