import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { COLOR_TEMPLATE, SIZES } from '../../enums/fields.type';

@Component({
  selector: 'mo-loading',
  templateUrl: './mo-loading.component.html',
  styleUrls: ['./mo-loading.component.scss'],
  imports: [CommonModule],
  standalone: true,
})
export class MoLoadingComponent {
  @Input() size!: SIZES;
  @Input() color!: COLOR_TEMPLATE;
}
