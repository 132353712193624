import { Filters } from '@app/core/models/filters.model';

export interface Offer {
  name?: string;
  status?: OFFER_STATUS;
  reference?: string;
  product_id?: string;
  product_reference?: string;
  id?: string;
  created_at?: Date;
  updated_at?: Date;
  // TODO VALIDATE
  action?: OFFER_ACTION;
}

export enum OFFER_STATUS {
  DRAFT = 'draft',
  LIVE = 'live',
  HIDDEN = 'hidden',
}

export enum OFFER_STATUS_ICONS {
  LIVE = 'uil-wifi',
  DRAFT = 'uil-edit',
  HIDDEN = 'uil-eye-slash',
}

export enum OFFER_ACTION {
  IN_USE = 'in-use',
  INACTIVE = 'inactive',
  IDLE = 'idle',
}

export interface OffersFilters extends Filters {
  product_reference?: string;
}

export interface OfferSpecs {
  generalSettings?: OfferGeneralSettings;
  repaymentLogic?: OfferRepaymentLogic;
  charges?: OfferCharges;
}

export interface OfferGeneralSettings {
  name?: string;
  description?: string;
  max_active_credits?: number;
  limitless?: string;
  file?: File;
  file_name?: string;
}
export interface OfferRepaymentLogic {
  repayment_type?: string;
  periodicity?: string;
  calendar_logic?: string;
  fixed_or_relative?: string;
  day_of_week?: string;
  number_of_installments?: number;
  type_of_installment?: string;
  missed_repayment_logic?: string;
  grace_period?: string;
  grace_period_days?: number;
}

export type OfferChargeType =
  | 'interest'
  | 'cost'
  | 'collectionCost'
  | 'defaultInterest'
  | 'capital';

export interface OfferCharges {
  capital?: OfferCapitalCharge;
  interest?: OfferInterestCharge;
  cost?: OfferCostCharge;
  collectionCost?: OfferCollectionCostCharge;
  defaultInterest?: OfferDefaultInterestCharge;
}

export interface OfferCapitalCharge {
  name?: string;
  priorization?: number;
}

export interface OfferInterestCharge {
  name?: string;
  interest?: string;
  type_of_interest?: string;
  tax?: string;
  tax_fee?: number;
  periodicity?: string;
  fixed_or_relative?: string;
  grace_period?: string;
  grace_period_days?: number;
  priorization?: number;
}
export interface OfferCostCharge {
  type_of_cost?: string;
  name?: string;
  description?: string;
  fixed_or_percent?: string;
  fixed_or_percent_value?: number;
  tax?: string;
  tax_fee?: number;
  priorization?: number;
}
export interface OfferCollectionCostCharge {
  name?: string;
  trigger?: string;
  grace_period?: string;
  grace_period_time_unit?: string;
  grace_period_time_number?: number;
  fixed_or_percent?: string;
  fixed_or_percent_value?: number;
  base_definition?: string;
  tax?: string;
  tax_fee?: number;
  priorization?: number;
}
export interface OfferDefaultInterestCharge {
  name?: string;
  trigger?: string;
  interest?: string;
  type_of_interest?: string;
  tax?: string;
  tax_fee?: number;
  periodicity?: string;
  fixed_or_relative?: string;
  grace_period?: string;
  grace_period_days?: number;
  priorization?: number;
}
