import { MyAccountPageTranslate } from '@app/core/i18n/models/my-account-translate';

const MY_ACCOUNT: MyAccountPageTranslate = {
  ACCOUNT: {
    TITLE: 'My account',
    DESCRIPTION:
      'View and edit your account details and information. Manage user´s profiles based on their respective roles. Create new users via email.',
    YOUR_DETAILS: {
      TITLE: 'Your details',
      NAME: 'Name',
      EMAIL: 'Email',
      ROL_TITLE: 'Profile role',
    },
    ACCOUNT_DETAILS: {
      TITLE: 'Account details',
      NAME: 'Name',
      DESCRIPTION: 'Description',
      MO_MANAGE_FAMILY: 'Mo Manage Family',
      ADD_IN_MO_PRODUCTS: 'Add in MO Products',
      COUNTRY: 'Country',
      CURRENCY: 'Currency',
      TOP_LIMIT_TITLE: 'Top-Top Limit',
      TOP_LIMIT_DESCRIPTION:
        'Set the absolute top number of active credits a customer can have at the same time for any product or offer.',
    },
    TABLE_CARD: {
      TITLE: 'Users',
      TAB_USERS: 'Users',
      TAB_INVITATIONS: 'Invite sent',
      TABLE: {
        NAME: 'Name',
        STATUS: 'Status',
        ROL: 'Rol',
      },
    },
  },
};
export default MY_ACCOUNT;
