import { CustomerPageTranslate } from '../../models/customer-translate';

const CUSTOMERS_TRANSLATE: CustomerPageTranslate = {
  CUSTOMERS: {
    TITLE: 'Customers',
    DESCRIPTION:
      'Review all your current customers. Filter and download this database to quickly <br />navigate and analyze your customer base. ',
    FILTERS: {
      TITLE: 'Search tools',
      DESCRIPTION:
        'Filter your customer database by using our search filters <br/> for specific fields. ',
      SEARCH_BAR: 'Customer name, ID or national ID, Mail or phone number',
      NUMBER_OF_ACTIVE_LOANS: '# of active credits',
      CUSTOMER_STATUS: 'Customer status',
      CREATED_AT: 'Date created',
      UPDATED_AT: 'Date modified',
      SELECT_PRODUCT: 'Select product',
    },
    TABLE: {
      REFERENCE: 'Customer ID',
      NAME: 'Name',
      NUMBER_OF_ACTIVE_LOANS: 'Active credits',
      EMAIL: 'Email',
      PHONE_NUMBER: 'Phone number',
      IDENTIFICATION_NUMBER: 'National ID',
      CREATED_AT: 'Date created',
      UPDATED_AT: 'Date modified',
    },
  },
  CUSTOMER_PROFILE: {
    TITLE: 'Customer profile',
    DESCRIPTION:
      'Review your customer´s available information. Take a closer look into their personal info, associated credits, scores, and recent payments.',
    INFO: {
      CUSTOMER_ID: 'Customer ID',
      CREATED_AT: 'Date created',
      UPDATED_AT: 'Date modified',
    },
    DETAILS: {
      TITLE: 'Customer details',
      CONTACT: 'Contact',
      CUSTOMER_NAME: 'Customer name',
      DOCUMENT_TYPE: 'Document type',
      NUMBER: 'Number',
      EMAIL: 'Email',
      PHONE_NUMBER: 'Phone number',
      WEBSITE: 'Website',
      OTHERS: 'Other',
    },
    PRODUCTS_AND_SCORES: {
      TITLE: 'Products and scores',
      DESCRIPTION:
        'See the associated products for this customer, their current respective scores and preapproved amounts. Go to score history for additional details.',
    },
    ASSOCIATED_LOANS: {
      TITLE: 'Associated credits',
      DESCRIPTION:
        'See the existing credits for this customer. Go to credit profile for additional information on a specific credit for this customer.',
      TABLE: {
        ID: 'Credit ID',
        STATUS: 'Status',
        LOAN_AMOUNT: 'Credit amount',
        CREATED_AT: 'Date created',
      },
      BTN_VISIT_LOAN_MANAGER: 'Visit credit manager',
    },
    RECENT_PAYMENTS: {
      TITLE: 'Recent payments',
      DESCRIPTION:
        'Visualize the most recent payments made by this customer. Quickly see their upcoming payment for their next credit installment.',
      TABLE: {
        REFERENCE: 'Payment ID',
        CREDIT_REFERENCE: 'Credit ID',
        AMOUNT: 'Amount',
        PAID_AT: 'Date of payment',
      },
      BTN_VISIT_PAYMENTS: 'Visit payments',
    },
  },
};
export default CUSTOMERS_TRANSLATE;
