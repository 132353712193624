import { PreCustomerPageTransle } from '../../models/pre-customer-translate';

const PRE_CUSTOMERS_TRANSLATE: PreCustomerPageTransle = {
  PRE_CUSTOMERS: {
    TITLE: 'Pre-customers',
    DESCRIPTION:
      'Review all your current pre-customers. Filter and download this database to quickly <br />navigate and analyze your pre-customer base.',
    FILTERS: {
      TITLE: 'Search tools',
      DESCRIPTION:
        'Filter your pre-customer database by using our search <br/ > filters for specific fields. ',
      SEARCH_BAR: 'Name, Phone number',
      ID: 'Pre-customer ID',
      CREATED_AT: 'Date created',
      UPDATED_AT: 'Date modified',
      SELECT_PRODUCT: 'Select product',
    },
    TABLE: {
      REFERENCE: 'Pre-customer ID',
      DISPLAY_NAME: 'Name',
      PHONE_NUMBER: 'Phone Number',
      CREATED_AT: 'Date created',
      UPDATED_AT: 'Date modified',
      ASSOCIATED_PRODUCT: 'Associated products',
    },
  },

  PRE_CUSTOMER_PROFILE: {
    TITLE: 'Pre-customer profile',
    DESCRIPTION:
      'Review your pre-customer´s available information. Take a closer <br />look into their basic details, current scores, and products.',
    INFO: {
      PRE_CUSTOMER_ID: 'Pre-customer ID',
      CREATED_AT: 'Date created:',
      UPDATED_AT: 'Date modified:',
    },
    DETAILS: {
      TITLE: 'Pre-customer details',
      CUSTOMER_NAME: 'Customer name',
      DOCUMENT_TYPE: 'Document type',
      NUMBER: 'Number',
      EMAIL: 'Email',
      PHONE_NUMBER: 'Phone number',
      OTHERS: 'Other',
    },
    PRODUCTS_AND_SCORES: {
      TITLE: 'Products and scores',
    },
  },
};
export default PRE_CUSTOMERS_TRANSLATE;
