import { RoutesMock } from '@app/core/models/routes-mock.model';
import {
  ALL_CUSTOMERS,
  CUSTOMERS_PRODUCTS_AND_SCORES,
  CUSTOMER_PROFILE,
} from '../mocks/data/customers.mock';
import {
  ALL_PRE_CUSTOMERS,
  PRE_CUSTOMERS_PRODUCTS_AND_SCORES,
  PRE_CUSTOMERS_PROFILE,
} from '../mocks/data/pre-customers.mock';
import { API_URLS } from './api.constants';

const ID_REGEX = '/[0-9a-z-]+';

export const CUSTOMERS_ROUTES_MOCK: RoutesMock[] = [
  // ********************
  // CUSTOMERS
  // ********************
  // PRE-CUSTOMER
  {
    url: API_URLS.PRE_CUSTOMERS.GET_PRE_CUSTOMERS,
    data: ALL_PRE_CUSTOMERS,
    method: 'GET',
  },
  // PRE-CUSTOMER PROFILE
  {
    url: `${API_URLS.PRE_CUSTOMERS.GET_PRE_CUSTOMERS}${ID_REGEX}`,
    data: PRE_CUSTOMERS_PROFILE,
    method: 'GET',
  },
  // PRE-CUSTOMER PRODUCTS AND SCORES
  {
    url: API_URLS.PRE_CUSTOMERS.GET_PRE_CUSTOMERS_PRODUCTS_AND_SCORES.replace(
      '/:id',
      ID_REGEX
    ),
    data: PRE_CUSTOMERS_PRODUCTS_AND_SCORES,
    method: 'GET',
  },

  // CUSTOMER
  {
    url: API_URLS.CUSTOMERS.GET_CUSTOMERS,
    data: ALL_CUSTOMERS,
    method: 'GET',
  },
  // CUSTOMER PROFILE
  {
    url: `${API_URLS.CUSTOMERS.GET_CUSTOMERS}${ID_REGEX}`,
    data: CUSTOMER_PROFILE,
    method: 'GET',
  },
  // CUSTOMER PRODUCTS AND SCORES
  {
    url: API_URLS.CUSTOMERS.GET_CUSTOMERS_PRODUCTS_AND_SCORES.replace(
      '/:id',
      ID_REGEX
    ),
    data: CUSTOMERS_PRODUCTS_AND_SCORES,
    method: 'GET',
  },
];
