import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FORM_CONSTANTS } from '../../constants/form.constants';
import { FormUtilService } from '../../services/form-util.service';

@Component({
  selector: 'mo-upload',
  templateUrl: './mo-upload.component.html',
  styleUrls: ['./mo-upload.component.scss'],
})
export class MoUploadComponent {
  @Input() direction: string;
  @Input() form!: UntypedFormGroup;
  @Input() controlName!: string;
  @Input() label!: string;
  @Input() info: string;
  @Input() showButtonDelete: boolean = false;

  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<any>();

  public FORM_CONSTANTS = FORM_CONSTANTS;

  public file: any;

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    let files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.onFileDropped(files);
    }
  }

  constructor(public _formUtilService: FormUtilService) {}

  /**
   * on file drop handler
   */
  public onFileDropped($event) {
    this._prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  public fileBrowseHandler(files) {
    this._prepareFilesList(files);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  public formatBytes(bytes, decimals?) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  public onDelete() {
    this._prepareFilesList([]);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  private _prepareFilesList(files: Array<any>) {
    this.file = files[0];

    if (this.form) {
      this.form.get(this.controlName).setValue(this.file);
      this.form.get(this.controlName).updateValueAndValidity();
    } else {
      this.fileDropped.emit(this.file);
    }
  }
}
