import { MERCHANT_STATUS } from '@app/plugins/modules/merchant-manager/core/constants/merchants.constants';
import {
  Merchant,
  MerchantGroupAndOffersProfile,
  MerchantProfile,
  MerchantSummary,
} from '@app/plugins/modules/merchant-manager/core/models/merchant.model';
import { TableResponse } from '@app/presentation/layout/mo-tables/interfaces/table.interface';

export const MERCHANTS: TableResponse<Merchant> = {
  filtered: 500,
  count: 3,
  results: [
    {
      id: '123123-asdasd-123123',
      reference: '0001',
      display_name: 'Merchant name',
      national_id: '1234567890',
      status: MERCHANT_STATUS.ACTIVE,
      sales: 1000,
      payout: 20000,
      created_at: new Date('2019-10-01T15:59:15-05:00'),
    },
    {
      id: '123123-asdasd-123123',
      reference: '0002',
      display_name: 'Adidas',
      national_id: '1234567890',
      status: MERCHANT_STATUS.ACTIVE,
      sales: 5000,
      payout: 30000,
      created_at: new Date('2019-10-01T15:59:15-05:00'),
    },
    {
      id: '123123-asdasd-123123',
      reference: '0003',
      display_name: 'Audi',
      national_id: '1234567890',
      status: MERCHANT_STATUS.INACTIVE,
      sales: 100000,
      payout: 2000000,
      created_at: new Date('2019-10-01T15:59:15-05:00'),
    },
  ],
};

export const MERCHANT_PROFILE: MerchantProfile = {
  id: '4cd6d1be-1d10-4388-890a-9b244eb0cf8c',
  reference: 'BH7427VJVFAT',
  display_name: 'Merchant name',
  status: 'inactive',
  created_at: new Date('2022-08-26T20:44:52.418736Z'),
  national_id_type: 'NUC',
  national_id: '10101012312',
  billing_address: 'Cra 93 #11 A 11',
  online_store: 'wearemo.com',
  contact_numbers: ['+51936703836', '+5122991297'],
  contact_email: 'movers@wearemo.com',
  service_type: 'online_checkout',
};

export const MERCHANT_SUMMARY: MerchantSummary = {
  total_sales_amount: {
    value: 1000,
    percentage: 12,
  },
  total_number_of_sales: {
    value: 102,
    percentage: -0.2,
  },
  avg_ticket: {
    value: 2000,
    percentage: 0.12,
  },
  avg_number_of_purchases: {
    value: 0.5,
    percentage: -2.5,
  },
};

export const MERCHANT_GROUP_AND_OFFERS: MerchantGroupAndOffersProfile = {
  merchant_group_name: 'Mr. Ethan Hensley',
  merchant_product_and_offers: [
    {
      product_id: '49f7dc29-258e-4575-b8c3-b809a08dd6bd',
      product_name: 'Dr. Wayne Ponce 1',
      offer_name: 'Mrs. Bethany Snyder PhD 1',
      offer_value: 1,
    },
    {
      product_id: '49f7dc29-258e-4575-b8c3-b809a08dd6bd',
      product_name: 'Dr. Wayne Ponce 2',
      offer_name: 'Cameron Hester 2',
      offer_value: 2,
    },
    {
      product_id: '49f7dc29-258e-4575-b8c3-b809a08dd6bd',
      product_name: 'Dr. Wayne Ponce 3',
      offer_name: 'Miss Bianca Montes PhD 3',
      offer_value: 3,
    },
    {
      product_id: '49f7dc29-258e-4575-b8c3-b809a08dd6bd',
      product_name: 'Dr. Wayne Ponce 4',
      offer_name: 'Miss Cheryl Montes DVM 4',
      offer_value: 4,
    },
    {
      product_id: '49f7dc29-258e-4575-b8c3-b809a08dd6bd',
      product_name: 'Dr. Wayne Ponce 5',
      offer_name: 'Adam Owens 5',
      offer_value: 5,
    },
  ],
};
