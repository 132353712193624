import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Credencials, RefreshToken, Token } from '@app/core/models/auth.model';
import { API_URLS } from '@app/data/constants/api.constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthApi {
  constructor(private _http: HttpClient) {}

  public login(credencials: Credencials): Observable<Token> {
    return this._http.post<Token>(API_URLS.AUTH.LOGIN, credencials);
  }

  public refreshToken(refreshToken: RefreshToken): Observable<Token> {
    return this._http.post<Token>(API_URLS.AUTH.REFRESH_TOKEN, refreshToken);
  }
}
