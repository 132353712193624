import { LoansPageTranslate } from '../../models/loans-translate';

const LOANS_TRANSLATE: LoansPageTranslate = {
  LOANS: {
    TITLE: 'Créditos',
    DESCRIPTION:
      'Revise todos sus créditos con servicio. Filtre y descargue la base de datos de crédito para <br />navegar y analizar rápidamente los datos de su operación de crédito.',
    FILTERS: {
      TITLE: 'Herramientas de búsqueda',
      DESCRIPTION:
        'Filtre su base de datos de crédito utilizando filtros predeterminados y avanzados o utilice la búsqueda de texto libre para campos específicos.',
      SEARCH_BAR: 'ID de crédito, ID de cliente o ID nacional',
      AMOUNT_TITLE: 'Monto de crédito',
      AMOUNT_LESS: 'Desde',
      AMOUNT_GRATHER: 'hasta',
      STATUS: 'Estado de crédito',
      TAKEN_AT: 'Tomado en',
    },
    TABLE: {
      REFERENCE: 'ID de crédito',
      CUSTOMER_REFERENCE: 'ID del cliente',
      MERCHANT_REFERENCE: 'ID del comercio',
      DISPLAY_NAME: 'Nombre del cliente',
      NATIONAL_ID: 'ID nacional',
      STATUS: 'Estado del crédito',
      AMOUNT: 'Monto',
      TAKEN_AT: 'Tomado en',
      PRODUCT_NAME: 'Producto',
      OFFER_NAME: 'Oferta',
    },
  },
  LOAN_PROFILE: {
    TITLE: 'Perfil de crédito',
    DESCRIPTION:
      'Gestiona revisar la información básica para con este crédito. Supervise el historial de pagos, <br/ > las puntuaciones de los clientes y el progreso general de la amortización de este crédito.',
    INFO: {
      LOAN_ID: 'ID de crédito',
      MERCHANT: 'Comerciante',
      CUSTOMER_ID: 'ID del cliente',
      TAKEN_AT: 'Tomado en',
      MAX_DUE_DATE: 'Fecha máxima de vencimiento',
    },
    LOAN_SUMMARY: {
      TITLE: 'Resumen de crédito',
      LOAN_DETAILS: {
        TITLE: 'Detalles de crédito',
        PURCHASE_AMOUNT: 'Monto de la compra',
        REQUESTED_AMOUNT: 'Monto requerido',
        LOAN_TERM: 'Plazo del crédito',
        REPAYMENT_FRECUENCY: 'Frecuencia de reembolso',
        INSTALLMENT: 'Cuota',
        NUMBER_OF_INSTALLMENTS: '# de cuotas',
      },
      CHARGES: 'Cargos',
      LOAN_TYC: 'Visita los términos y condiciones de tu crédito',
    },
    PAYMENT_SUMMARY: {
      TITLE: 'Resumen de pago',
      DESCRIPTION:
        'Vea los productos asociados para este cliente, sus respectivos puntajes actuales y montos preaprobados. Vaya al historial de puntajes para obtener detalles adicionales.',
      TABLE: {
        REFERENCE: 'ID de pago',
        TYPE: 'Método de pago',
        AMOUNT: 'Monto',
        PAID_AT: 'Fecha del pago',
      },
      BTN_VISIT_PAYMENTS: 'Visitar los pagos',
    },
  },
};
export default LOANS_TRANSLATE;
