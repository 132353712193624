import { MENU_CONFIG_DISABLED } from '@instance-config/menu.config';
import { REPORTS_FEATURE } from './feature.constants';

export const REPORTS_ROUTES = [
  {
    path: REPORTS_FEATURE.PATH,
    loadChildren: () =>
      import('../../presentation/reports.module').then((m) => m.ReportsModule),
    data: {
      breadcrumb: REPORTS_FEATURE.NAME,
      disabled: MENU_CONFIG_DISABLED.REPORTS.MODULE ?? false
    },
  },
];
