import { CUSTOMER_CATEGORY } from '@app/core/models/score.model';
import {
  PreCustomer,
  PreCustomerProfile,
} from '@app/plugins/modules/customers/core/models/pre-customer.model';
import { ProductAndScore } from '@app/plugins/modules/customers/core/models/product-and-score.model';
import { TableResponse } from '@app/presentation/layout/mo-tables/interfaces/table.interface';

export const ALL_PRE_CUSTOMERS: TableResponse<PreCustomer> = {
  filtered: 500,
  count: 3,
  results: [
    {
      id: '029c13ba-fcc2-4817-b11e-b7c0a93cbac0',
      external_id: 'None',
      reference: 'eHNJalqEqrCZrQNuosMS',
      display_name: 'Wade Warren',
      phone_number: '(671) 555-0110',
      created_at: new Date('2022-08-01T21:04:52.813946Z'),
      updated_at: new Date('2022-08-01T21:04:52.813954Z'),
      products: [
        {
          id: '0bff09a2-5f94-42f4-be8a-ae317d880220',
          name: 'Ms. Kirsten Paul DVM',
          status: 'active',
          reference: 'eHNJalqEqrCZrQNuosMS',
        },
        {
          id: '0bff09a2-5f94-42f4-be8a-ae317d880220',
          name: 'Ms. Other ',
          status: 'active',
          reference: 'eHNJalqEqrCZrQNuosMS',
        },
      ],
    },
    {
      id: '029c13ba-fcc2-4817-b11e-b7r0a93cbac0',
      external_id: 'None',
      reference: 'eHNJalqEqrCZrQNuosMS',
      display_name: 'Kristin Watson',
      phone_number: '(316) 555-0116',
      created_at: new Date('2022-08-01T21:04:52.813946Z'),
      updated_at: new Date('2022-08-01T21:04:52.813954Z'),
      products: [],
    },
    {
      id: '029c13ba-fcc2-48a7-b11e-b7c0a93cbac0',
      external_id: 'None',
      reference: 'eHNJalqEqrCZrQNuosMS',
      display_name: 'Floyd Miles',
      phone_number: '(209) 555-0104',
      created_at: new Date('2022-08-01T21:04:52.813946Z'),
      updated_at: new Date('2022-08-01T21:04:52.813954Z'),
      products: [
        {
          id: '0bff09a2-5f94-42f4-be8a-ae317d880220',
          name: 'Ms. DVM',
          status: 'active',
          reference: 'eHNJalqEqrCZrQNuosMS',
        },
      ],
    },
  ],
};

export const PRE_CUSTOMERS_PROFILE: PreCustomerProfile = {
  id: 'bcbea3c1-939e-427b-a235-7d9cccd1a5f7',
  external_id: null,
  reference: 'eHNJalqEqrCZrQNuosMS',
  created_at: new Date('2022-05-24T15:01:32.709376Z'),
  updated_at: new Date('2022-05-24T15:06:37.493649Z'),
  display_name: 'Andres Orozco',
  email: 'andres.orozco@gmail.com',
  phone_number: '+57 315 267 3521',
  website: null,
  identification_number: '900738725',
  identification_type: 'C.C',
  others: {
    pre_field: 'pre_value',
    pre_field_2: 'pre_value_2',
    pre_patch_field: 'pre_patch_value',
  },
};

export const PRE_CUSTOMERS_PRODUCTS_AND_SCORES: ProductAndScore[] = [
  {
    id: 'e9d7b2a7-c010-4c51-bf21-b6911bd4ec45',
    pre_approved_amount: 20000.0,
    pre_approved_at: new Date('2022-06-29 00:00:00'),
    score: 0.9,
    product_id: '6cfe450c-add3-41b3-b2ba-4e704dafcc30',
    product_name: 'Ejemplo_MCA',
    product_reference: '783e45b5-3ba3-4702-8129-34bc0a3b67c3',
    customer_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
    customer_category: CUSTOMER_CATEGORY.PRE_CUSTOMER,
    display_name: 'Bridget Bradtke',
    created_at: new Date('2022-06-29 20:33:56'),
    others: {
      estimated_monthly_sales: '50',
      maximum_discount_percentage: '500000',
    },
  },
  {
    id: 'e9d7b2a7-c010-4c51-bf21-b6911bd4ec45',
    pre_approved_amount: 20000.0,
    pre_approved_at: new Date('2022-06-29 00:00:00'),
    score: 0.9,
    product_id: '6cfe450c-add3-41b3-b2ba-4e704dafcc30',
    product_name: 'Ejemplo_MCA',
    product_reference: '783e45b5-3ba3-4702-8129-34bc0a3b67c3',
    customer_id: '4e7e4717-445a-4d30-bec3-7729a3ee9103',
    customer_category: CUSTOMER_CATEGORY.CUSTOMER,
    display_name: 'Bridget Bradtke',
    created_at: new Date('2022-06-29 20:33:56'),
    others: {
      estimated_monthly_sales: '50',
      maximum_discount_percentage: '500000',
    },
  },
];
