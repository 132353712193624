import { User } from '@app/core/models/user.model';
import { createAction, props } from '@ngrx/store';

export const getUserInfo = createAction('[User Effects] Get User Info ');
export const successGetUserInfo = createAction(
  '[User Effects] Success Get User Info ',
  props<{ data: User }>()
);
export const failedGetUserInfo = createAction(
  '[User Effects] Failed Get User Info '
);
