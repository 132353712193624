import { Injectable } from '@angular/core';
import { Credencials, RefreshToken, Token } from '@app/core/models/auth.model';
import { AuthService } from '@app/domain/services/auth/auth.service';
import * as authActions from '@app/domain/store/auth/auth.actions';
import * as authSelectors from '@app/domain/store/auth/auth.selectors';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthFacade {
  public isLoadingLogin$: Observable<boolean>;
  public isAuthenticated$: Observable<boolean>;

  constructor(private _store: Store, private _authService: AuthService) {
    this.isLoadingLogin$ = this._store.select(
      authSelectors.selectIsLoadingLogin
    );
    this.isAuthenticated$ = this._store.select(
      authSelectors.selectIsAuthenticated
    );
  }

  public getSessionToken(): Observable<Token> {
    return of(this._authService.getSessionToken());
  }

  public login(credencials: Credencials) {
    this._store.dispatch(authActions.login({ credencials }));
  }

  public refreshToken() {
    const refreshToken: RefreshToken = this._authService.getRefreshToken();
    if (refreshToken) {
      this._store.dispatch(authActions.refreshToken({ refreshToken }));
    }
  }

  public logout() {
    this._store.dispatch(authActions.logout());
  }
}
