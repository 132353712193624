export interface Project {
  id?: string;
  name?: string;
  description?: string;
  country?: string;
  decimal_places?: number;
  decimal_round_way?: ROUND_WAY;
  includes_moscore?: boolean;
  family_name?: string;
  calendar_name?: string;
  maximum_number_active_loans?: number;
  file_s3_path?: string;
  created_at?: Date;
  updated_at?: Date;
  currency_value?: string;
  currency_code?: string;
  timezone_value?: string;
}

export enum ROUND_WAY {
  ROUND_UP = 'up',
  ROUND_DOWN = 'down',
}
