import { MerchantsPageTranslate } from '../../models/merchants-translate';

const MERCHANT_TRANSLATE: MerchantsPageTranslate = {
  MERCHANTS: {
    TITLE: 'Merchants',
    DESCRIPTION: '',
    FILTERS: {
      TITLE: 'Search tools',
      DESCRIPTION:
        'Filter your credit database by using default and advanced filters or use free text search for specific fields.',
      SEARCH_BAR: 'Merchant ID, Merchant name, National ID',
      STATUS: 'Status',
      DATE_CREATED_TITLE: 'Date created',
      DATE_CREATED_PLACEHOLDER: 'Choose a range',
      SALES_TITLE: 'Sales',
      SALES_LESS: 'From',
      SALES_GRATHER: 'to',
      PAYOUT_TITLE: 'Payout',
      PAYOUT_LESS: 'From',
      PAYOUT_GRATHER: 'to',
    },
    TABLE: {
      ID: 'Merchant ID',
      MERCHANT_NAME: 'Merchant name',
      NATIONAL_ID: 'National ID',
      STATUS: 'Status',
      SALES: 'Sales',
      TOTAL_PAYOUT: 'Total Payout',
      CREATED_AT: 'Date created',
    },
  },
  MERCHANT_PROFILE: {
    TITLE: 'Merchants profile',
    DESCRIPTION:
      'Review all your current merchants. Filter and download this database to quickly <br />navigate and analyze your merchant base. ',
    INFO: {
      MERCHANT_ID: 'Merchant ID',
      CREATED_AT: 'Date created',
    },
    SUMMARY: {
      TITLE: 'Summary',
      TOTAL_SALES: 'Total sales',
      TOTAL_NUMBER_OF_SALES: 'Total # of sales',
      AVERAGE_TICKET: 'Average ticket',
      AVERAGE_NUMBER_PURCHASES_CUSTOMERS: 'Avg. # of purchases / Customer',
      MONTH: 'Month',
      LAST_MONTH: 'Last month',
    },
    DETAILS: {
      TITLE: 'Merchant details',
      MERCHANT_NAME: 'Merchant name',
      TYPE_OF_ID: 'Type of ID',
      ID_NUMBER: 'ID number',
      ADDRESS: 'Address',
      ONLINE_STORE: 'Online store',
      CONTACT_NUMBER: 'Contact number',
      CONTACT_EMAIL: 'Contact email',
      TYPE_OF_SERVICE: 'Type of BNPL service',
    },
    PURCHASES: {
      TITLE: 'Recent purchases',
      DESCRIPTION:
        'See the recent purchases for this merchant. Quickly review the amount, date of purchase.',
      TABLE: {
        PURCHASE_ID: 'Purchases ID',
        CREDIT_ID: 'Credit ID',
        AMOUNT: 'Amount',
        DATE_OF_PURCHASE: 'Date of purchase',
      },
      BTN_VISIT_PURCHASES: 'Visit all purchases',
      BTN_VISIT_CREDITS: 'Visit credits',
    },
    MOVEMENTS: {
      TITLE: 'Recent movement',
      DESCRIPTION:
        'See the recent movements for this merchant. Quickly review the concept, amount and date of purchase.',
      TABLE: {
        MOVEMENT_ID: 'Movement ID',
        CONCEPT: 'Concept',
        AMOUNT: 'Amount',
        DATE_OF_MOVEMENT: 'Date of movement',
      },
      BTN_VISIT_TRANSACTION: 'Visit transaction',
    },
    GROUP_AND_OFFERS: {
      TITLE: 'Group and offers',
      DESCRIPTION:
        'See the associated offers for this merchant with their respective commissions. ',
      BTN_VISIT_GROUPS: 'Visit groups',
    },
  },
};
export default MERCHANT_TRANSLATE;
