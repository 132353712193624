import { Injectable } from '@angular/core';
import { User } from '@app/core/models/user.model';
import { UserApi } from '@app/data/api/user/user.api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as userActions from '@store/user/user.actions';
import { catchError, map, of, switchMap } from 'rxjs';

@Injectable()
export class UserEffects {
  constructor(private _actions$: Actions, private _userApi: UserApi) {}

  getUserInfo$ = createEffect(() =>
    this._actions$.pipe(
      ofType(userActions.getUserInfo),
      switchMap(() =>
        this._userApi.getUserInfo().pipe(
          map((data: User) => userActions.successGetUserInfo({ data })),
          catchError((_) => of(userActions.failedGetUserInfo()))
        )
      )
    )
  );
}
