import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { inOutAnimation } from '@app/core/ animations/ animations';
import { Filters } from '@app/core/models/filters.model';
import { CONTAINER_SIZE } from '../../constants/container.constants';
import { TABLE_COLUMNS_SIZE } from '../../constants/table.constant';
import { TABLE_DESIGN_CLASS, TYPE_COLUMN } from '../../enums/table.enum';
import {
  Column,
  ColumnSort,
  TableHeader,
} from '../../interfaces/table.interface';

@Component({
  selector: 'mo-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: inOutAnimation,
})
export class TableComponent<T> implements OnInit {
  @Input() designClass: TABLE_DESIGN_CLASS = TABLE_DESIGN_CLASS.DEFAULT;
  @Input() headers: TableHeader[];
  @Input() data: T[];
  @Input() firstColumnFixed: boolean = false;
  @Input() containerSize: string = CONTAINER_SIZE.DEFAULT;
  @Input() fixedMinWidth: boolean = true;
  @Input() urlRow: string;
  @Input() urlRowIdentifier: string = TYPE_COLUMN.ID;
  @Input() isLoading: boolean;
  @Input() loadingRows: number = 5;
  @Input() loadingColumns: number = 7;
  @Output() sortFilters: EventEmitter<Filters> = new EventEmitter<Filters>();

  public sortedColumn: string;
  public firstColumn: Column<T>;
  public columns: Column<T>;
  public columnsSize: number;
  public loaderRows: any[];
  public loaderColums: any[];

  public TYPE_COLUMNS = TYPE_COLUMN;

  constructor() {}

  ngOnInit(): void {
    this.loaderRows = new Array(this.loadingRows);
    this.loaderColums = new Array(this.loadingColumns);
    this._calculateColumnSize();
  }

  public getTextColumn(header: TableHeader, row: T): string {
    let text = header.dataKey ? String(row[header.dataKey] ?? '') : '-';
    if (!text) text = '-';
    return header.transform ? header.transform(text) : text;
  }

  public onSort(columnSort: ColumnSort) {
    this.sortedColumn = columnSort.column;
    const ordering: string =
      columnSort.sortBy == 'ASC' && this.sortedColumn
        ? `-${this.sortedColumn}`
        : this.sortedColumn;

    const tableSortFilter: Filters = { ordering };
    this.sortFilters.emit(tableSortFilter);
  }

  private _calculateColumnSize() {
    this.columnsSize = Math.round(
      TABLE_COLUMNS_SIZE.TOTAL_COLUMNS /
        (this.headers?.length ?? TABLE_COLUMNS_SIZE.MIN_COLUMNS)
    );
    this.columnsSize =
      this.columnsSize < TABLE_COLUMNS_SIZE.MIN_COLUMNS
        ? TABLE_COLUMNS_SIZE.MIN_COLUMNS
        : this.columnsSize;
  }
}
