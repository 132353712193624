import { CUSTOMERS_ROUTES_MOCK } from '../modules/customers/data/constants/mocks.constants';
import { LOANS_ROUTES_MOCK } from '../modules/loan-manager/data/constants/mock.constants';
import { MERCHANT_MANAGER_ROUTES_MOCK } from '../modules/merchant-manager/data/constants/mock.constants';
import { PRODUCTS_AND_OFFERS_ROUTES_MOCK } from '../modules/product-catalogue/data/constants/mocks.constants';

export const PLUGINS_ROUTES_MOCK = [
  ...CUSTOMERS_ROUTES_MOCK,
  ...LOANS_ROUTES_MOCK,
  ...PRODUCTS_AND_OFFERS_ROUTES_MOCK,
  ...MERCHANT_MANAGER_ROUTES_MOCK,
];
