import { MenusTranslate } from '@app/core/i18n/models/menu-translate';

const MENUS_TRANSLATE: MenusTranslate = {
  SIDE_MENU: {
    MY_ACCOUNT: 'Mi cuenta',
  },
  NAVBAR: {
    LOGOUT: 'Cerrar sesión',
    NEED_SUPPORT: '¿Necesita ayuda?',
  },
};
export default MENUS_TRANSLATE;
