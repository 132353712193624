import { Component, OnInit } from '@angular/core';
import { DEFAULT_LANGUAGE } from '@app/core/i18n/constants/translate.constants';
import { AuthService } from '@app/domain/services/auth/auth.service';
import { AppFacade } from '@app/facade/app/app.facade';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private _authService: AuthService,
    private _appFacade: AppFacade
  ) {
    this._appFacade.updateLanguage(DEFAULT_LANGUAGE);
  }

  ngOnInit() {
    this._authService.checkSession();
  }
}
