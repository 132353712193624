import { FiltersToolsTranslate } from '@app/core/i18n/models/filters-translate';

const FILTERS_TRANSLATE: FiltersToolsTranslate = {
  RESULTS: 'Results',
  BTN_SEARCH: 'Search',
  BTN_CLEAN_FILTERS: 'Clear filters',
  ADVANCED_FILTERS: 'Advanced Filters',
  RECORDS_PER_PAGE: 'Records per page',
};
export default FILTERS_TRANSLATE;
