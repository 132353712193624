import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'MONumber',
})
export class MONumberPipe implements PipeTransform {
  transform(value: any): string {
    value = value ? value : 0;
    const formatValue = new Intl.NumberFormat().format(Number(value));
    return formatValue;
  }
}
