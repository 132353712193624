<ng-container *ngIf="value">
  <div class="item" [class.item--dashed]="isDashed">
    <p class="item__title">{{ name }}</p>
    <div class="item__dashed-divider" *ngIf="isDashed"></div>
    <p
      class="item__value"
      [class.highlighted]="isHighlighted"
      [class.bold]="isBold"
    >
      {{ value }}
    </p>
  </div>
  <ng-content></ng-content>
</ng-container>
