import { LoanRequestPageTranslate } from '../../models/loan-request-translate';

const LOAN_REQUEST_TRANSLATE: LoanRequestPageTranslate = {
  LOAN_REQUEST: {
    TITLE: 'Credit Requests',
    DESCRIPTION:
      'Review all your credit requests. Filter and download the requests database to quickly <br />navigate and analyze your credit applications.',
    FILTERS: {
      TITLE: 'Search tools',
      DESCRIPTION:
        'Filter your request database by using default and advanced filters or use free text search for specific fields.',
      SEARCH_BAR: 'Customer ID or Request ID',
      AMOUNT_TITLE: 'Credit amount',
      AMOUNT_LESS: 'From',
      AMOUNT_GRATHER: 'to',
      STATUS: 'Request status',
      TAKEN_AT: 'Taken at',
    },
    TABLE: {
      REFERENCE: 'Request ID',
      CUSTOMER_REFERENCE: 'Customer ID',
      MERCHANT_REFERENCE: 'Merchant  ID',
      DISPLAY_NAME: 'Customer name',
      STATUS: 'Request status',
      AMOUNT: 'Amount',
      TAKEN_AT: 'Taken at',
      PRODUCT_NAME: 'Product',
      OFFER_NAME: 'Offer',
    },
  },
};
export default LOAN_REQUEST_TRANSLATE;
