import { ItemSubMenu, MenuItem, MENU_ITEM_TYPE } from '@app/core/models/menu.model';
import { MENU_CONFIG_DISABLED } from '@instance-config/menu.config';
import { LOAN_MANAGER_URLS } from './urls.constants';

const SUB_ITEMS_MENU: ItemSubMenu[] = [
  {
    type: MENU_ITEM_TYPE.SUB_MENU_ITEM,
    label: 'LOAN_MANAGER._MENU.OVERVIEW',
    path: LOAN_MANAGER_URLS.OVERVIEW,
    isSubMenu: true,
    disabled: MENU_CONFIG_DISABLED.LOAN_MANAGER.OVERVIEW ?? false
  },
  {
    type: MENU_ITEM_TYPE.SUB_MENU_ITEM,
    label: 'LOAN_MANAGER._MENU.LOAN_REQUEST',
    path: LOAN_MANAGER_URLS.LOANS_REQUEST,
    isSubMenu: true,
    disabled: MENU_CONFIG_DISABLED.LOAN_MANAGER.LOANS_REQUEST ?? false
  },
  {
    type: MENU_ITEM_TYPE.SUB_MENU_ITEM,
    label: 'LOAN_MANAGER._MENU.LOANS',
    path: LOAN_MANAGER_URLS.LOANS,
    isSubMenu: true,
    disabled: MENU_CONFIG_DISABLED.LOAN_MANAGER.LOANS ?? false
  },
  {
    type: MENU_ITEM_TYPE.SUB_MENU_ITEM,
    label: 'LOAN_MANAGER._MENU.PAYMENTS',
    path: LOAN_MANAGER_URLS.PAYMENTS,
    isSubMenu: true,
    disabled: MENU_CONFIG_DISABLED.LOAN_MANAGER.PAYMENTS ?? false
  },
].filter(item => !item.disabled);

export const LOAN_MANAGER_MENU: MenuItem[] = [
  {
    type: MENU_ITEM_TYPE.ITEM_ACCORDION,
    icon: 'app/presentation/assets/img/icons/menu/loan-manager.svg',
    label: 'LOAN_MANAGER._NAME',
    path: SUB_ITEMS_MENU[0]?.path ?? '',
    disabled: MENU_CONFIG_DISABLED.LOAN_MANAGER.MODULE ?? false,
    item: {
      type: MENU_ITEM_TYPE.SUB_MENU,
      items: SUB_ITEMS_MENU
    },
  },
];
