import { Component, Input, OnInit } from '@angular/core';
import {
  getSkeleton,
  Skeleton,
  SkeletonTheme,
} from '@app/core/models/skeleton.model';

@Component({
  selector: 'layout-score-item',
  templateUrl: './score-item.component.html',
  styleUrls: ['./score-item.component.scss'],
})
export class ScoreItemComponent implements OnInit {
  @Input() name: string;
  @Input() date: Date | string;
  @Input() score: number;
  @Input() amount: number;
  @Input() isLoading?: boolean;
  @Input() hasDetail?: boolean = false;

  public loaderName: Skeleton[];
  public loaderValues: Skeleton[];

  constructor() {}

  ngOnInit(): void {
    const margin: string = '0 5px 5px 0';
    const minWidth: number = 60;
    const maxWidth: number = 100;

    const skeletonTheme: SkeletonTheme = { minWidth, maxWidth, margin };

    this.loaderName = getSkeleton(1, 1, skeletonTheme);
    this.loaderValues = getSkeleton(1, 3, skeletonTheme);
  }
}
