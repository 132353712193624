import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.state';

export const getAuthFeatureState = createFeatureSelector<AuthState>('auth');

export const selectIsLoadingLogin = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state?.isLoadingLogin
);

export const selectIsAuthenticated = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state?.isAuthenticated
);
