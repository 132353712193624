import DATES_TRANSLTE from './date.translate';
import FILTERS_TRANSLATE from './filters.translate';
import GENERAL_TRANSLATE from './general.translate';
import MENUS_TRANSLATE from './menus.translate';
import TABLE_TRANSLATE from './table.translate';

const APP_TRANSLATE = {
  MENUS: MENUS_TRANSLATE,
  FILTERS: FILTERS_TRANSLATE,
  TABLES: TABLE_TRANSLATE,
  DATES: DATES_TRANSLTE,
  GENERAL: GENERAL_TRANSLATE,
};
export default APP_TRANSLATE;
