<div class="container-menu-accordion" [class.selected]="active">
  <div class="container-item-menu" [class]="product">
    <div class="item-menu" (click)="onToggleAccordion()">
      <img [src]="icon" />
      <label class="item-label">{{ label }}</label>
    </div>
  </div>
  <div
    class="container-accordion"
    *ngIf="active && collapsed"
    [@inOutAnimation]
  >
    <ng-content></ng-content>
  </div>
</div>
