<div
  class="mo-date"
  [class.is-single-date-picker]="singleDatePicker"
  *ngIf="dateConfig$ | async as dateConfig"
>
  <label class="mo-label" *ngIf="label">
    {{ label }}
  </label>
  <input
    type="text"
    ngxDaterangepickerMd
    [class]="'form-control ' + iconPosition"
    [keepCalendarOpeningWithRange]="keepCalendarOpeningWithRange"
    [showRangeLabelOnInput]="showRangeLabelOnInput"
    [singleDatePicker]="singleDatePicker"
    [showCustomRangeLabel]="showCustomRangeLabel"
    [alwaysShowCalendars]="alwaysShowCalendars"
    [showClearButton]="showClearButton"
    [showCancel]="showCancel"
    [linkedCalendars]="linkedCalendars"
    [isInvalidDate]="isInvalidDate"
    [autoApply]="autoApply"
    [maxDate]="maxDate"
    [minDate]="minDate"
    [locale]="dateConfig?.locale"
    [ranges]="dateConfig?.ranges"
    [placeholder]="placeholder"
    [value]="value"
    [disabled]="isDisabled"
    (rangeClicked)="onRangeClicked($event)"
    (datesUpdated)="onDatesUpdated($event)"
    readonly
  />
  <div class="form-group">
    <div class="form-relative">
      <div #boxIcon *ngIf="icon" [class]="'box-icon ' + iconPosition">
        <layout-icon [icon]="icon" [color]="iconColor"> </layout-icon>
      </div>
    </div>
  </div>
</div>
