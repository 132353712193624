import {
  LOAN_MANAGER_FEATURE,
  MERCHANT_MANAGER_FEATURE,
} from './feature.constants';

export const MERCHANT_MANAGER_URLS = {
  OVERVIEW: `/${MERCHANT_MANAGER_FEATURE.PATH}/${MERCHANT_MANAGER_FEATURE.PAGES.OVERVIEW.PATH}`,
  MERCHANTS: `/${MERCHANT_MANAGER_FEATURE.PATH}/${MERCHANT_MANAGER_FEATURE.PAGES.MERCHANTS.PATH}`,
  MERCHANT_PROFILE: `/${MERCHANT_MANAGER_FEATURE.PATH}/${MERCHANT_MANAGER_FEATURE.PAGES.MERCHANTS.PATH}/${MERCHANT_MANAGER_FEATURE.PAGES.MERCHANTS.SUB_PAGES.MERCHANT_PROFILE.PATH}`,
  PURCHASES: `/${MERCHANT_MANAGER_FEATURE.PATH}/${MERCHANT_MANAGER_FEATURE.PAGES.PURCHASES.PATH}`,
  MERCHANT_GROUPS: `/${MERCHANT_MANAGER_FEATURE.PATH}/${MERCHANT_MANAGER_FEATURE.PAGES.MERCHANT_GROUPS.PATH}`,
};

export const LOAN_MANAGER_URLS = {
  LOANS: `/${LOAN_MANAGER_FEATURE.PATH}/${LOAN_MANAGER_FEATURE.PAGES.LOANS.PATH}`,
  LOAN_PROFILE: `/${LOAN_MANAGER_FEATURE.PATH}/${LOAN_MANAGER_FEATURE.PAGES.LOANS.PATH}/${LOAN_MANAGER_FEATURE.PAGES.LOANS.SUB_PAGES.LOAN_PROFILE.PATH}`,
};
