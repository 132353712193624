export enum SIZES {
  SM = 'size-sm',
  MD = 'size-md',
  LG = 'size-lg',
}

export enum BUTTON_DESIGN_CLASS {
  DEFAULT = 'template-default',
  OUTLINE_DEFAULT = 'outline-default',
  OUTLINE_PRIMARY = 'outline-primary',
  OUTLINE_PRIMARY_DARK = 'outline-primary__dark',
  NEUTRO_DARK = 'template-neutro-dark',
  PRIMARY = 'template-primary',
  PRIMARY_WHITE = 'template-primary-white',
  ALTERNATIVE = 'template-alternative',
  ALTERNATIVE_ACTIVE = 'template-alternative active',
  INDIGO = 'template-indigo',
  DARK_GREEN = 'template-dark-green',
}

export enum BUTTON_TYPE {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset',
}

export enum ICON_POSITION {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum COLOR_TEMPLATE {
  DEFAULT = 'default',
  LIGHT = 'light',
  PRIMARY = 'primary',
  OUTLINE_PRIMARY = 'outline-primary',
}

export enum INPUT_TYPE {
  TEXT = 'text',
  NUMBER = 'number',
  DECIMAL = 'decimal',
  PHONE = 'phone',
  EMAIL = 'email',
  PASSWORD = 'password',
  RE_PASSWORD = 're_password',
  CUSTOM = 'custom',
}
export enum FILE_TYPE {
  CSV = 'text/csv',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PNG = 'image/png',
  JPG = 'image/jpg',
  PDF = 'application/pdf',
}

export enum ORIENTATION {
  TOP = 'orientaton-top',
  BOTTOM = ' orientaton-bottom',
}
