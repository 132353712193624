import { RoutesMock } from '@app/core/models/routes-mock.model';
import { PLUGINS_ROUTES_MOCK } from '@app/plugins/constants/mocks.constants';
import { SESSION_TOKEN } from '../mocks/data/auth.mock';
import { PROJECT } from '../mocks/data/project.mock';
import { USER_INFO } from '../mocks/data/user.mock';
import { API_URLS } from './api.constants';

export const ROUTES_MOCK: RoutesMock[] = [
  // ********************
  // AUTH
  // ********************
  {
    url: API_URLS.AUTH.LOGIN,
    data: SESSION_TOKEN,
    method: 'POST',
  },
  {
    url: API_URLS.AUTH.REFRESH_TOKEN,
    data: SESSION_TOKEN,
    method: 'POST',
  },
  // ********************
  // USER
  // ********************
  {
    url: API_URLS.USER.GET_INFO,
    data: USER_INFO,
    method: 'GET',
  },
  // ********************
  // PROJECT
  // ********************
  {
    url: API_URLS.PROJECT.GET_PROJECT,
    data: PROJECT,
    method: 'GET',
  },
  ...PLUGINS_ROUTES_MOCK,
];
