import { TableTranslate } from '@app/core/i18n/models/table-translate';

const TABLE_TRANSLATE: TableTranslate = {
  EMPTY_STATE: {
    TITLE: 'Ups!.. No se han encontrado resultados',
  },
  OPTIONS: {
    DOWNLOAD: 'Descargar',
  },
};
export default TABLE_TRANSLATE;
